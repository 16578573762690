/* start dyslexia-friendly font */
@font-face {
  font-family: "UDF";
  src: url("https://cdn.tamkin.app/fonts/UserwayDyslexiaFont-Medium.woff"),
    url("https://cdn.tamkin.app/fonts/UserwayDyslexiaFont-Medium.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UDF";
  src: url("https://cdn.tamkin.app/fonts/UserwayDyslexiaFont-Italic.woff"),
    url("https://cdn.tamkin.app/fonts/UserwayDyslexiaFont-Italic.woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "UDF";
  src: url("https://cdn.tamkin.app/fonts/UserwayDyslexiaFont-Bold.woff"),
    url("https://cdn.tamkin.app/fonts/UserwayDyslexiaFont-Bold.woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "UDF";
  src: url("https://cdn.tamkin.app/fonts/UserwayDyslexiaFont-Bold-Italic.woff"),
    url("https://cdn.tamkin.app/fonts/UserwayDyslexiaFont-Bold-Italic.woff");
  font-weight: bold;
  font-style: italic;
}
/* end dyslexia-friendly font */

#tamkinPlayerApp {
  --tp-accessibility-icon-width: 48px;
  --tp-accessibility-icon-height: 48px;
  /* --tp-accessibility-top: calc(
    50% + var(--tp-accessibility-icon-height) + 1rem
  ); */
  --tp-accessibility-right: calc(var(--tp-accessibility-icon-width) + 1rem);
  --tp-primary: #35c0b4;
  --tp-primary-grad: #71dad2;
  --tp-primary-border: #3cd8cb;
  --tp-primary-light: #f2fbf9;
  --tp-secondary: #f2f2f2;
  --tp-accessibility-sidebar-width: 450px;
  --tp-light: #f6f6f6;
  --tp-light-2: #e7e7e7;
  --tp-dark: #1e1e1e;
  --tp-border: #c8cfeb;
  --tp-inactive: #d9d9d9;
}

#tamkinPlayerApp .modal {
  --bs-modal-bg: var(--tp-light);
}
#tamkinPlayerApp a:focus-visible {
  outline-color: var(--tp-primary);
}
#tamkinPlayerApp .btn-primary {
  --bs-btn-disabled-border-color: #c7c7c7;
  --bs-btn-disabled-bg: #c7c7c7;
  --bs-btn-active-color: var(--tp-primary);
  --bs-btn-active-border-color: var(--tp-primary);
  --bs-btn-active-bg: var(--tp-primary);
  --bs-btn-hover-border-color: var(--tp-primary);
  --bs-btn-hover-bg: var(--tp-primary);
  --bs-btn-border-color: var(--tp-primary);
  --bs-btn-bg: var(--tp-primary);
}
@media (min-width: 992px) {
  #tamkinPlayerApp .modal-lg,
  .modal-xl {
    --bs-modal-width: 650px;
  }
}
font,
font:hover,
font:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
.modal {
  --bs-modal-border-radius: 2.2rem;
}
#tamkinPlayerApp .modal .modal-content > div {
  border: none;
}
#tamkinPlayerApp #translateModal .modal-content .modal-header {
  align-items: flex-start;
}
#tamkinPlayerApp #translateModal .modal-content > * {
  padding-inline-start: 1.5rem;
}
#tamkinPlayerApp .modal .modal-content button[data-bs-dismiss="modal"] {
  background: none;
  border: none;
}
#tamkinPlayerApp .imggray {
  filter: grayscale(1);
}
#tamkinPlayerApp .form-check-input:is(:checked, :focus) {
  background: var(--tp-primary);
  border: 3px solid white;
  box-shadow: 0 0 0 1px var(--tp-primary);
}
/* #tamkinPlayerApp .form-check-input:focus {
} */
#tamkinPlayerApp .nav-pills#xlTabs {
  --bs-nav-pills-link-active-bg: var(--tp-primary);
}
#tamkinPlayerApp .nav-pills#xlTabs .nav-item {
  background-color: var(--tp-secondary);
}
#tamkinPlayerApp .nav-pills#xlTabs .nav-item .nav-link:not(.active) {
  color: #a4a3a3;
}
#tamkinPlayerApp .nav-pills#xlTabs .nav-item .nav-link.active {
  color: #fff;
}
#tamkinPlayerApp .nav-pills#xlTabs .nav-item .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
#tamkinPlayerApp .tp-bg-primary:not(:disabled) {
  background-color: var(--tp-primary);
}
#tamkinPlayerApp .tp-text-primary {
  color: var(--tp-primary);
}
#tamkinPlayerApp .tp-bg-primary-light {
  background-color: var(--tp-primary-light);
}
#tamkinPlayerApp .tp-text-primary-light {
  color: var(--tp-primary-light);
}
#tamkinPlayerApp .tp-bg-secondary {
  background-color: var(--tp-secondary);
}
#tamkinPlayerApp .tp-text-secondary {
  color: var(--tp-secondary);
}
#tamkinPlayerApp .cursor-pointer {
  cursor: pointer;
}
#tamkinPlayerApp .font-12 {
  font-size: 12px;
}
#tamkinPlayerApp .font-13 {
  font-size: 13px;
}
#tamkinPlayerApp .font-14 {
  font-size: 14px;
}
#tamkinPlayerApp .font-15 {
  font-size: 15px;
}
#tamkinPlayerApp .font-16 {
  font-size: 16px;
}
#tamkinPlayerApp .font-17 {
  font-size: 17px;
}
#tamkinPlayerApp .font-18 {
  font-size: 18px;
}
#tamkinPlayerApp .font-19 {
  font-size: 19px;
}
#tamkinPlayerApp .font-20 {
  font-size: 20px;
}
#tamkinPlayerApp .font-w400 {
  font-weight: 400;
}
#tamkinPlayerApp .font-w500 {
  font-weight: 500;
}
#tamkinPlayerApp .font-w600 {
  font-weight: 600;
}
#tamkinPlayerApp .font-w700 {
  font-weight: 700;
}
#tamkinPlayerApp .line-12 {
  line-height: 1.2rem;
}
#tamkinPlayerApp .h-20px {
  height: 20px;
}
#tamkinPlayerApp .h-25px {
  height: 25px;
}
#tamkinPlayerApp .h-40px {
  height: 40px;
}
#tamkinPlayerApp .h-50px {
  height: 50px;
}
#tamkinPlayerApp .h-75px {
  height: 75px;
}
#tamkinPlayerApp .h-100px {
  height: 100px;
}
#tamkinPlayerApp a.appear img.accordion-arrow {
  transform: rotate(90deg) !important;
}
#tamkinPlayerApp .form-group {
  position: relative;
}

#tamkinPlayerApp hr {
  opacity: 0.15;
}
#tamkinPlayerApp .list-group-flush > .list-group-item:last-child {
  border-width: 0 0 var(--bs-list-group-border-width);
}
#tamkinPlayerApp .tp-icon {
  width: 36px !important;
  height: 36px !important;
  align-content: center;
  text-align: center;
}
#tamkinPlayerApp .tp-icon.lg {
  width: 50px !important;
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
#tamkinPlayerApp .tp-icon.sm {
  width: 28px !important;
  height: 28px !important;
}
#tamkinPlayerApp #collapseLang .list-group-item .tp-icon {
  background-color: var(--tp-primary-light);
  color: var(--tp-primary);
}
#tamkinPlayerApp #collapseLang .list-group-item.active .tp-icon,
#tamkinPlayerApp #g-lang-active .tp-icon {
  background-color: var(--tp-primary);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
#tamkinPlayerApp #collapseLang .list-group-item.active .tp-icon span,
#tamkinPlayerApp #g-lang-active .tp-icon span {
  color: #fff;
}
#tamkinPlayerApp #collapseLang .list-group-item.active {
  background-color: var(--tp-primary-light);
  border-color: var(--tp-primary-light);
  color: var(--tp-dark);
}
#tamkinPlayerApp .collapsing,
#tamkinPlayerApp .collapse {
  transition: all 0.1s !important;
}
#tamkinPlayerApp #collapseLang .list-group-item.active::before {
  content: "✓";
  position: absolute;
  right: 24px;
  font-weight: bold;
  top: 14px;
  width: 24px;
  color: var(--tp-primary);
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}
#tamkinPlayerApp .rtl #collapseLang .list-group-item.active::before {
  right: auto;
  left: 24px;
}

/* media tabs */
#tamkinPlayerApp #translateModal #mediaTabs .nav-link {
  color: var(--tp-dark);
  background-color: white;
  border-color: var(--tp-light);
}
#tamkinPlayerApp #translateModal #mediaTabs .nav-link.active {
  border: 1px solid var(--tp-primary);
  background-color: var(--tp-primary-light);
}
#tamkinPlayerApp #translateModal #mediaTabs .nav-link .tp-icon {
  background-color: var(--tp-light);
}
#tamkinPlayerApp #translateModal #mediaTabs .nav-link.active .tp-icon {
  background-color: white;
}
#tamkinPlayerApp #translateModal #mediaTabs .media-tab {
  background-color: #ecf0ef;
  display: flex;
}
#tamkinPlayerApp #translateModal #mediaTabs #upload .media-tab {
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--tp-border);
}
#tamkinPlayerApp #translateModal #mediaTabs .media-tab .input-border {
  border: 1px solid var(--tp-border);
}
#tamkinPlayerApp #translateModal .footer-credits {
  background-color: var(--tp-primary);
  border-radius: 0 0 var(--bs-modal-border-radius) var(--bs-modal-border-radius);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  margin-top: 1rem;
}
#tamkinPlayerApp #translateModal .footer-credits a span {
  border-bottom: 1px solid white;
  color: white;
}
/* translation modal */
#tamkinPlayerApp .translation-modal .modal-footer {
  background-color: var(--tp-light-2);
}
#tamkinPlayerApp .translation-modal .modal-body .translationLoaderContainer {
  background-color: var(--tp-light-2);
}
#tamkinPlayerApp .translation-modal .footer-credits a span {
  border-bottom: 1px solid var(--tp-dark);
  color: var(--tp-dark);
}
#tamkinPlayerApp #filePreview {
  position: relative;
}
#tamkinPlayerApp #filePreview .btn-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 0.5rem;
  height: 0.5rem;
}
/* dropzone */
#tamkinPlayerApp .dragover#mediaUpload {
  background-color: #0092ff3d !important;
}
#tamkinPlayerApp #upload-progress-container {
  display: flex;
  align-items: center;
}
/* outside icons */
.accessibility-icon-container-widget1 * {
  z-index: 1001;
}

.accessibility-icon-container-widget1 .tp-accessibility.active:before {
  content: "";
  background-image: url("https://cdn.tamkin.app/images/check_on.svg") !important;
  background-size: cover;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  z-index: 9999998;
  top: -2px;
  left: -5px;
}
.accessibility-icon-container-widget1.ltr .tp-accessibility.active:before {
  right: -5px;
  left: auto;
}
#tamkinPlayerApp
  .accessibility-icon-container-widget1
  .tp-accessibility-actions {
  display: none;
  position: fixed;
  right: var(--tp-accessibility-right);
  top: var(--tp-accessibility-top);
}
#tamkinPlayerApp
  .accessibility-icon-container-widget1.ltr
  .tp-accessibility-actions {
  left: var(--tp-accessibility-right);
  right: auto;
}
#tamkinPlayerApp
  .accessibility-icon-container-widget1
  .tp-accessibility-actions.active {
  display: flex;
}
#tamkinPlayerApp
  .accessibility-icon-container-widget1
  .tp-accessibility-actions.active
  img {
  cursor: pointer;
}
#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility img {
  width: var(--tp-accessibility-icon-width);
  height: var(--tp-accessibility-icon-height);
}
#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility {
  position: fixed;
  right: -100%;
  top: var(--tp-accessibility-top);
  transition: all 0.5s;
  z-index: 9999998;
  /* background: linear-gradient(
    180deg,
    var(--tp-primary) 0%,
    var(--tp-primary-grad) 100%
  ); */
  background-color: var(--tp-primary);
  background-size: 100% 100%;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  cursor: pointer;
  padding: 4px;
  align-items: center;
  justify-content: center;
}
#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility {
  left: -100%;
  right: auto;
}
.btn::before,
button::before,
a::before {
  background-color: transparent !important;
}
#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility-text {
  position: fixed;
  right: -1000px;
  top: var(--tp-accessibility-top);
  /* background: linear-gradient(
    180deg,
    var(--tp-primary) 0%,
    var(--tp-primary-grad) 100%
  ); */
  background-color: var(--tp-primary);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  height: calc(var(--tp-accessibility-icon-height) + 8px);
  transition: right 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 0 9px;
  border-radius: 4px;
}
#tamkinPlayerApp
  .accessibility-icon-container-widget1.ltr
  .tp-accessibility-text {
  left: -1000px;
  right: auto;
}

#tamkinPlayerApp
  .accessibility-icon-container-widget1
  .tp-accessibility-text.active {
  right: calc(var(--tp-accessibility-icon-width) + 12px);
  z-index: 1000;
}
#tamkinPlayerApp
  .accessibility-icon-container-widget1.ltr
  .tp-accessibility-text.active {
  left: calc(var(--tp-accessibility-icon-width) + 12px);
  right: auto;
  z-index: 1000;
}

/* sidebar accessibility */

#tamkinPlayerApp .tp-accessibility-sidebar {
  position: fixed;
  direction: ltr !important;
  top: 0;
  right: calc(var(--tp-accessibility-sidebar-width) * -1);
  width: var(--tp-accessibility-sidebar-width);
  height: 100%;
  z-index: 9999999;
  background-color: var(--tp-secondary);
  transition: 0.5s;
}
#tamkinPlayerApp .tp-accessibility-sidebar.ltr {
  right: auto;
  left: calc(var(--tp-accessibility-sidebar-width) * -1);
}
@media only screen and (max-width: 460px) {
  #tamkinPlayerApp .tp-accessibility-sidebar {
    width: 100% !important;
    right: -100%;
  }
  #tamkinPlayerApp .tp-accessibility-sidebar.ltr {
    left: -100%;
  }
}
/* used in sidebar language accordion */
#tamkinPlayerApp .tp-accessibility-sidebar.rtl {
  direction: rtl !important;
  text-align: right;
}
#tamkinPlayerApp .tp-accessibility-sidebar.rtl input#tp-search {
  direction: rtl !important;
  text-align: right !important;
  padding-right: 2.5rem;
}
#tamkinPlayerApp .tp-accessibility-sidebar input#tp-search {
  direction: ltr !important;
  text-align: left !important;
}
.tp-accessibility-sidebar .form-group label {
  position: absolute;
  top: 10px;
  left: 1.4rem;
  right: auto;
}
.tp-accessibility-sidebar.rtl .form-group label {
  position: absolute;
  top: 10px;
  right: 1.4rem;
  left: auto;
}

.tp-accessibility-sidebar #tp-search-remove {
  position: absolute;
  font-size: 22px;
  cursor: pointer;
  z-index: 9999999999;
  right: 30px;
  top: 4px;
}
.tp-accessibility-sidebar.rtl #tp-search-remove {
  left: 30px;
  right: auto !important;
}
#tamkinPlayerApp .tp-accessibility-sidebar.rtl img.accordion-arrow {
  transform: rotate(180deg);
}
#tamkinPlayerApp .tp-accessibility-sidebar.active {
  right: 0;
}
#tamkinPlayerApp .tp-accessibility-sidebar.ltr.active {
  right: auto;
  left: 0;
}
#tamkinPlayerApp .tp-accessibility-sidebar.oversized {
  /*--tp-accessibility-sidebar-width: 440px;*/
  zoom: 120%;
}
/* sidebar header */
#tamkinPlayerApp .tp-accessibility-sidebar-header {
  background-color: var(--tp-primary);
  border-radius: 0 0 12px 12px;
  height: 56px;
  color: #fff !important;
}
#tamkinPlayerApp .tp-accessibility-sidebar-header p {
  color: #fff !important;
}
#tamkinPlayerApp .tp-modal-header {
  border-radius: 0 0 12px 12px;
  /* text-white tp-bg-primary border-bottom p-4 d-flex align-items-center justify-content-between */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1rem;
  background-color: var(--tp-primary);
}
#tamkinPlayerApp .tp-accessibility-sidebar-footer {
  height: 85px;
  background-size: cover;
}
#tamkinPlayerApp .tp-accessibility-sidebar-body {
  height: calc(100% - 56px - 85px);
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
/* page structure modal */
#tamkinPlayerApp #pageStructureModal .modal-body {
  padding: 0;
  max-height: calc(100vh - 205px);
  overflow: auto;
}
#tamkinPlayerApp #pageStructureModal #pageStructureTabs .nav-link {
  background: none !important;
  border: none;
  color: #878787;
  margin: auto;
  width: 100%;
  font-size: 17px;
}
#tamkinPlayerApp #pageStructureModal #pageStructureTabs .nav-link.active {
  border-bottom: 3px solid var(--tp-primary);
  color: var(--tp-primary);
  font-weight: 600;
}
#tamkinPlayerApp #pageStructureModal .heading-link-container {
  padding: 8px 18px;
  height: 60px;
  align-content: center;
}
#tamkinPlayerApp #pageStructureModal .heading-link-container .heading-link {
  height: 100%;
  display: block;
  align-content: center;
  text-decoration: none !important;
}
#tamkinPlayerApp #pageStructureModal .heading-link-container:hover {
  background-color: #ebebeb;
}
#tamkinPlayerApp #pageStructureModal .heading-link-container .heading-icon {
  background-color: var(--tp-primary);
  color: white;
  padding: 5px;
  margin-inline-end: 10px;
  border-radius: 5px;
}
#tamkinPlayerApp
  #pageStructureModal
  .heading-link-container
  .link-heading-icon {
  margin-inline-end: 10px;
}
#tamkinPlayerApp #pageStructureModal .heading-link-container .heading-title {
  color: #077bbad1;
}

/* #tamkinPlayerApp .tp-accessibility-sidebar-close{
	width: 48px;
	height: 48px;
} */
/* #tamkinPlayerApp .tp-accessibility-sidebar-close:hover{
	background-color: #f0f5fb;
} */
#tamkinPlayerApp #sidebody {
  transition:
    opacity 0.5s,
    visibility 0.5s;
}
#tamkinPlayerApp #sidebody {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(21, 21, 21, 0.8);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
#tamkinPlayerApp .rtl #sidebody {
  left: auto;
  right: 0;
}
#tamkinPlayerApp #sidebody.active {
  z-index: 1040;
  opacity: 1;
  visibility: visible;
}
body.stop {
  overflow: hidden;
}
#tamkinPlayerApp .no-style-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#tamkinPlayerApp .no-style-scroll::-webkit-scrollbar {
  display: none;
}
/* Style the sidebar scrollbar */
#tamkinPlayerApp .tp-accessibility-sidebar .tp-style-scroll {
  scrollbar-color: transparent transparent; /* For Firefox */
  scrollbar-width: thin;
  transition: scrollbar-color 0.3s ease; /* Transition for Firefox */
}
/* Style the scrollbar itself (the track) */
#tamkinPlayerApp .tp-accessibility-sidebar .tp-style-scroll::-webkit-scrollbar {
  background-color: transparent; /* Initially set to transparent */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  width: 10px; /* Set the width of the scrollbar */
}
/* Style the draggable part of the scrollbar (the thumb) */
#tamkinPlayerApp
  .tp-accessibility-sidebar
  .tp-style-scroll::-webkit-scrollbar-thumb {
  background-color: transparent; /* Scrollbar color */
  border-radius: 6px; /* Rounded corners for the thumb */
  border: 2px solid transparent; /* Makes room for margin */
  background-clip: padding-box; /* Ensures the border doesn't overlap the background color */
}
#tamkinPlayerApp .tp-accessibility-sidebar:hover .tp-style-scroll {
  scrollbar-color: var(--tp-primary) #e8e8e8;
}
#tamkinPlayerApp
  .tp-accessibility-sidebar:hover
  .tp-style-scroll::-webkit-scrollbar {
  background-color: #f0f0f0;
}
#tamkinPlayerApp
  .tp-accessibility-sidebar:hover
  .tp-style-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--tp-primary);
}
/* Style the top and bottom buttons (if present) - Not all browsers support styling these */
#tamkinPlayerApp
  .tp-accessibility-sidebar
  .tp-style-scroll::-webkit-scrollbar-button {
  /* display: none; */
}
/* Round caps for the top and bottom parts of the scrollbar */
#tamkinPlayerApp
  .tp-accessibility-sidebar
  .tp-style-scroll::-webkit-scrollbar-button:single-button:vertical:decrement {
  background-color: #c1c1c1; /* Button color */
  border-radius: 10px 10px 0 0; /* Rounded top */
  /* display: none; */
}

#tamkinPlayerApp
  .tp-accessibility-sidebar
  .tp-style-scroll::-webkit-scrollbar-button:single-button:vertical:increment {
  background-color: #c1c1c1; /* Button color */
  border-radius: 0 0 10px 10px; /* Rounded bottom */
  /* display: none; */
}
/* accessibility features effect on dom */
/* bigger text */
.tp-bigger-text-1 {
  zoom: 110%;
}
.tp-bigger-text-2 {
  zoom: 125%;
}
.tp-bigger-text-3 {
  zoom: 135%;
}
.tp-bigger-text-4 {
  zoom: 150%;
}

/* highlight links */
a.tp-highlight-links-1,
a.tp-highlight-links-1 * {
  transition: all 0.3s ease 0s;
  text-decoration: underline !important;
  color: rgb(255, 255, 0) !important;
  background-color: rgb(0, 0, 0) !important;
}
/* text spacing */
.tp-spacing-1 {
  word-spacing: 0.16em !important;
  letter-spacing: 0.12em !important;
}
.tp-spacing-2 {
  word-spacing: 0.32em !important;
  letter-spacing: 0.24em !important;
}
.tp-spacing-3 {
  word-spacing: 0.48em !important;
  letter-spacing: 0.36em !important;
}
/* line height */
.tp-line-height-1 {
  line-height: 33px !important;
}
.tp-line-height-2 {
  line-height: 38.5px !important;
}
.tp-line-height-3 {
  line-height: 44px !important;
}
/* text align */
/* added .tp-text-align for stronger selector */
.tp-class-added.tp-text-align-1 {
  text-align: left !important;
}
.tp-class-added.tp-text-align-2 {
  text-align: right !important;
}
.tp-class-added.tp-text-align-3 {
  text-align: center !important;
}
.tp-class-added.tp-text-align-4 {
  text-align: justify !important;
}
.tp-class-added.tp-text-align-plus-justify.tp-text-align-1 {
  justify-content: left !important;
}
.tp-class-added.tp-text-align-plus-justify.tp-text-align-2 {
  justify-content: right !important;
}
.tp-class-added.tp-text-align-plus-justify.tp-text-align-3 {
  justify-content: center !important;
}
.tp-class-added.tp-text-align-plus-justify.tp-text-align-4 {
  justify-content: justify !important;
}
/* hide images */
.tp-hide-images-1 {
  background-image: none !important;
}
img.tp-hide-images-1 {
  visibility: hidden !important;
}
/* contrast */
/* invert colors */
.tp-contrast-1 {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}
.tp-contrast-1 #tamkinPlayerApp .tp-accessibility-sidebar {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

/* dark contrast */
.tp-contrast-2:not(a):not(img) {
  background-color: rgb(0, 0, 0) !important;
  border-color: rgb(255, 255, 255) !important;
  color: rgb(80, 208, 160) !important;
}
a.tp-contrast-2 {
  background-color: rgb(0, 0, 0) !important;
  border-color: rgb(255, 255, 255) !important;
  color: rgb(252, 255, 60) !important;
}
/* light contrast */
:not(a, img).tp-contrast-3 {
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(0, 0, 0) !important;
  color: rgb(0, 0, 0) !important;
}
a.tp-contrast-3 {
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(0, 0, 0) !important;
  color: rgb(0, 0, 211) !important;
}
/* saturation */
.tp-saturation-1 {
  filter: saturate(0.5) !important;
  -webkit-filter: saturate(0.5) !important;
}
.tp-saturation-2 {
  filter: saturate(3) !important;
  -webkit-filter: saturate(3) !important;
}
.tp-saturation-3 {
  filter: saturate(0) !important;
  -webkit-filter: saturate(0) !important;
}

/* cursor */
/* white big cursor */
.tp-cursor-1,
.tp-cursor-1 * {
  cursor: url("https://cdn.tamkin.app/images/arrow-large.svg"), auto !important;
}
/* black big cursor */
.tp-cursor-2,
.tp-cursor-2 * {
  cursor: url("https://cdn.tamkin.app/images/arrow-large-black.svg"), auto !important;
}
/* reading mask */
.tp-reading-mask {
  position: fixed;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10000;
}
.tp-reading-mask#top-mask {
  top: 0;
  border-bottom: 10px solid darkblue;
}
.tp-reading-mask#bottom-mask {
  bottom: 0;
  border-top: 10px solid var(--tp-primary);
}
/* reading guide */
.tp-reading-guide {
  position: fixed;
  background-color: black;
  height: 10px;
  border: 3px solid yellow;
  border-radius: 10px;
  max-width: 100%;
  width: 530px;
  pointer-events: none; /* Ensures the line doesn't interfere with text selection */
  z-index: 10000;
}
.tp-reading-guide .marker {
  position: fixed;
  pointer-events: none; /* Ensures the marker doesn't interfere with text selection */
}

.tp-reading-guide .marker::before {
  content: "";
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff300;
  border-width: 17px;
  margin-left: -17px;
}
.tp-reading-guide .marker::after {
  content: "";
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #000;
  border-width: 14px;
  margin-left: -14px;
}
/* tooltip */
.tp-tooltip-container {
  display: none;
  white-space: nowrap;
  position: absolute !important;
  box-sizing: border-box !important;
  border: solid 2px #fff !important;
  background: #34394d !important;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  border-radius: 10px;
  padding: 13px 18px;
  box-shadow: 0 0 15px rgba(66, 73, 96, 0.4);
  height: auto !important;
  z-index: 2147483647;
  pointer-events: none;
}

/* dyslexia friendly */
.tp-dyslexia-friendly-1 {
  font-family: "UDF", sans-serif;
}
.tp-dyslexia-friendly-2 {
  font-family: Arial, serif;
}
/* pause animation */
.stoptransform {
  transform: none !important;
}
.tp-pause-animation-1 {
  transition: all 0s !important;
  transition-timing-function: step-end !important;
  transition-duration: 0s !important;
  animation-timing-function: step-end !important;
  animation-iteration-count: 1 !important;
  animation-duration: 0s !important;
  animation: none !important;
}
/* reading mode */
.reading-mode {
}

/* .reading-mode-container * {
    max-width: 800px;
    margin: auto;
    padding: 20px;
} */

#reading-mode-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f4f4f4;
  color: #333;
  font-size: 18px;
  line-height: 1.6;
  overflow-y: scroll;
  box-sizing: border-box;
  z-index: 1000;
}
#reading-mode-container.tp-dark-reading-mode {
  color: #f4f4f4;
  background-color: #333;
}
#reading-mode-container #reading-mode-inner {
  max-width: 800px;
  margin: auto;
}
#reading-mode-container #reading-mode-inner * {
  margin-bottom: 20px;
}
/* #reading-mode-container #reading-mode-inner h2 {
    margin-top: 40px;
} */
.reading-mode-d-none {
  display: none !important;
}

.iconCode {
  /* background: linear-gradient(
    180deg,
    var(--tp-primary) 0%,
    var(--tp-primary-grad) 100%
  ); */
  background-color: var(--tp-primary);
  width: calc(var(--tp-accessibility-icon-width) + 8px);
  height: calc(var(--tp-accessibility-icon-height) + 8px);
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}
.iconWorld img {
  width: var(--tp-accessibility-icon-width);
  height: var(--tp-accessibility-icon-height);
}
.iconWorld {
  /* background: linear-gradient(
    180deg,
    var(--tp-primary) 0%,
    var(--tp-primary-grad) 100%
  ); */
  background-color: var(--tp-primary);
  border-radius: 4px;
  padding: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconFlag {
  width: calc(var(--tp-accessibility-icon-width) + 8px);
  height: calc(var(--tp-accessibility-icon-height) + 8px);
}
.iconcodelang {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  /* background: linear-gradient(180deg, #2dada3 0%, #71dad2 100%); */
  background-color: var(--tp-primary);
}

.tp-lang-highlight {
  background-color: #d0f0fd !important;
}

#tamkinPlayerApp .modal {
  --bs-modal-zindex: 9999999 !important;
}

.tp-reset-button-spinner {
  width: 25px;
  height: 25px;
  border: 3.91px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  border-radius: 50%;
  animation: tp-reset-spin 1s linear infinite;
  background-origin: border-box;
  background-clip: border-box;
}

@keyframes tp-reset-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#tamkinPlayerApp button,#tamkinPlayerApp .btn ,#tamkinPlayerApp a{
  min-width: auto !important;
}
#tamkinPlayerApp .btn::before,
#tamkinPlayerApp button::before,
#tamkinPlayerApp a::before {
  background-color: transparent !important;
}

#tamkinPlayerApp .btn:focus,
#tamkinPlayerApp button:focus,
#tamkinPlayerApp a:focus {
  box-shadow: none !important;
}
