#tamkinPlayerApp {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #0d6efd;
    --bs-link-hover-color: #0a58ca;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd
}
#tamkinPlayerApp *, #tamkinPlayerApp ::after, #tamkinPlayerApp ::before{
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
    #tamkinPlayerApp {
        scroll-behavior: smooth
    }
}
#tamkinPlayerApp {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}
#tamkinPlayerApp hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: .25
}
#tamkinPlayerApp .h1, #tamkinPlayerApp .h2, #tamkinPlayerApp .h3, #tamkinPlayerApp .h4, #tamkinPlayerApp .h5, #tamkinPlayerApp .h6, #tamkinPlayerApp h1, #tamkinPlayerApp h2, #tamkinPlayerApp h3, #tamkinPlayerApp h4, #tamkinPlayerApp h5, #tamkinPlayerApp h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}
#tamkinPlayerApp .h1, #tamkinPlayerApp h1{
    font-size: calc(1.375rem + 1.5vw)
}

@media (min-width:1200px) {

    #tamkinPlayerApp .h1,
    #tamkinPlayerApp h1 {
        font-size: 2.5rem
    }
}
#tamkinPlayerApp .h2, #tamkinPlayerApp h2{
    font-size: calc(1.325rem + .9vw)
}

@media (min-width:1200px) {

    #tamkinPlayerApp .h2,
    #tamkinPlayerApp h2 {
        font-size: 2rem
    }
}
#tamkinPlayerApp .h3, #tamkinPlayerApp h3{
    font-size: calc(1.3rem + .6vw)
}

@media (min-width:1200px) {

    #tamkinPlayerApp .h3,
    #tamkinPlayerApp h3 {
        font-size: 1.75rem
    }
}
#tamkinPlayerApp .h4, #tamkinPlayerApp h4{
    font-size: calc(1.275rem + .3vw)
}

@media (min-width:1200px) {

    #tamkinPlayerApp .h4,
    #tamkinPlayerApp h4 {
        font-size: 1.5rem
    }
}
#tamkinPlayerApp .h5, #tamkinPlayerApp h5{
    font-size: 1.25rem
}
#tamkinPlayerApp .h6, #tamkinPlayerApp h6{
    font-size: 1rem
}
#tamkinPlayerApp p{
    margin-top: 0;
    margin-bottom: 1rem
}
#tamkinPlayerApp abbr[title]{
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}
#tamkinPlayerApp address{
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}
#tamkinPlayerApp ol, #tamkinPlayerApp ul{
    padding-left: 2rem
}
#tamkinPlayerApp dl, #tamkinPlayerApp ol, #tamkinPlayerApp ul{
    margin-top: 0;
    margin-bottom: 1rem
}
#tamkinPlayerApp ol ol, #tamkinPlayerApp ol ul, #tamkinPlayerApp ul ol, #tamkinPlayerApp ul ul{
    margin-bottom: 0
}
#tamkinPlayerApp dt{
    font-weight: 700
}
#tamkinPlayerApp dd{
    margin-bottom: .5rem;
    margin-left: 0
}
#tamkinPlayerApp blockquote{
    margin: 0 0 1rem
}
#tamkinPlayerApp b, #tamkinPlayerApp strong{
    font-weight: bolder
}
#tamkinPlayerApp .small, #tamkinPlayerApp small{
    font-size: .875em
}
#tamkinPlayerApp .mark, #tamkinPlayerApp mark{
    padding: .1875em;
    background-color: var(--bs-highlight-bg)
}
#tamkinPlayerApp sub, #tamkinPlayerApp sup{
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}
#tamkinPlayerApp sub{
    bottom: -.25em
}
#tamkinPlayerApp sup{
    top: -.5em
}
#tamkinPlayerApp a{
    color: var(--bs-link-color);
    text-decoration: underline
}
#tamkinPlayerApp a:hover{
    color: var(--bs-link-hover-color)
}
#tamkinPlayerApp a:not([href]):not([class]), #tamkinPlayerApp a:not([href]):not([class]):hover{
    color: inherit;
    text-decoration: none
}
#tamkinPlayerApp code, #tamkinPlayerApp kbd, #tamkinPlayerApp pre, #tamkinPlayerApp samp{
    font-family: var(--bs-font-monospace);
    font-size: 1em
}
#tamkinPlayerApp pre{
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875em
}
#tamkinPlayerApp pre code{
    font-size: inherit;
    color: inherit;
    word-break: normal
}
#tamkinPlayerApp code{
    font-size: .875em;
    color: var(--bs-code-color);
    word-wrap: break-word
}
#tamkinPlayerApp a>code{
    color: inherit
}
#tamkinPlayerApp kbd{
    padding: .1875rem .375rem;
    font-size: .875em;
    color: var(--bs-body-bg);
    background-color: var(--bs-body-color);
    border-radius: .25rem
}
#tamkinPlayerApp kbd kbd{
    padding: 0;
    font-size: 1em
}
#tamkinPlayerApp figure{
    margin: 0 0 1rem
}
#tamkinPlayerApp img, #tamkinPlayerApp svg{
    vertical-align: middle
}
#tamkinPlayerApp table{
    caption-side: bottom;
    border-collapse: collapse
}
#tamkinPlayerApp caption{
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: #6c757d;
    text-align: left
}
#tamkinPlayerApp th{
    text-align: inherit;
    text-align: -webkit-match-parent
}
#tamkinPlayerApp tbody, #tamkinPlayerApp td, #tamkinPlayerApp tfoot, #tamkinPlayerApp th, #tamkinPlayerApp thead, #tamkinPlayerApp tr{
    border-color: inherit;
    border-style: solid;
    border-width: 0
}
#tamkinPlayerApp label{
    display: inline-block
}
#tamkinPlayerApp button{
    border-radius: 0
}
#tamkinPlayerApp button:focus:not(:focus-visible){
    outline: 0
}
#tamkinPlayerApp button, #tamkinPlayerApp input, #tamkinPlayerApp optgroup, #tamkinPlayerApp select, #tamkinPlayerApp textarea{
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}
#tamkinPlayerApp button, #tamkinPlayerApp select{
    text-transform: none
}
#tamkinPlayerApp [role=button]{
    cursor: pointer
}
#tamkinPlayerApp select{
    word-wrap: normal
}
#tamkinPlayerApp select:disabled{
    opacity: 1
}
#tamkinPlayerApp [list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator{
    display: none !important
}
#tamkinPlayerApp [type=button], #tamkinPlayerApp [type=reset], #tamkinPlayerApp [type=submit], #tamkinPlayerApp button{
    -webkit-appearance: button
}
#tamkinPlayerApp [type=button]:not(:disabled), #tamkinPlayerApp [type=reset]:not(:disabled), #tamkinPlayerApp [type=submit]:not(:disabled), #tamkinPlayerApp button:not(:disabled){
    cursor: pointer
}
#tamkinPlayerApp ::-moz-focus-inner{
    padding: 0;
    border-style: none
}
#tamkinPlayerApp textarea{
    resize: vertical
}
#tamkinPlayerApp fieldset{
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}
#tamkinPlayerApp legend{
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
}

@media (min-width:1200px) {
    #tamkinPlayerApp legend {
        font-size: 1.5rem
    }
}
#tamkinPlayerApp legend+*{
    clear: left
}
#tamkinPlayerApp ::-webkit-datetime-edit-day-field, #tamkinPlayerApp ::-webkit-datetime-edit-fields-wrapper, #tamkinPlayerApp ::-webkit-datetime-edit-hour-field, #tamkinPlayerApp ::-webkit-datetime-edit-minute, #tamkinPlayerApp ::-webkit-datetime-edit-month-field, #tamkinPlayerApp ::-webkit-datetime-edit-text, #tamkinPlayerApp ::-webkit-datetime-edit-year-field{
    padding: 0
}
#tamkinPlayerApp ::-webkit-inner-spin-button{
    height: auto
}
#tamkinPlayerApp [type=search]{
    outline-offset: -2px;
    -webkit-appearance: textfield
}
#tamkinPlayerApp ::-webkit-search-decoration{
    -webkit-appearance: none
}
#tamkinPlayerApp ::-webkit-color-swatch-wrapper{
    padding: 0
}
#tamkinPlayerApp ::-webkit-file-upload-button{
    font: inherit;
    -webkit-appearance: button
}
#tamkinPlayerApp ::file-selector-button{
    font: inherit;
    -webkit-appearance: button
}
#tamkinPlayerApp output{
    display: inline-block
}
#tamkinPlayerApp iframe{
    border: 0
}
#tamkinPlayerApp summary{
    display: list-item;
    cursor: pointer
}
#tamkinPlayerApp progress{
    vertical-align: baseline
}
#tamkinPlayerApp [hidden]{
    display: none !important
}
#tamkinPlayerApp .lead{
    font-size: 1.25rem;
    font-weight: 300
}
#tamkinPlayerApp .display-1{
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    #tamkinPlayerApp .display-1 {
        font-size: 5rem
    }
}
#tamkinPlayerApp .display-2{
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    #tamkinPlayerApp .display-2 {
        font-size: 4.5rem
    }
}
#tamkinPlayerApp .display-3{
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    #tamkinPlayerApp .display-3 {
        font-size: 4rem
    }
}
#tamkinPlayerApp .display-4{
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    #tamkinPlayerApp .display-4 {
        font-size: 3.5rem
    }
}
#tamkinPlayerApp .display-5{
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    #tamkinPlayerApp .display-5 {
        font-size: 3rem
    }
}
#tamkinPlayerApp .display-6{
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    #tamkinPlayerApp .display-6 {
        font-size: 2.5rem
    }
}
#tamkinPlayerApp .list-unstyled{
    padding-left: 0;
    list-style: none
}
#tamkinPlayerApp .list-inline{
    padding-left: 0;
    list-style: none
}
#tamkinPlayerApp .list-inline-item{
    display: inline-block
}
#tamkinPlayerApp .list-inline-item:not(:last-child){
    margin-right: .5rem
}
#tamkinPlayerApp .initialism{
    font-size: .875em;
    text-transform: uppercase
}
#tamkinPlayerApp .blockquote{
    margin-bottom: 1rem;
    font-size: 1.25rem
}
#tamkinPlayerApp .blockquote>:last-child{
    margin-bottom: 0
}
#tamkinPlayerApp .blockquote-footer{
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #6c757d
}
#tamkinPlayerApp .blockquote-footer::before{
    content: "— "
}
#tamkinPlayerApp .img-fluid{
    max-width: 100%;
    height: auto
}
#tamkinPlayerApp .img-thumbnail{
    padding: .25rem;
    background-color: #fff;
    border: 1px solid var(--bs-border-color);
    border-radius: .375rem;
    max-width: 100%;
    height: auto
}
#tamkinPlayerApp .figure{
    display: inline-block
}
#tamkinPlayerApp .figure-img{
    margin-bottom: .5rem;
    line-height: 1
}
#tamkinPlayerApp .figure-caption{
    font-size: .875em;
    color: #6c757d
}
#tamkinPlayerApp .container, #tamkinPlayerApp .container-fluid, #tamkinPlayerApp .container-lg, #tamkinPlayerApp .container-md, #tamkinPlayerApp .container-sm, #tamkinPlayerApp .container-xl, #tamkinPlayerApp .container-xxl{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {

    #tamkinPlayerApp .container,
    #tamkinPlayerApp .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {

    #tamkinPlayerApp .container,
    #tamkinPlayerApp .container-md,
    #tamkinPlayerApp .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {

    #tamkinPlayerApp .container,
    #tamkinPlayerApp .container-lg,
    #tamkinPlayerApp .container-md,
    #tamkinPlayerApp .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {

    #tamkinPlayerApp .container,
    #tamkinPlayerApp .container-lg,
    #tamkinPlayerApp .container-md,
    #tamkinPlayerApp .container-sm,
    #tamkinPlayerApp .container-xl {
        max-width: 1140px
    }
}

@media (min-width:1400px) {

    #tamkinPlayerApp .container,
    #tamkinPlayerApp .container-lg,
    #tamkinPlayerApp .container-md,
    #tamkinPlayerApp .container-sm,
    #tamkinPlayerApp .container-xl,
    #tamkinPlayerApp .container-xxl {
        max-width: 1312px
    }
}
#tamkinPlayerApp .row{
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
}
#tamkinPlayerApp .row>*{
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y)
}
#tamkinPlayerApp .col{
    flex: 1 0 0%
}
#tamkinPlayerApp .row-cols-auto>*{
    flex: 0 0 auto;
    width: auto
}
#tamkinPlayerApp .row-cols-1>*{
    flex: 0 0 auto;
    width: 100%
}
#tamkinPlayerApp .row-cols-2>*{
    flex: 0 0 auto;
    width: 50%
}
#tamkinPlayerApp .row-cols-3>*{
    flex: 0 0 auto;
    width: 33.3333333333%
}
#tamkinPlayerApp .row-cols-4>*{
    flex: 0 0 auto;
    width: 25%
}
#tamkinPlayerApp .row-cols-5>*{
    flex: 0 0 auto;
    width: 20%
}
#tamkinPlayerApp .row-cols-6>*{
    flex: 0 0 auto;
    width: 16.6666666667%
}
#tamkinPlayerApp .col-auto{
    flex: 0 0 auto;
    width: auto
}
#tamkinPlayerApp .col-1{
    flex: 0 0 auto;
    width: 8.33333333%
}
#tamkinPlayerApp .col-2{
    flex: 0 0 auto;
    width: 16.66666667%
}
#tamkinPlayerApp .col-3{
    flex: 0 0 auto;
    width: 25%
}
#tamkinPlayerApp .col-4{
    flex: 0 0 auto;
    width: 33.33333333%
}
#tamkinPlayerApp .col-5{
    flex: 0 0 auto;
    width: 41.66666667%
}
#tamkinPlayerApp .col-6{
    flex: 0 0 auto;
    width: 50%
}
#tamkinPlayerApp .col-7{
    flex: 0 0 auto;
    width: 58.33333333%
}
#tamkinPlayerApp .col-8{
    flex: 0 0 auto;
    width: 66.66666667%
}
#tamkinPlayerApp .col-9{
    flex: 0 0 auto;
    width: 75%
}
#tamkinPlayerApp .col-10{
    flex: 0 0 auto;
    width: 83.33333333%
}
#tamkinPlayerApp .col-11{
    flex: 0 0 auto;
    width: 91.66666667%
}
#tamkinPlayerApp .col-12{
    flex: 0 0 auto;
    width: 100%
}
#tamkinPlayerApp .offset-1{
    margin-left: 8.33333333%
}
#tamkinPlayerApp .offset-2{
    margin-left: 16.66666667%
}
#tamkinPlayerApp .offset-3{
    margin-left: 25%
}
#tamkinPlayerApp .offset-4{
    margin-left: 33.33333333%
}
#tamkinPlayerApp .offset-5{
    margin-left: 41.66666667%
}
#tamkinPlayerApp .offset-6{
    margin-left: 50%
}
#tamkinPlayerApp .offset-7{
    margin-left: 58.33333333%
}
#tamkinPlayerApp .offset-8{
    margin-left: 66.66666667%
}
#tamkinPlayerApp .offset-9{
    margin-left: 75%
}
#tamkinPlayerApp .offset-10{
    margin-left: 83.33333333%
}
#tamkinPlayerApp .offset-11{
    margin-left: 91.66666667%
}
#tamkinPlayerApp .g-0, #tamkinPlayerApp .gx-0{
    --bs-gutter-x: 0
}
#tamkinPlayerApp .g-0, #tamkinPlayerApp .gy-0{
    --bs-gutter-y: 0
}
#tamkinPlayerApp .g-1, #tamkinPlayerApp .gx-1{
    --bs-gutter-x: 0.25rem
}
#tamkinPlayerApp .g-1, #tamkinPlayerApp .gy-1{
    --bs-gutter-y: 0.25rem
}
#tamkinPlayerApp .g-2, #tamkinPlayerApp .gx-2{
    --bs-gutter-x: 0.5rem
}
#tamkinPlayerApp .g-2, #tamkinPlayerApp .gy-2{
    --bs-gutter-y: 0.5rem
}
#tamkinPlayerApp .g-3, #tamkinPlayerApp .gx-3{
    --bs-gutter-x: 1rem
}
#tamkinPlayerApp .g-3, #tamkinPlayerApp .gy-3{
    --bs-gutter-y: 1rem
}
#tamkinPlayerApp .g-4, #tamkinPlayerApp .gx-4{
    --bs-gutter-x: 1.5rem
}
#tamkinPlayerApp .g-4, #tamkinPlayerApp .gy-4{
    --bs-gutter-y: 1.5rem
}
#tamkinPlayerApp .g-5, #tamkinPlayerApp .gx-5{
    --bs-gutter-x: 3rem
}
#tamkinPlayerApp .g-5, #tamkinPlayerApp .gy-5{
    --bs-gutter-y: 3rem
}

@media (min-width:576px) {
    #tamkinPlayerApp .col-sm {
        flex: 1 0 0%
    }
    #tamkinPlayerApp .row-cols-sm-auto>*{
        flex: 0 0 auto;
        width: auto
    }
    #tamkinPlayerApp .row-cols-sm-1>*{
        flex: 0 0 auto;
        width: 100%
    }
    #tamkinPlayerApp .row-cols-sm-2>*{
        flex: 0 0 auto;
        width: 50%
    }
    #tamkinPlayerApp .row-cols-sm-3>*{
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    #tamkinPlayerApp .row-cols-sm-4>*{
        flex: 0 0 auto;
        width: 25%
    }
    #tamkinPlayerApp .row-cols-sm-5>*{
        flex: 0 0 auto;
        width: 20%
    }
    #tamkinPlayerApp .row-cols-sm-6>*{
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    #tamkinPlayerApp .col-sm-auto{
        flex: 0 0 auto;
        width: auto
    }
    #tamkinPlayerApp .col-sm-1{
        flex: 0 0 auto;
        width: 8.33333333%
    }
    #tamkinPlayerApp .col-sm-2{
        flex: 0 0 auto;
        width: 16.66666667%
    }
    #tamkinPlayerApp .col-sm-3{
        flex: 0 0 auto;
        width: 25%
    }
    #tamkinPlayerApp .col-sm-4{
        flex: 0 0 auto;
        width: 33.33333333%
    }
    #tamkinPlayerApp .col-sm-5{
        flex: 0 0 auto;
        width: 41.66666667%
    }
    #tamkinPlayerApp .col-sm-6{
        flex: 0 0 auto;
        width: 50%
    }
    #tamkinPlayerApp .col-sm-7{
        flex: 0 0 auto;
        width: 58.33333333%
    }
    #tamkinPlayerApp .col-sm-8{
        flex: 0 0 auto;
        width: 66.66666667%
    }
    #tamkinPlayerApp .col-sm-9{
        flex: 0 0 auto;
        width: 75%
    }
    #tamkinPlayerApp .col-sm-10{
        flex: 0 0 auto;
        width: 83.33333333%
    }
    #tamkinPlayerApp .col-sm-11{
        flex: 0 0 auto;
        width: 91.66666667%
    }
    #tamkinPlayerApp .col-sm-12{
        flex: 0 0 auto;
        width: 100%
    }
    #tamkinPlayerApp .offset-sm-0{
        margin-left: 0
    }
    #tamkinPlayerApp .offset-sm-1{
        margin-left: 8.33333333%
    }
    #tamkinPlayerApp .offset-sm-2{
        margin-left: 16.66666667%
    }
    #tamkinPlayerApp .offset-sm-3{
        margin-left: 25%
    }
    #tamkinPlayerApp .offset-sm-4{
        margin-left: 33.33333333%
    }
    #tamkinPlayerApp .offset-sm-5{
        margin-left: 41.66666667%
    }
    #tamkinPlayerApp .offset-sm-6{
        margin-left: 50%
    }
    #tamkinPlayerApp .offset-sm-7{
        margin-left: 58.33333333%
    }
    #tamkinPlayerApp .offset-sm-8{
        margin-left: 66.66666667%
    }
    #tamkinPlayerApp .offset-sm-9{
        margin-left: 75%
    }
    #tamkinPlayerApp .offset-sm-10{
        margin-left: 83.33333333%
    }
    #tamkinPlayerApp .offset-sm-11{
        margin-left: 91.66666667%
    }
    #tamkinPlayerApp .g-sm-0, #tamkinPlayerApp .gx-sm-0{
        --bs-gutter-x: 0
    }
    #tamkinPlayerApp .g-sm-0, #tamkinPlayerApp .gy-sm-0{
        --bs-gutter-y: 0
    }
    #tamkinPlayerApp .g-sm-1, #tamkinPlayerApp .gx-sm-1{
        --bs-gutter-x: 0.25rem
    }
    #tamkinPlayerApp .g-sm-1, #tamkinPlayerApp .gy-sm-1{
        --bs-gutter-y: 0.25rem
    }
    #tamkinPlayerApp .g-sm-2, #tamkinPlayerApp .gx-sm-2{
        --bs-gutter-x: 0.5rem
    }
    #tamkinPlayerApp .g-sm-2, #tamkinPlayerApp .gy-sm-2{
        --bs-gutter-y: 0.5rem
    }
    #tamkinPlayerApp .g-sm-3, #tamkinPlayerApp .gx-sm-3{
        --bs-gutter-x: 1rem
    }
    #tamkinPlayerApp .g-sm-3, #tamkinPlayerApp .gy-sm-3{
        --bs-gutter-y: 1rem
    }
    #tamkinPlayerApp .g-sm-4, #tamkinPlayerApp .gx-sm-4{
        --bs-gutter-x: 1.5rem
    }
    #tamkinPlayerApp .g-sm-4, #tamkinPlayerApp .gy-sm-4{
        --bs-gutter-y: 1.5rem
    }
    #tamkinPlayerApp .g-sm-5, #tamkinPlayerApp .gx-sm-5{
        --bs-gutter-x: 3rem
    }
    #tamkinPlayerApp .g-sm-5, #tamkinPlayerApp .gy-sm-5{
        --bs-gutter-y: 3rem
    }
}

@media (min-width:768px) {
    #tamkinPlayerApp .col-md {
        flex: 1 0 0%
    }
    #tamkinPlayerApp .row-cols-md-auto>*{
        flex: 0 0 auto;
        width: auto
    }
    #tamkinPlayerApp .row-cols-md-1>*{
        flex: 0 0 auto;
        width: 100%
    }
    #tamkinPlayerApp .row-cols-md-2>*{
        flex: 0 0 auto;
        width: 50%
    }
    #tamkinPlayerApp .row-cols-md-3>*{
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    #tamkinPlayerApp .row-cols-md-4>*{
        flex: 0 0 auto;
        width: 25%
    }
    #tamkinPlayerApp .row-cols-md-5>*{
        flex: 0 0 auto;
        width: 20%
    }
    #tamkinPlayerApp .row-cols-md-6>*{
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    #tamkinPlayerApp .col-md-auto{
        flex: 0 0 auto;
        width: auto
    }
    #tamkinPlayerApp .col-md-1{
        flex: 0 0 auto;
        width: 8.33333333%
    }
    #tamkinPlayerApp .col-md-2{
        flex: 0 0 auto;
        width: 16.66666667%
    }
    #tamkinPlayerApp .col-md-3{
        flex: 0 0 auto;
        width: 25%
    }
    #tamkinPlayerApp .col-md-4{
        flex: 0 0 auto;
        width: 33.33333333%
    }
    #tamkinPlayerApp .col-md-5{
        flex: 0 0 auto;
        width: 41.66666667%
    }
    #tamkinPlayerApp .col-md-6{
        flex: 0 0 auto;
        width: 50%
    }
    #tamkinPlayerApp .col-md-7{
        flex: 0 0 auto;
        width: 58.33333333%
    }
    #tamkinPlayerApp .col-md-8{
        flex: 0 0 auto;
        width: 66.66666667%
    }
    #tamkinPlayerApp .col-md-9{
        flex: 0 0 auto;
        width: 75%
    }
    #tamkinPlayerApp .col-md-10{
        flex: 0 0 auto;
        width: 83.33333333%
    }
    #tamkinPlayerApp .col-md-11{
        flex: 0 0 auto;
        width: 91.66666667%
    }
    #tamkinPlayerApp .col-md-12{
        flex: 0 0 auto;
        width: 100%
    }
    #tamkinPlayerApp .offset-md-0{
        margin-left: 0
    }
    #tamkinPlayerApp .offset-md-1{
        margin-left: 8.33333333%
    }
    #tamkinPlayerApp .offset-md-2{
        margin-left: 16.66666667%
    }
    #tamkinPlayerApp .offset-md-3{
        margin-left: 25%
    }
    #tamkinPlayerApp .offset-md-4{
        margin-left: 33.33333333%
    }
    #tamkinPlayerApp .offset-md-5{
        margin-left: 41.66666667%
    }
    #tamkinPlayerApp .offset-md-6{
        margin-left: 50%
    }
    #tamkinPlayerApp .offset-md-7{
        margin-left: 58.33333333%
    }
    #tamkinPlayerApp .offset-md-8{
        margin-left: 66.66666667%
    }
    #tamkinPlayerApp .offset-md-9{
        margin-left: 75%
    }
    #tamkinPlayerApp .offset-md-10{
        margin-left: 83.33333333%
    }
    #tamkinPlayerApp .offset-md-11{
        margin-left: 91.66666667%
    }
    #tamkinPlayerApp .g-md-0, #tamkinPlayerApp .gx-md-0{
        --bs-gutter-x: 0
    }
    #tamkinPlayerApp .g-md-0, #tamkinPlayerApp .gy-md-0{
        --bs-gutter-y: 0
    }
    #tamkinPlayerApp .g-md-1, #tamkinPlayerApp .gx-md-1{
        --bs-gutter-x: 0.25rem
    }
    #tamkinPlayerApp .g-md-1, #tamkinPlayerApp .gy-md-1{
        --bs-gutter-y: 0.25rem
    }
    #tamkinPlayerApp .g-md-2, #tamkinPlayerApp .gx-md-2{
        --bs-gutter-x: 0.5rem
    }
    #tamkinPlayerApp .g-md-2, #tamkinPlayerApp .gy-md-2{
        --bs-gutter-y: 0.5rem
    }
    #tamkinPlayerApp .g-md-3, #tamkinPlayerApp .gx-md-3{
        --bs-gutter-x: 1rem
    }
    #tamkinPlayerApp .g-md-3, #tamkinPlayerApp .gy-md-3{
        --bs-gutter-y: 1rem
    }
    #tamkinPlayerApp .g-md-4, #tamkinPlayerApp .gx-md-4{
        --bs-gutter-x: 1.5rem
    }
    #tamkinPlayerApp .g-md-4, #tamkinPlayerApp .gy-md-4{
        --bs-gutter-y: 1.5rem
    }
    #tamkinPlayerApp .g-md-5, #tamkinPlayerApp .gx-md-5{
        --bs-gutter-x: 3rem
    }
    #tamkinPlayerApp .g-md-5, #tamkinPlayerApp .gy-md-5{
        --bs-gutter-y: 3rem
    }
}

@media (min-width:992px) {
    #tamkinPlayerApp .col-lg {
        flex: 1 0 0%
    }
    #tamkinPlayerApp .row-cols-lg-auto>*{
        flex: 0 0 auto;
        width: auto
    }
    #tamkinPlayerApp .row-cols-lg-1>*{
        flex: 0 0 auto;
        width: 100%
    }
    #tamkinPlayerApp .row-cols-lg-2>*{
        flex: 0 0 auto;
        width: 50%
    }
    #tamkinPlayerApp .row-cols-lg-3>*{
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    #tamkinPlayerApp .row-cols-lg-4>*{
        flex: 0 0 auto;
        width: 25%
    }
    #tamkinPlayerApp .row-cols-lg-5>*{
        flex: 0 0 auto;
        width: 20%
    }
    #tamkinPlayerApp .row-cols-lg-6>*{
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    #tamkinPlayerApp .col-lg-auto{
        flex: 0 0 auto;
        width: auto
    }
    #tamkinPlayerApp .col-lg-1{
        flex: 0 0 auto;
        width: 8.33333333%
    }
    #tamkinPlayerApp .col-lg-2{
        flex: 0 0 auto;
        width: 16.66666667%
    }
    #tamkinPlayerApp .col-lg-3{
        flex: 0 0 auto;
        width: 25%
    }
    #tamkinPlayerApp .col-lg-4{
        flex: 0 0 auto;
        width: 33.33333333%
    }
    #tamkinPlayerApp .col-lg-5{
        flex: 0 0 auto;
        width: 41.66666667%
    }
    #tamkinPlayerApp .col-lg-6{
        flex: 0 0 auto;
        width: 50%
    }
    #tamkinPlayerApp .col-lg-7{
        flex: 0 0 auto;
        width: 58.33333333%
    }
    #tamkinPlayerApp .col-lg-8{
        flex: 0 0 auto;
        width: 66.66666667%
    }
    #tamkinPlayerApp .col-lg-9{
        flex: 0 0 auto;
        width: 75%
    }
    #tamkinPlayerApp .col-lg-10{
        flex: 0 0 auto;
        width: 83.33333333%
    }
    #tamkinPlayerApp .col-lg-11{
        flex: 0 0 auto;
        width: 91.66666667%
    }
    #tamkinPlayerApp .col-lg-12{
        flex: 0 0 auto;
        width: 100%
    }
    #tamkinPlayerApp .offset-lg-0{
        margin-left: 0
    }
    #tamkinPlayerApp .offset-lg-1{
        margin-left: 8.33333333%
    }
    #tamkinPlayerApp .offset-lg-2{
        margin-left: 16.66666667%
    }
    #tamkinPlayerApp .offset-lg-3{
        margin-left: 25%
    }
    #tamkinPlayerApp .offset-lg-4{
        margin-left: 33.33333333%
    }
    #tamkinPlayerApp .offset-lg-5{
        margin-left: 41.66666667%
    }
    #tamkinPlayerApp .offset-lg-6{
        margin-left: 50%
    }
    #tamkinPlayerApp .offset-lg-7{
        margin-left: 58.33333333%
    }
    #tamkinPlayerApp .offset-lg-8{
        margin-left: 66.66666667%
    }
    #tamkinPlayerApp .offset-lg-9{
        margin-left: 75%
    }
    #tamkinPlayerApp .offset-lg-10{
        margin-left: 83.33333333%
    }
    #tamkinPlayerApp .offset-lg-11{
        margin-left: 91.66666667%
    }
    #tamkinPlayerApp .g-lg-0, #tamkinPlayerApp .gx-lg-0{
        --bs-gutter-x: 0
    }
    #tamkinPlayerApp .g-lg-0, #tamkinPlayerApp .gy-lg-0{
        --bs-gutter-y: 0
    }
    #tamkinPlayerApp .g-lg-1, #tamkinPlayerApp .gx-lg-1{
        --bs-gutter-x: 0.25rem
    }
    #tamkinPlayerApp .g-lg-1, #tamkinPlayerApp .gy-lg-1{
        --bs-gutter-y: 0.25rem
    }
    #tamkinPlayerApp .g-lg-2, #tamkinPlayerApp .gx-lg-2{
        --bs-gutter-x: 0.5rem
    }
    #tamkinPlayerApp .g-lg-2, #tamkinPlayerApp .gy-lg-2{
        --bs-gutter-y: 0.5rem
    }
    #tamkinPlayerApp .g-lg-3, #tamkinPlayerApp .gx-lg-3{
        --bs-gutter-x: 1rem
    }
    #tamkinPlayerApp .g-lg-3, #tamkinPlayerApp .gy-lg-3{
        --bs-gutter-y: 1rem
    }
    #tamkinPlayerApp .g-lg-4, #tamkinPlayerApp .gx-lg-4{
        --bs-gutter-x: 1.5rem
    }
    #tamkinPlayerApp .g-lg-4, #tamkinPlayerApp .gy-lg-4{
        --bs-gutter-y: 1.5rem
    }
    #tamkinPlayerApp .g-lg-5, #tamkinPlayerApp .gx-lg-5{
        --bs-gutter-x: 3rem
    }
    #tamkinPlayerApp .g-lg-5, #tamkinPlayerApp .gy-lg-5{
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1200px) {
    #tamkinPlayerApp .col-xl {
        flex: 1 0 0%
    }
    #tamkinPlayerApp .row-cols-xl-auto>*{
        flex: 0 0 auto;
        width: auto
    }
    #tamkinPlayerApp .row-cols-xl-1>*{
        flex: 0 0 auto;
        width: 100%
    }
    #tamkinPlayerApp .row-cols-xl-2>*{
        flex: 0 0 auto;
        width: 50%
    }
    #tamkinPlayerApp .row-cols-xl-3>*{
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    #tamkinPlayerApp .row-cols-xl-4>*{
        flex: 0 0 auto;
        width: 25%
    }
    #tamkinPlayerApp .row-cols-xl-5>*{
        flex: 0 0 auto;
        width: 20%
    }
    #tamkinPlayerApp .row-cols-xl-6>*{
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    #tamkinPlayerApp .col-xl-auto{
        flex: 0 0 auto;
        width: auto
    }
    #tamkinPlayerApp .col-xl-1{
        flex: 0 0 auto;
        width: 8.33333333%
    }
    #tamkinPlayerApp .col-xl-2{
        flex: 0 0 auto;
        width: 16.66666667%
    }
    #tamkinPlayerApp .col-xl-3{
        flex: 0 0 auto;
        width: 25%
    }
    #tamkinPlayerApp .col-xl-4{
        flex: 0 0 auto;
        width: 33.33333333%
    }
    #tamkinPlayerApp .col-xl-5{
        flex: 0 0 auto;
        width: 41.66666667%
    }
    #tamkinPlayerApp .col-xl-6{
        flex: 0 0 auto;
        width: 50%
    }
    #tamkinPlayerApp .col-xl-7{
        flex: 0 0 auto;
        width: 58.33333333%
    }
    #tamkinPlayerApp .col-xl-8{
        flex: 0 0 auto;
        width: 66.66666667%
    }
    #tamkinPlayerApp .col-xl-9{
        flex: 0 0 auto;
        width: 75%
    }
    #tamkinPlayerApp .col-xl-10{
        flex: 0 0 auto;
        width: 83.33333333%
    }
    #tamkinPlayerApp .col-xl-11{
        flex: 0 0 auto;
        width: 91.66666667%
    }
    #tamkinPlayerApp .col-xl-12{
        flex: 0 0 auto;
        width: 100%
    }
    #tamkinPlayerApp .offset-xl-0{
        margin-left: 0
    }
    #tamkinPlayerApp .offset-xl-1{
        margin-left: 8.33333333%
    }
    #tamkinPlayerApp .offset-xl-2{
        margin-left: 16.66666667%
    }
    #tamkinPlayerApp .offset-xl-3{
        margin-left: 25%
    }
    #tamkinPlayerApp .offset-xl-4{
        margin-left: 33.33333333%
    }
    #tamkinPlayerApp .offset-xl-5{
        margin-left: 41.66666667%
    }
    #tamkinPlayerApp .offset-xl-6{
        margin-left: 50%
    }
    #tamkinPlayerApp .offset-xl-7{
        margin-left: 58.33333333%
    }
    #tamkinPlayerApp .offset-xl-8{
        margin-left: 66.66666667%
    }
    #tamkinPlayerApp .offset-xl-9{
        margin-left: 75%
    }
    #tamkinPlayerApp .offset-xl-10{
        margin-left: 83.33333333%
    }
    #tamkinPlayerApp .offset-xl-11{
        margin-left: 91.66666667%
    }
    #tamkinPlayerApp .g-xl-0, #tamkinPlayerApp .gx-xl-0{
        --bs-gutter-x: 0
    }
    #tamkinPlayerApp .g-xl-0, #tamkinPlayerApp .gy-xl-0{
        --bs-gutter-y: 0
    }
    #tamkinPlayerApp .g-xl-1, #tamkinPlayerApp .gx-xl-1{
        --bs-gutter-x: 0.25rem
    }
    #tamkinPlayerApp .g-xl-1, #tamkinPlayerApp .gy-xl-1{
        --bs-gutter-y: 0.25rem
    }
    #tamkinPlayerApp .g-xl-2, #tamkinPlayerApp .gx-xl-2{
        --bs-gutter-x: 0.5rem
    }
    #tamkinPlayerApp .g-xl-2, #tamkinPlayerApp .gy-xl-2{
        --bs-gutter-y: 0.5rem
    }
    #tamkinPlayerApp .g-xl-3, #tamkinPlayerApp .gx-xl-3{
        --bs-gutter-x: 1rem
    }
    #tamkinPlayerApp .g-xl-3, #tamkinPlayerApp .gy-xl-3{
        --bs-gutter-y: 1rem
    }
    #tamkinPlayerApp .g-xl-4, #tamkinPlayerApp .gx-xl-4{
        --bs-gutter-x: 1.5rem
    }
    #tamkinPlayerApp .g-xl-4, #tamkinPlayerApp .gy-xl-4{
        --bs-gutter-y: 1.5rem
    }
    #tamkinPlayerApp .g-xl-5, #tamkinPlayerApp .gx-xl-5{
        --bs-gutter-x: 3rem
    }
    #tamkinPlayerApp .g-xl-5, #tamkinPlayerApp .gy-xl-5{
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1400px) {
    #tamkinPlayerApp .col-xxl {
        flex: 1 0 0%
    }
    #tamkinPlayerApp .row-cols-xxl-auto>*{
        flex: 0 0 auto;
        width: auto
    }
    #tamkinPlayerApp .row-cols-xxl-1>*{
        flex: 0 0 auto;
        width: 100%
    }
    #tamkinPlayerApp .row-cols-xxl-2>*{
        flex: 0 0 auto;
        width: 50%
    }
    #tamkinPlayerApp .row-cols-xxl-3>*{
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    #tamkinPlayerApp .row-cols-xxl-4>*{
        flex: 0 0 auto;
        width: 25%
    }
    #tamkinPlayerApp .row-cols-xxl-5>*{
        flex: 0 0 auto;
        width: 20%
    }
    #tamkinPlayerApp .row-cols-xxl-6>*{
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    #tamkinPlayerApp .col-xxl-auto{
        flex: 0 0 auto;
        width: auto
    }
    #tamkinPlayerApp .col-xxl-1{
        flex: 0 0 auto;
        width: 8.33333333%
    }
    #tamkinPlayerApp .col-xxl-2{
        flex: 0 0 auto;
        width: 16.66666667%
    }
    #tamkinPlayerApp .col-xxl-3{
        flex: 0 0 auto;
        width: 25%
    }
    #tamkinPlayerApp .col-xxl-4{
        flex: 0 0 auto;
        width: 33.33333333%
    }
    #tamkinPlayerApp .col-xxl-5{
        flex: 0 0 auto;
        width: 41.66666667%
    }
    #tamkinPlayerApp .col-xxl-6{
        flex: 0 0 auto;
        width: 50%
    }
    #tamkinPlayerApp .col-xxl-7{
        flex: 0 0 auto;
        width: 58.33333333%
    }
    #tamkinPlayerApp .col-xxl-8{
        flex: 0 0 auto;
        width: 66.66666667%
    }
    #tamkinPlayerApp .col-xxl-9{
        flex: 0 0 auto;
        width: 75%
    }
    #tamkinPlayerApp .col-xxl-10{
        flex: 0 0 auto;
        width: 83.33333333%
    }
    #tamkinPlayerApp .col-xxl-11{
        flex: 0 0 auto;
        width: 91.66666667%
    }
    #tamkinPlayerApp .col-xxl-12{
        flex: 0 0 auto;
        width: 100%
    }
    #tamkinPlayerApp .offset-xxl-0{
        margin-left: 0
    }
    #tamkinPlayerApp .offset-xxl-1{
        margin-left: 8.33333333%
    }
    #tamkinPlayerApp .offset-xxl-2{
        margin-left: 16.66666667%
    }
    #tamkinPlayerApp .offset-xxl-3{
        margin-left: 25%
    }
    #tamkinPlayerApp .offset-xxl-4{
        margin-left: 33.33333333%
    }
    #tamkinPlayerApp .offset-xxl-5{
        margin-left: 41.66666667%
    }
    #tamkinPlayerApp .offset-xxl-6{
        margin-left: 50%
    }
    #tamkinPlayerApp .offset-xxl-7{
        margin-left: 58.33333333%
    }
    #tamkinPlayerApp .offset-xxl-8{
        margin-left: 66.66666667%
    }
    #tamkinPlayerApp .offset-xxl-9{
        margin-left: 75%
    }
    #tamkinPlayerApp .offset-xxl-10{
        margin-left: 83.33333333%
    }
    #tamkinPlayerApp .offset-xxl-11{
        margin-left: 91.66666667%
    }
    #tamkinPlayerApp .g-xxl-0, #tamkinPlayerApp .gx-xxl-0{
        --bs-gutter-x: 0
    }
    #tamkinPlayerApp .g-xxl-0, #tamkinPlayerApp .gy-xxl-0{
        --bs-gutter-y: 0
    }
    #tamkinPlayerApp .g-xxl-1, #tamkinPlayerApp .gx-xxl-1{
        --bs-gutter-x: 0.25rem
    }
    #tamkinPlayerApp .g-xxl-1, #tamkinPlayerApp .gy-xxl-1{
        --bs-gutter-y: 0.25rem
    }
    #tamkinPlayerApp .g-xxl-2, #tamkinPlayerApp .gx-xxl-2{
        --bs-gutter-x: 0.5rem
    }
    #tamkinPlayerApp .g-xxl-2, #tamkinPlayerApp .gy-xxl-2{
        --bs-gutter-y: 0.5rem
    }
    #tamkinPlayerApp .g-xxl-3, #tamkinPlayerApp .gx-xxl-3{
        --bs-gutter-x: 1rem
    }
    #tamkinPlayerApp .g-xxl-3, #tamkinPlayerApp .gy-xxl-3{
        --bs-gutter-y: 1rem
    }
    #tamkinPlayerApp .g-xxl-4, #tamkinPlayerApp .gx-xxl-4{
        --bs-gutter-x: 1.5rem
    }
    #tamkinPlayerApp .g-xxl-4, #tamkinPlayerApp .gy-xxl-4{
        --bs-gutter-y: 1.5rem
    }
    #tamkinPlayerApp .g-xxl-5, #tamkinPlayerApp .gx-xxl-5{
        --bs-gutter-x: 3rem
    }
    #tamkinPlayerApp .g-xxl-5, #tamkinPlayerApp .gy-xxl-5{
        --bs-gutter-y: 3rem
    }
}
#tamkinPlayerApp .table{
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    border-color: var(--bs-table-border-color)
}
#tamkinPlayerApp .table>:not(caption)>*>*{
    padding: .5rem .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}
#tamkinPlayerApp .table>tbody{
    vertical-align: inherit
}
#tamkinPlayerApp .table>thead{
    vertical-align: bottom
}
#tamkinPlayerApp .table-group-divider{
    border-top: 2px solid currentcolor
}
#tamkinPlayerApp .caption-top{
    caption-side: top
}
#tamkinPlayerApp .table-sm>:not(caption)>*>*{
    padding: .25rem .25rem
}
#tamkinPlayerApp .table-bordered>:not(caption)>*{
    border-width: 1px 0
}
#tamkinPlayerApp .table-bordered>:not(caption)>*>*{
    border-width: 0 1px
}
#tamkinPlayerApp .table-borderless>:not(caption)>*>*{
    border-bottom-width: 0
}
#tamkinPlayerApp .table-borderless>:not(:first-child){
    border-top-width: 0
}
#tamkinPlayerApp .table-striped>tbody>tr:nth-of-type(odd)>*{
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}
#tamkinPlayerApp .table-striped-columns>:not(caption)>tr>:nth-child(2n){
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}
#tamkinPlayerApp .table-active{
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}
#tamkinPlayerApp .table-hover>tbody>tr:hover>*{
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}
#tamkinPlayerApp .table-primary{
    --bs-table-color: #000;
    --bs-table-bg: #cfe2ff;
    --bs-table-border-color: #bacbe6;
    --bs-table-striped-bg: #c5d7f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}
#tamkinPlayerApp .table-secondary{
    --bs-table-color: #000;
    --bs-table-bg: #e2e3e5;
    --bs-table-border-color: #cbccce;
    --bs-table-striped-bg: #d7d8da;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cbccce;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d1d2d4;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}
#tamkinPlayerApp .table-success{
    --bs-table-color: #000;
    --bs-table-bg: #d1e7dd;
    --bs-table-border-color: #bcd0c7;
    --bs-table-striped-bg: #c7dbd2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bcd0c7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c1d6cc;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}
#tamkinPlayerApp .table-info{
    --bs-table-color: #000;
    --bs-table-bg: #cff4fc;
    --bs-table-border-color: #badce3;
    --bs-table-striped-bg: #c5e8ef;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #badce3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfe2e9;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}
#tamkinPlayerApp .table-warning{
    --bs-table-color: #000;
    --bs-table-bg: #fff3cd;
    --bs-table-border-color: #e6dbb9;
    --bs-table-striped-bg: #f2e7c3;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6dbb9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece1be;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}
#tamkinPlayerApp .table-danger{
    --bs-table-color: #000;
    --bs-table-bg: #f8d7da;
    --bs-table-border-color: #dfc2c4;
    --bs-table-striped-bg: #eccccf;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfc2c4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5c7ca;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}
#tamkinPlayerApp .table-light{
    --bs-table-color: #000;
    --bs-table-bg: #f8f9fa;
    --bs-table-border-color: #dfe0e1;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}
#tamkinPlayerApp .table-dark{
    --bs-table-color: #fff;
    --bs-table-bg: #212529;
    --bs-table-border-color: #373b3e;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}
#tamkinPlayerApp .table-responsive{
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media (max-width:575.98px) {
    #tamkinPlayerApp .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:767.98px) {
    #tamkinPlayerApp .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:991.98px) {
    #tamkinPlayerApp .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1199.98px) {
    #tamkinPlayerApp .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1399.98px) {
    #tamkinPlayerApp .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}
#tamkinPlayerApp .form-label{
    margin-bottom: .5rem
}
#tamkinPlayerApp .col-form-label{
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}
#tamkinPlayerApp .col-form-label-lg{
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.25rem
}
#tamkinPlayerApp .col-form-label-sm{
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .875rem
}
#tamkinPlayerApp .form-text{
    margin-top: .25rem;
    font-size: .875em;
    color: #6c757d
}
#tamkinPlayerApp .form-control{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .form-control {
        transition: none
    }
}
#tamkinPlayerApp .form-control[type=file]{
    overflow: hidden
}
#tamkinPlayerApp .form-control[type=file]:not(:disabled):not([readonly]){
    cursor: pointer
}
#tamkinPlayerApp .form-control:focus{
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}
#tamkinPlayerApp .form-control::-webkit-date-and-time-value{
    height: 1.5em
}
#tamkinPlayerApp .form-control::-moz-placeholder{
    color: #6c757d;
    opacity: 1
}
#tamkinPlayerApp .form-control::placeholder{
    color: #6c757d;
    opacity: 1
}
#tamkinPlayerApp .form-control:disabled{
    background-color: #e9ecef;
    opacity: 1
}
#tamkinPlayerApp .form-control::-webkit-file-upload-button{
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}
#tamkinPlayerApp .form-control::file-selector-button{
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }
    #tamkinPlayerApp .form-control::file-selector-button{
        transition: none
    }
}
#tamkinPlayerApp .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button{
    background-color: #dde0e3
}
#tamkinPlayerApp .form-control:hover:not(:disabled):not([readonly])::file-selector-button{
    background-color: #dde0e3
}
#tamkinPlayerApp .form-control-plaintext{
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}
#tamkinPlayerApp .form-control-plaintext:focus{
    outline: 0
}
#tamkinPlayerApp .form-control-plaintext.form-control-lg, #tamkinPlayerApp .form-control-plaintext.form-control-sm{
    padding-right: 0;
    padding-left: 0
}
#tamkinPlayerApp .form-control-sm{
    min-height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .25rem
}
#tamkinPlayerApp .form-control-sm::-webkit-file-upload-button{
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
}
#tamkinPlayerApp .form-control-sm::file-selector-button{
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end: .5rem
}
#tamkinPlayerApp .form-control-lg{
    min-height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .5rem
}
#tamkinPlayerApp .form-control-lg::-webkit-file-upload-button{
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}
#tamkinPlayerApp .form-control-lg::file-selector-button{
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem
}
#tamkinPlayerApp textarea.form-control{
    min-height: calc(1.5em + .75rem + 2px)
}
#tamkinPlayerApp textarea.form-control-sm{
    min-height: calc(1.5em + .5rem + 2px)
}
#tamkinPlayerApp textarea.form-control-lg{
    min-height: calc(1.5em + 1rem + 2px)
}
#tamkinPlayerApp .form-control-color{
    width: 3rem;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem
}
#tamkinPlayerApp .form-control-color:not(:disabled):not([readonly]){
    cursor: pointer
}
#tamkinPlayerApp .form-control-color::-moz-color-swatch{
    border: 0 !important;
    border-radius: .375rem
}
#tamkinPlayerApp .form-control-color::-webkit-color-swatch{
    border-radius: .375rem
}
#tamkinPlayerApp .form-control-color.form-control-sm{
    height: calc(1.5em + .5rem + 2px)
}
#tamkinPlayerApp .form-control-color.form-control-lg{
    height: calc(1.5em + 1rem + 2px)
}
#tamkinPlayerApp .form-select{
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .form-select {
        transition: none
    }
}
#tamkinPlayerApp .form-select:focus{
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}
#tamkinPlayerApp .form-select[multiple], #tamkinPlayerApp .form-select[size]:not([size="1"]){
    padding-right: .75rem;
    background-image: none
}
#tamkinPlayerApp .form-select:disabled{
    background-color: #e9ecef
}
#tamkinPlayerApp .form-select:-moz-focusring{
    color: transparent;
    text-shadow: 0 0 0 #212529
}
#tamkinPlayerApp .form-select-sm{
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .875rem;
    border-radius: .25rem
}
#tamkinPlayerApp .form-select-lg{
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    border-radius: .5rem
}
#tamkinPlayerApp .form-check{
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: .125rem
}
#tamkinPlayerApp .form-check .form-check-input{
    float: left;
    margin-left: -1.5em
}
#tamkinPlayerApp .form-check-reverse{
    padding-right: 1.5em;
    padding-left: 0;
    text-align: right
}
#tamkinPlayerApp .form-check-reverse .form-check-input{
    float: right;
    margin-right: -1.5em;
    margin-left: 0
}
#tamkinPlayerApp .form-check-input{
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact
}
#tamkinPlayerApp .form-check-input[type=checkbox]{
    border-radius: .25em
}
#tamkinPlayerApp .form-check-input[type=radio]{
    border-radius: 50%
}
#tamkinPlayerApp .form-check-input:active{
    filter: brightness(90%)
}
#tamkinPlayerApp .form-check-input:focus{
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}
#tamkinPlayerApp .form-check-input:checked{
    background-color: #0d6efd;
    border-color: #0d6efd
}
#tamkinPlayerApp .form-check-input:checked[type=checkbox]{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e")
}
#tamkinPlayerApp .form-check-input:checked[type=radio]{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}
#tamkinPlayerApp .form-check-input[type=checkbox]:indeterminate{
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}
#tamkinPlayerApp .form-check-input:disabled{
    pointer-events: none;
    filter: none;
    opacity: .5
}
#tamkinPlayerApp .form-check-input:disabled~.form-check-label, #tamkinPlayerApp .form-check-input[disabled]~.form-check-label{
    cursor: default;
    opacity: .5
}
#tamkinPlayerApp .form-switch{
    padding-left: 2.5em
}
#tamkinPlayerApp .form-switch .form-check-input{
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .form-switch .form-check-input {
        transition: none
    }
}
#tamkinPlayerApp .form-switch .form-check-input:focus{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e")
}
#tamkinPlayerApp .form-switch .form-check-input:checked{
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}
#tamkinPlayerApp .form-switch.form-check-reverse{
    padding-right: 2.5em;
    padding-left: 0
}
#tamkinPlayerApp .form-switch.form-check-reverse .form-check-input{
    margin-right: -2.5em;
    margin-left: 0
}
#tamkinPlayerApp .form-check-inline{
    display: inline-block;
    margin-right: 1rem
}
#tamkinPlayerApp .btn-check{
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}
#tamkinPlayerApp .btn-check:disabled+.btn, #tamkinPlayerApp .btn-check[disabled]+.btn{
    pointer-events: none;
    filter: none;
    opacity: .65
}
#tamkinPlayerApp .form-range{
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}
#tamkinPlayerApp .form-range:focus{
    outline: 0
}
#tamkinPlayerApp .form-range:focus::-webkit-slider-thumb{
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}
#tamkinPlayerApp .form-range:focus::-moz-range-thumb{
    box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, .25)
}
#tamkinPlayerApp .form-range::-moz-focus-outer{
    border: 0
}
#tamkinPlayerApp .form-range::-webkit-slider-thumb{
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}
#tamkinPlayerApp .form-range::-webkit-slider-thumb:active{
    background-color: #b6d4fe
}
#tamkinPlayerApp .form-range::-webkit-slider-runnable-track{
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
}
#tamkinPlayerApp .form-range::-moz-range-thumb{
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}
#tamkinPlayerApp .form-range::-moz-range-thumb:active{
    background-color: #b6d4fe
}
#tamkinPlayerApp .form-range::-moz-range-track{
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
}
#tamkinPlayerApp .form-range:disabled{
    pointer-events: none
}
#tamkinPlayerApp .form-range:disabled::-webkit-slider-thumb{
    background-color: #adb5bd
}
#tamkinPlayerApp .form-range:disabled::-moz-range-thumb{
    background-color: #adb5bd
}
#tamkinPlayerApp .form-floating{
    position: relative
}
#tamkinPlayerApp .form-floating>.form-control, #tamkinPlayerApp .form-floating>.form-control-plaintext, #tamkinPlayerApp .form-floating>.form-select{
    height: calc(3.5rem + 2px);
    line-height: 1.25
}
#tamkinPlayerApp .form-floating>label{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem .75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .form-floating>label {
        transition: none
    }
}
#tamkinPlayerApp .form-floating>.form-control, #tamkinPlayerApp .form-floating>.form-control-plaintext{
    padding: 1rem .75rem
}
#tamkinPlayerApp .form-floating>.form-control-plaintext::-moz-placeholder, #tamkinPlayerApp .form-floating>.form-control::-moz-placeholder{
    color: transparent
}
#tamkinPlayerApp .form-floating>.form-control-plaintext::placeholder, #tamkinPlayerApp .form-floating>.form-control::placeholder{
    color: transparent
}
#tamkinPlayerApp .form-floating>.form-control-plaintext:not(:-moz-placeholder-shown), #tamkinPlayerApp .form-floating>.form-control:not(:-moz-placeholder-shown){
    padding-top: 1.625rem;
    padding-bottom: .625rem
}
#tamkinPlayerApp .form-floating>.form-control-plaintext:focus, #tamkinPlayerApp .form-floating>.form-control-plaintext:not(:placeholder-shown), #tamkinPlayerApp .form-floating>.form-control:focus, #tamkinPlayerApp .form-floating>.form-control:not(:placeholder-shown){
    padding-top: 1.625rem;
    padding-bottom: .625rem
}
#tamkinPlayerApp .form-floating>.form-control-plaintext:-webkit-autofill, #tamkinPlayerApp .form-floating>.form-control:-webkit-autofill{
    padding-top: 1.625rem;
    padding-bottom: .625rem
}
#tamkinPlayerApp .form-floating>.form-select{
    padding-top: 1.625rem;
    padding-bottom: .625rem
}
#tamkinPlayerApp .form-floating>.form-control:not(:-moz-placeholder-shown)~label{
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}
#tamkinPlayerApp .form-floating>.form-control-plaintext~label, #tamkinPlayerApp .form-floating>.form-control:focus~label, #tamkinPlayerApp .form-floating>.form-control:not(:placeholder-shown)~label, #tamkinPlayerApp .form-floating>.form-select~label{
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}
#tamkinPlayerApp .form-floating>.form-control:-webkit-autofill~label{
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}
#tamkinPlayerApp .form-floating>.form-control-plaintext~label{
    border-width: 1px 0
}
#tamkinPlayerApp .input-group{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}
#tamkinPlayerApp .input-group>.form-control, #tamkinPlayerApp .input-group>.form-floating, #tamkinPlayerApp .input-group>.form-select{
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}
#tamkinPlayerApp .input-group>.form-control:focus, #tamkinPlayerApp .input-group>.form-floating:focus-within, #tamkinPlayerApp .input-group>.form-select:focus{
    z-index: 5
}
#tamkinPlayerApp .input-group .btn{
    position: relative;
    z-index: 2
}
#tamkinPlayerApp .input-group .btn:focus{
    z-index: 5
}
#tamkinPlayerApp .input-group-text{
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .375rem
}
#tamkinPlayerApp .input-group-lg>.btn, #tamkinPlayerApp .input-group-lg>.form-control, #tamkinPlayerApp .input-group-lg>.form-select, #tamkinPlayerApp .input-group-lg>.input-group-text{
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .5rem
}
#tamkinPlayerApp .input-group-sm>.btn, #tamkinPlayerApp .input-group-sm>.form-control, #tamkinPlayerApp .input-group-sm>.form-select, #tamkinPlayerApp .input-group-sm>.input-group-text{
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .25rem
}
#tamkinPlayerApp .input-group-lg>.form-select, #tamkinPlayerApp .input-group-sm>.form-select{
    padding-right: 3rem
}
#tamkinPlayerApp .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), #tamkinPlayerApp .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, #tamkinPlayerApp .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select, #tamkinPlayerApp .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating){
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}
#tamkinPlayerApp .input-group.has-validation>.dropdown-toggle:nth-last-child(n+4), #tamkinPlayerApp .input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control, #tamkinPlayerApp .input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select, #tamkinPlayerApp .input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating){
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}
#tamkinPlayerApp .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}
#tamkinPlayerApp .input-group>.form-floating:not(:first-child)>.form-control, #tamkinPlayerApp .input-group>.form-floating:not(:first-child)>.form-select{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}
#tamkinPlayerApp .valid-feedback{
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #198754
}
#tamkinPlayerApp .valid-tooltip{
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(25, 135, 84, .9);
    border-radius: .375rem
}
#tamkinPlayerApp .is-valid~.valid-feedback, #tamkinPlayerApp .is-valid~.valid-tooltip, #tamkinPlayerApp .was-validated :valid~.valid-feedback, #tamkinPlayerApp .was-validated :valid~.valid-tooltip{
    display: block
}
#tamkinPlayerApp .form-control.is-valid, #tamkinPlayerApp .was-validated .form-control:valid{
    border-color: #198754;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}
#tamkinPlayerApp .form-control.is-valid:focus, #tamkinPlayerApp .was-validated .form-control:valid:focus{
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}
#tamkinPlayerApp .was-validated textarea.form-control:valid, #tamkinPlayerApp textarea.form-control.is-valid{
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}
#tamkinPlayerApp .form-select.is-valid, #tamkinPlayerApp .was-validated .form-select:valid{
    border-color: #198754
}
#tamkinPlayerApp .form-select.is-valid:not([multiple]):not([size]), #tamkinPlayerApp .form-select.is-valid:not([multiple])[size="1"], #tamkinPlayerApp .was-validated .form-select:valid:not([multiple]):not([size]), #tamkinPlayerApp .was-validated .form-select:valid:not([multiple])[size="1"]{
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}
#tamkinPlayerApp .form-select.is-valid:focus, #tamkinPlayerApp .was-validated .form-select:valid:focus{
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}
#tamkinPlayerApp .form-control-color.is-valid, #tamkinPlayerApp .was-validated .form-control-color:valid{
    width: calc(3rem + calc(1.5em + .75rem))
}
#tamkinPlayerApp .form-check-input.is-valid, #tamkinPlayerApp .was-validated .form-check-input:valid{
    border-color: #198754
}
#tamkinPlayerApp .form-check-input.is-valid:checked, #tamkinPlayerApp .was-validated .form-check-input:valid:checked{
    background-color: #198754
}
#tamkinPlayerApp .form-check-input.is-valid:focus, #tamkinPlayerApp .was-validated .form-check-input:valid:focus{
    box-shadow: 0 0 0 .25rem rgba(25, 135, 84, .25)
}
#tamkinPlayerApp .form-check-input.is-valid~.form-check-label, #tamkinPlayerApp .was-validated .form-check-input:valid~.form-check-label{
    color: #198754
}
#tamkinPlayerApp .form-check-inline .form-check-input~.valid-feedback{
    margin-left: .5em
}
#tamkinPlayerApp .input-group>.form-control:not(:focus).is-valid, #tamkinPlayerApp .input-group>.form-floating:not(:focus-within).is-valid, #tamkinPlayerApp .input-group>.form-select:not(:focus).is-valid, #tamkinPlayerApp .was-validated .input-group>.form-control:not(:focus):valid, #tamkinPlayerApp .was-validated .input-group>.form-floating:not(:focus-within):valid, #tamkinPlayerApp .was-validated .input-group>.form-select:not(:focus):valid{
    z-index: 3
}
#tamkinPlayerApp .invalid-feedback{
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545
}
#tamkinPlayerApp .invalid-tooltip{
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(220, 53, 69, .9);
    border-radius: .375rem
}
#tamkinPlayerApp .is-invalid~.invalid-feedback, #tamkinPlayerApp .is-invalid~.invalid-tooltip, #tamkinPlayerApp .was-validated :invalid~.invalid-feedback, #tamkinPlayerApp .was-validated :invalid~.invalid-tooltip{
    display: block
}
#tamkinPlayerApp .form-control.is-invalid, #tamkinPlayerApp .was-validated .form-control:invalid{
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}
#tamkinPlayerApp .form-control.is-invalid:focus, #tamkinPlayerApp .was-validated .form-control:invalid:focus{
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}
#tamkinPlayerApp .was-validated textarea.form-control:invalid, #tamkinPlayerApp textarea.form-control.is-invalid{
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}
#tamkinPlayerApp .form-select.is-invalid, #tamkinPlayerApp .was-validated .form-select:invalid{
    border-color: #dc3545
}
#tamkinPlayerApp .form-select.is-invalid:not([multiple]):not([size]), #tamkinPlayerApp .form-select.is-invalid:not([multiple])[size="1"], #tamkinPlayerApp .was-validated .form-select:invalid:not([multiple]):not([size]), #tamkinPlayerApp .was-validated .form-select:invalid:not([multiple])[size="1"]{
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}
#tamkinPlayerApp .form-select.is-invalid:focus, #tamkinPlayerApp .was-validated .form-select:invalid:focus{
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}
#tamkinPlayerApp .form-control-color.is-invalid, #tamkinPlayerApp .was-validated .form-control-color:invalid{
    width: calc(3rem + calc(1.5em + .75rem))
}
#tamkinPlayerApp .form-check-input.is-invalid, #tamkinPlayerApp .was-validated .form-check-input:invalid{
    border-color: #dc3545
}
#tamkinPlayerApp .form-check-input.is-invalid:checked, #tamkinPlayerApp .was-validated .form-check-input:invalid:checked{
    background-color: #dc3545
}
#tamkinPlayerApp .form-check-input.is-invalid:focus, #tamkinPlayerApp .was-validated .form-check-input:invalid:focus{
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25)
}
#tamkinPlayerApp .form-check-input.is-invalid~.form-check-label, #tamkinPlayerApp .was-validated .form-check-input:invalid~.form-check-label{
    color: #dc3545
}
#tamkinPlayerApp .form-check-inline .form-check-input~.invalid-feedback{
    margin-left: .5em
}
#tamkinPlayerApp .input-group>.form-control:not(:focus).is-invalid, #tamkinPlayerApp .input-group>.form-floating:not(:focus-within).is-invalid, #tamkinPlayerApp .input-group>.form-select:not(:focus).is-invalid, #tamkinPlayerApp .was-validated .input-group>.form-control:not(:focus):invalid, #tamkinPlayerApp .was-validated .input-group>.form-floating:not(:focus-within):invalid, #tamkinPlayerApp .was-validated .input-group>.form-select:not(:focus):invalid{
    z-index: 4
}
#tamkinPlayerApp .btn{
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: #212529;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.375rem;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .btn {
        transition: none
    }
}
#tamkinPlayerApp .btn:hover{
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color)
}
#tamkinPlayerApp .btn-check+.btn:hover{
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color)
}
#tamkinPlayerApp .btn:focus-visible{
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow)
}
#tamkinPlayerApp .btn-check:focus-visible+.btn{
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow)
}
#tamkinPlayerApp .btn-check:checked+.btn, #tamkinPlayerApp .btn.active, #tamkinPlayerApp .btn.show, #tamkinPlayerApp .btn:first-child:active, #tamkinPlayerApp :not(.btn-check)+.btn:active{
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color)
}
#tamkinPlayerApp .btn-check:checked+.btn:focus-visible, #tamkinPlayerApp .btn.active:focus-visible, #tamkinPlayerApp .btn.show:focus-visible, #tamkinPlayerApp .btn:first-child:active:focus-visible, #tamkinPlayerApp :not(.btn-check)+.btn:active:focus-visible{
    box-shadow: var(--bs-btn-focus-box-shadow)
}
#tamkinPlayerApp .btn.disabled, #tamkinPlayerApp .btn:disabled, #tamkinPlayerApp fieldset:disabled .btn{
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity)
}
#tamkinPlayerApp .btn-primary{
    --bs-btn-color: #fff;
    --bs-btn-bg: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd
}
#tamkinPlayerApp .btn-secondary{
    --bs-btn-color: #fff;
    --bs-btn-bg: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d
}
#tamkinPlayerApp .btn-success{
    --bs-btn-color: #fff;
    --bs-btn-bg: #198754;
    --bs-btn-border-color: #198754;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #157347;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #198754;
    --bs-btn-disabled-border-color: #198754
}
#tamkinPlayerApp .btn-info{
    --bs-btn-color: #000;
    --bs-btn-bg: #0dcaf0;
    --bs-btn-border-color: #0dcaf0;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #31d2f2;
    --bs-btn-hover-border-color: #25cff2;
    --bs-btn-focus-shadow-rgb: 11, 172, 204;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #3dd5f3;
    --bs-btn-active-border-color: #25cff2;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #0dcaf0;
    --bs-btn-disabled-border-color: #0dcaf0
}
#tamkinPlayerApp .btn-warning{
    --bs-btn-color: #000;
    --bs-btn-bg: #ffc107;
    --bs-btn-border-color: #ffc107;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #ffca2c;
    --bs-btn-hover-border-color: #ffc720;
    --bs-btn-focus-shadow-rgb: 217, 164, 6;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #ffcd39;
    --bs-btn-active-border-color: #ffc720;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #ffc107;
    --bs-btn-disabled-border-color: #ffc107
}
#tamkinPlayerApp .btn-danger{
    --bs-btn-color: #fff;
    --bs-btn-bg: #dc3545;
    --bs-btn-border-color: #dc3545;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #bb2d3b;
    --bs-btn-hover-border-color: #b02a37;
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b02a37;
    --bs-btn-active-border-color: #a52834;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #dc3545;
    --bs-btn-disabled-border-color: #dc3545
}
#tamkinPlayerApp .btn-light{
    --bs-btn-color: #000;
    --bs-btn-bg: #f8f9fa;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #d3d4d5;
    --bs-btn-hover-border-color: #c6c7c8;
    --bs-btn-focus-shadow-rgb: 211, 212, 213;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #c6c7c8;
    --bs-btn-active-border-color: #babbbc;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #f8f9fa;
    --bs-btn-disabled-border-color: #f8f9fa
}
#tamkinPlayerApp .btn-dark{
    --bs-btn-color: #fff;
    --bs-btn-bg: #212529;
    --bs-btn-border-color: #212529;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #424649;
    --bs-btn-hover-border-color: #373b3e;
    --bs-btn-focus-shadow-rgb: 66, 70, 73;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #4d5154;
    --bs-btn-active-border-color: #373b3e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #212529;
    --bs-btn-disabled-border-color: #212529
}
#tamkinPlayerApp .btn-outline-primary{
    --bs-btn-color: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0d6efd;
    --bs-btn-hover-border-color: #0d6efd;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0d6efd;
    --bs-btn-active-border-color: #0d6efd;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0d6efd;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0d6efd;
    --bs-gradient: none
}
#tamkinPlayerApp .btn-outline-secondary{
    --bs-btn-color: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6c757d;
    --bs-btn-hover-border-color: #6c757d;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #6c757d;
    --bs-btn-active-border-color: #6c757d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #6c757d;
    --bs-gradient: none
}
#tamkinPlayerApp .btn-outline-success{
    --bs-btn-color: #198754;
    --bs-btn-border-color: #198754;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #198754;
    --bs-btn-hover-border-color: #198754;
    --bs-btn-focus-shadow-rgb: 25, 135, 84;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #198754;
    --bs-btn-active-border-color: #198754;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #198754;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #198754;
    --bs-gradient: none
}
#tamkinPlayerApp .btn-outline-info{
    --bs-btn-color: #0dcaf0;
    --bs-btn-border-color: #0dcaf0;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #0dcaf0;
    --bs-btn-hover-border-color: #0dcaf0;
    --bs-btn-focus-shadow-rgb: 13, 202, 240;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #0dcaf0;
    --bs-btn-active-border-color: #0dcaf0;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0dcaf0;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0dcaf0;
    --bs-gradient: none
}
#tamkinPlayerApp .btn-outline-warning{
    --bs-btn-color: #ffc107;
    --bs-btn-border-color: #ffc107;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #ffc107;
    --bs-btn-hover-border-color: #ffc107;
    --bs-btn-focus-shadow-rgb: 255, 193, 7;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #ffc107;
    --bs-btn-active-border-color: #ffc107;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffc107;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #ffc107;
    --bs-gradient: none
}
#tamkinPlayerApp .btn-outline-danger{
    --bs-btn-color: #dc3545;
    --bs-btn-border-color: #dc3545;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #dc3545;
    --bs-btn-hover-border-color: #dc3545;
    --bs-btn-focus-shadow-rgb: 220, 53, 69;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #dc3545;
    --bs-btn-active-border-color: #dc3545;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #dc3545;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #dc3545;
    --bs-gradient: none
}
#tamkinPlayerApp .btn-outline-light{
    --bs-btn-color: #f8f9fa;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #f8f9fa;
    --bs-btn-hover-border-color: #f8f9fa;
    --bs-btn-focus-shadow-rgb: 248, 249, 250;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #f8f9fa;
    --bs-btn-active-border-color: #f8f9fa;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #f8f9fa;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f8f9fa;
    --bs-gradient: none
}
#tamkinPlayerApp .btn-outline-dark{
    --bs-btn-color: #212529;
    --bs-btn-border-color: #212529;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #212529;
    --bs-btn-hover-border-color: #212529;
    --bs-btn-focus-shadow-rgb: 33, 37, 41;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #212529;
    --bs-btn-active-border-color: #212529;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #212529;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #212529;
    --bs-gradient: none
}
#tamkinPlayerApp .btn-link{
    --bs-btn-font-weight: 400;
    --bs-btn-color: var(--bs-link-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--bs-link-hover-color);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: var(--bs-link-hover-color);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: underline
}
#tamkinPlayerApp .btn-link:focus-visible{
    color: var(--bs-btn-color)
}
#tamkinPlayerApp .btn-link:hover{
    color: var(--bs-btn-hover-color)
}
#tamkinPlayerApp .btn-group-lg>.btn, #tamkinPlayerApp .btn-lg{
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 1.25rem;
    --bs-btn-border-radius: 0.5rem
}
#tamkinPlayerApp .btn-group-sm>.btn, #tamkinPlayerApp .btn-sm{
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-border-radius: 0.25rem
}
#tamkinPlayerApp .fade{
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .fade {
        transition: none
    }
}
#tamkinPlayerApp .fade:not(.show){
    opacity: 0
}
#tamkinPlayerApp .collapse:not(.show){
    display: none
}
#tamkinPlayerApp .collapsing{
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .collapsing {
        transition: none
    }
}
#tamkinPlayerApp .collapsing.collapse-horizontal{
    width: 0;
    height: auto;
    transition: width .35s ease
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .collapsing.collapse-horizontal {
        transition: none
    }
}
#tamkinPlayerApp .dropdown, #tamkinPlayerApp .dropdown-center, #tamkinPlayerApp .dropend, #tamkinPlayerApp .dropstart, #tamkinPlayerApp .dropup, #tamkinPlayerApp .dropup-center{
    position: relative
}
#tamkinPlayerApp .dropdown-toggle{
    white-space: nowrap
}
#tamkinPlayerApp .dropdown-toggle::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}
#tamkinPlayerApp .dropdown-toggle:empty::after{
    margin-left: 0
}
#tamkinPlayerApp .dropdown-menu{
    --bs-dropdown-zindex: 1000;
    --bs-dropdown-min-width: 10rem;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 1rem;
    --bs-dropdown-color: #212529;
    --bs-dropdown-bg: #fff;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-border-radius: 0.375rem;
    --bs-dropdown-border-width: 1px;
    --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-dropdown-link-color: #212529;
    --bs-dropdown-link-hover-color: #1e2125;
    --bs-dropdown-link-hover-bg: #e9ecef;
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #0d6efd;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-item-padding-x: 1rem;
    --bs-dropdown-item-padding-y: 0.25rem;
    --bs-dropdown-header-color: #6c757d;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    display: none;
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius)
}
#tamkinPlayerApp .dropdown-menu[data-bs-popper]{
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer)
}
#tamkinPlayerApp .dropdown-menu-start{
    --bs-position: start
}
#tamkinPlayerApp .dropdown-menu-start[data-bs-popper]{
    right: auto;
    left: 0
}
#tamkinPlayerApp .dropdown-menu-end{
    --bs-position: end
}
#tamkinPlayerApp .dropdown-menu-end[data-bs-popper]{
    right: 0;
    left: auto
}

@media (min-width:576px) {
    #tamkinPlayerApp .dropdown-menu-sm-start {
        --bs-position: start
    }
    #tamkinPlayerApp .dropdown-menu-sm-start[data-bs-popper]{
        right: auto;
        left: 0
    }
    #tamkinPlayerApp .dropdown-menu-sm-end{
        --bs-position: end
    }
    #tamkinPlayerApp .dropdown-menu-sm-end[data-bs-popper]{
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    #tamkinPlayerApp .dropdown-menu-md-start {
        --bs-position: start
    }
    #tamkinPlayerApp .dropdown-menu-md-start[data-bs-popper]{
        right: auto;
        left: 0
    }
    #tamkinPlayerApp .dropdown-menu-md-end{
        --bs-position: end
    }
    #tamkinPlayerApp .dropdown-menu-md-end[data-bs-popper]{
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    #tamkinPlayerApp .dropdown-menu-lg-start {
        --bs-position: start
    }
    #tamkinPlayerApp .dropdown-menu-lg-start[data-bs-popper]{
        right: auto;
        left: 0
    }
    #tamkinPlayerApp .dropdown-menu-lg-end{
        --bs-position: end
    }
    #tamkinPlayerApp .dropdown-menu-lg-end[data-bs-popper]{
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    #tamkinPlayerApp .dropdown-menu-xl-start {
        --bs-position: start
    }
    #tamkinPlayerApp .dropdown-menu-xl-start[data-bs-popper]{
        right: auto;
        left: 0
    }
    #tamkinPlayerApp .dropdown-menu-xl-end{
        --bs-position: end
    }
    #tamkinPlayerApp .dropdown-menu-xl-end[data-bs-popper]{
        right: 0;
        left: auto
    }
}

@media (min-width:1400px) {
    #tamkinPlayerApp .dropdown-menu-xxl-start {
        --bs-position: start
    }
    #tamkinPlayerApp .dropdown-menu-xxl-start[data-bs-popper]{
        right: auto;
        left: 0
    }
    #tamkinPlayerApp .dropdown-menu-xxl-end{
        --bs-position: end
    }
    #tamkinPlayerApp .dropdown-menu-xxl-end[data-bs-popper]{
        right: 0;
        left: auto
    }
}
#tamkinPlayerApp .dropup .dropdown-menu[data-bs-popper]{
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--bs-dropdown-spacer)
}
#tamkinPlayerApp .dropup .dropdown-toggle::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}
#tamkinPlayerApp .dropup .dropdown-toggle:empty::after{
    margin-left: 0
}
#tamkinPlayerApp .dropend .dropdown-menu[data-bs-popper]{
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--bs-dropdown-spacer)
}
#tamkinPlayerApp .dropend .dropdown-toggle::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}
#tamkinPlayerApp .dropend .dropdown-toggle:empty::after{
    margin-left: 0
}
#tamkinPlayerApp .dropend .dropdown-toggle::after{
    vertical-align: 0
}
#tamkinPlayerApp .dropstart .dropdown-menu[data-bs-popper]{
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--bs-dropdown-spacer)
}
#tamkinPlayerApp .dropstart .dropdown-toggle::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}
#tamkinPlayerApp .dropstart .dropdown-toggle::after{
    display: none
}
#tamkinPlayerApp .dropstart .dropdown-toggle::before{
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}
#tamkinPlayerApp .dropstart .dropdown-toggle:empty::after{
    margin-left: 0
}
#tamkinPlayerApp .dropstart .dropdown-toggle::before{
    vertical-align: 0
}
#tamkinPlayerApp .dropdown-divider{
    height: 0;
    margin: var(--bs-dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid var(--bs-dropdown-divider-bg);
    opacity: 1
}
#tamkinPlayerApp .dropdown-item{
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}
#tamkinPlayerApp .dropdown-item:focus, #tamkinPlayerApp .dropdown-item:hover{
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg)
}
#tamkinPlayerApp .dropdown-item.active, #tamkinPlayerApp .dropdown-item:active{
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg)
}
#tamkinPlayerApp .dropdown-item.disabled, #tamkinPlayerApp .dropdown-item:disabled{
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent
}
#tamkinPlayerApp .dropdown-menu.show{
    display: block
}
#tamkinPlayerApp .dropdown-header{
    display: block;
    padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
    margin-bottom: 0;
    font-size: .875rem;
    color: var(--bs-dropdown-header-color);
    white-space: nowrap
}
#tamkinPlayerApp .dropdown-item-text{
    display: block;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    color: var(--bs-dropdown-link-color)
}
#tamkinPlayerApp .dropdown-menu-dark{
    --bs-dropdown-color: #dee2e6;
    --bs-dropdown-bg: #343a40;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-box-shadow: ;
    --bs-dropdown-link-color: #dee2e6;
    --bs-dropdown-link-hover-color: #fff;
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #0d6efd;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-header-color: #adb5bd
}
#tamkinPlayerApp .btn-group, #tamkinPlayerApp .btn-group-vertical{
    position: relative;
    display: inline-flex;
    vertical-align: middle
}
#tamkinPlayerApp .btn-group-vertical>.btn, #tamkinPlayerApp .btn-group>.btn{
    position: relative;
    flex: 1 1 auto
}
#tamkinPlayerApp .btn-group-vertical>.btn-check:checked+.btn, #tamkinPlayerApp .btn-group-vertical>.btn-check:focus+.btn, #tamkinPlayerApp .btn-group-vertical>.btn.active, #tamkinPlayerApp .btn-group-vertical>.btn:active, #tamkinPlayerApp .btn-group-vertical>.btn:focus, #tamkinPlayerApp .btn-group-vertical>.btn:hover, #tamkinPlayerApp .btn-group>.btn-check:checked+.btn, #tamkinPlayerApp .btn-group>.btn-check:focus+.btn, #tamkinPlayerApp .btn-group>.btn.active, #tamkinPlayerApp .btn-group>.btn:active, #tamkinPlayerApp .btn-group>.btn:focus, #tamkinPlayerApp .btn-group>.btn:hover{
    z-index: 1
}
#tamkinPlayerApp .btn-toolbar{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}
#tamkinPlayerApp .btn-toolbar .input-group{
    width: auto
}
#tamkinPlayerApp .btn-group{
    border-radius: .375rem
}
#tamkinPlayerApp .btn-group>.btn-group:not(:first-child), #tamkinPlayerApp .btn-group>:not(.btn-check:first-child)+.btn{
    margin-left: -1px
}
#tamkinPlayerApp .btn-group>.btn-group:not(:last-child)>.btn, #tamkinPlayerApp .btn-group>.btn.dropdown-toggle-split:first-child, #tamkinPlayerApp .btn-group>.btn:not(:last-child):not(.dropdown-toggle){
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}
#tamkinPlayerApp .btn-group>.btn-group:not(:first-child)>.btn, #tamkinPlayerApp .btn-group>.btn:nth-child(n+3), #tamkinPlayerApp .btn-group>:not(.btn-check)+.btn{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}
#tamkinPlayerApp .dropdown-toggle-split{
    padding-right: .5625rem;
    padding-left: .5625rem
}
#tamkinPlayerApp .dropdown-toggle-split::after, #tamkinPlayerApp .dropend .dropdown-toggle-split::after, #tamkinPlayerApp .dropup .dropdown-toggle-split::after{
    margin-left: 0
}
#tamkinPlayerApp .dropstart .dropdown-toggle-split::before{
    margin-right: 0
}
#tamkinPlayerApp .btn-group-sm>.btn+.dropdown-toggle-split, #tamkinPlayerApp .btn-sm+.dropdown-toggle-split{
    padding-right: .375rem;
    padding-left: .375rem
}
#tamkinPlayerApp .btn-group-lg>.btn+.dropdown-toggle-split, #tamkinPlayerApp .btn-lg+.dropdown-toggle-split{
    padding-right: .75rem;
    padding-left: .75rem
}
#tamkinPlayerApp .btn-group-vertical{
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}
#tamkinPlayerApp .btn-group-vertical>.btn, #tamkinPlayerApp .btn-group-vertical>.btn-group{
    width: 100%
}
#tamkinPlayerApp .btn-group-vertical>.btn-group:not(:first-child), #tamkinPlayerApp .btn-group-vertical>.btn:not(:first-child){
    margin-top: -1px
}
#tamkinPlayerApp .btn-group-vertical>.btn-group:not(:last-child)>.btn, #tamkinPlayerApp .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle){
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}
#tamkinPlayerApp .btn-group-vertical>.btn-group:not(:first-child)>.btn, #tamkinPlayerApp .btn-group-vertical>.btn~.btn{
    border-top-left-radius: 0;
    border-top-right-radius: 0
}
#tamkinPlayerApp .nav{
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}
#tamkinPlayerApp .nav-link{
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .nav-link {
        transition: none
    }
}
#tamkinPlayerApp .nav-link:focus, #tamkinPlayerApp .nav-link:hover{
    color: var(--bs-nav-link-hover-color)
}
#tamkinPlayerApp .nav-link.disabled{
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default
}
#tamkinPlayerApp .nav-tabs{
    --bs-nav-tabs-border-width: 1px;
    --bs-nav-tabs-border-color: #dee2e6;
    --bs-nav-tabs-border-radius: 0.375rem;
    --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
    --bs-nav-tabs-link-active-color: #495057;
    --bs-nav-tabs-link-active-bg: #fff;
    --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color)
}
#tamkinPlayerApp .nav-tabs .nav-link{
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: 0 0;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius)
}
#tamkinPlayerApp .nav-tabs .nav-link:focus, #tamkinPlayerApp .nav-tabs .nav-link:hover{
    isolation: isolate;
    border-color: var(--bs-nav-tabs-link-hover-border-color)
}
#tamkinPlayerApp .nav-tabs .nav-link.disabled, #tamkinPlayerApp .nav-tabs .nav-link:disabled{
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent
}
#tamkinPlayerApp .nav-tabs .nav-item.show .nav-link, #tamkinPlayerApp .nav-tabs .nav-link.active{
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color)
}
#tamkinPlayerApp .nav-tabs .dropdown-menu{
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0
}
#tamkinPlayerApp .nav-pills{
    --bs-nav-pills-border-radius: 0.375rem;
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #0d6efd
}
#tamkinPlayerApp .nav-pills .nav-link{
    background: 0 0;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius)
}
#tamkinPlayerApp .nav-pills .nav-link:disabled{
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent
}
#tamkinPlayerApp .nav-pills .nav-link.active, #tamkinPlayerApp .nav-pills .show>.nav-link{
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg)
}
#tamkinPlayerApp .nav-fill .nav-item, #tamkinPlayerApp .nav-fill>.nav-link{
    flex: 1 1 auto;
    text-align: center
}
#tamkinPlayerApp .nav-justified .nav-item, #tamkinPlayerApp .nav-justified>.nav-link{
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}
#tamkinPlayerApp .nav-fill .nav-item .nav-link, #tamkinPlayerApp .nav-justified .nav-item .nav-link{
    width: 100%
}
#tamkinPlayerApp .tab-content>.tab-pane{
    display: none
}
#tamkinPlayerApp .tab-content>.active{
    display: block
}
#tamkinPlayerApp .navbar{
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0.5rem;
    --bs-navbar-color: rgba(0, 0, 0, 0.55);
    --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
    --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
    --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-brand-padding-y: 0.3125rem;
    --bs-navbar-brand-margin-end: 1rem;
    --bs-navbar-brand-font-size: 1.25rem;
    --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-nav-link-padding-x: 0.5rem;
    --bs-navbar-toggler-padding-y: 0.25rem;
    --bs-navbar-toggler-padding-x: 0.75rem;
    --bs-navbar-toggler-font-size: 1.25rem;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
    --bs-navbar-toggler-border-radius: 0.375rem;
    --bs-navbar-toggler-focus-width: 0.25rem;
    --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x)
}
#tamkinPlayerApp .navbar>.container, #tamkinPlayerApp .navbar>.container-fluid, #tamkinPlayerApp .navbar>.container-lg, #tamkinPlayerApp .navbar>.container-md, #tamkinPlayerApp .navbar>.container-sm, #tamkinPlayerApp .navbar>.container-xl, #tamkinPlayerApp .navbar>.container-xxl{
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between
}
#tamkinPlayerApp .navbar-brand{
    padding-top: var(--bs-navbar-brand-padding-y);
    padding-bottom: var(--bs-navbar-brand-padding-y);
    margin-right: var(--bs-navbar-brand-margin-end);
    font-size: var(--bs-navbar-brand-font-size);
    color: var(--bs-navbar-brand-color);
    text-decoration: none;
    white-space: nowrap
}
#tamkinPlayerApp .navbar-brand:focus, #tamkinPlayerApp .navbar-brand:hover{
    color: var(--bs-navbar-brand-hover-color)
}
#tamkinPlayerApp .navbar-nav{
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-navbar-color);
    --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
    --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}
#tamkinPlayerApp .navbar-nav .nav-link.active, #tamkinPlayerApp .navbar-nav .show>.nav-link{
    color: var(--bs-navbar-active-color)
}
#tamkinPlayerApp .navbar-nav .dropdown-menu{
    position: static
}
#tamkinPlayerApp .navbar-text{
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: var(--bs-navbar-color)
}
#tamkinPlayerApp .navbar-text a, #tamkinPlayerApp .navbar-text a:focus, #tamkinPlayerApp .navbar-text a:hover{
    color: var(--bs-navbar-active-color)
}
#tamkinPlayerApp .navbar-collapse{
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}
#tamkinPlayerApp .navbar-toggler{
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: transparent;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition)
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .navbar-toggler {
        transition: none
    }
}
#tamkinPlayerApp .navbar-toggler:hover{
    text-decoration: none
}
#tamkinPlayerApp .navbar-toggler:focus{
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width)
}
#tamkinPlayerApp .navbar-toggler-icon{
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}
#tamkinPlayerApp .navbar-nav-scroll{
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

@media (min-width:576px) {
    #tamkinPlayerApp .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    #tamkinPlayerApp .navbar-expand-sm .navbar-nav{
        flex-direction: row
    }
    #tamkinPlayerApp .navbar-expand-sm .navbar-nav .dropdown-menu{
        position: absolute
    }
    #tamkinPlayerApp .navbar-expand-sm .navbar-nav .nav-link{
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }
    #tamkinPlayerApp .navbar-expand-sm .navbar-nav-scroll{
        overflow: visible
    }
    #tamkinPlayerApp .navbar-expand-sm .navbar-collapse{
        display: flex !important;
        flex-basis: auto
    }
    #tamkinPlayerApp .navbar-expand-sm .navbar-toggler{
        display: none
    }
    #tamkinPlayerApp .navbar-expand-sm .offcanvas{
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }
    #tamkinPlayerApp .navbar-expand-sm .offcanvas .offcanvas-header{
        display: none
    }
    #tamkinPlayerApp .navbar-expand-sm .offcanvas .offcanvas-body{
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:768px) {
    #tamkinPlayerApp .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    #tamkinPlayerApp .navbar-expand-md .navbar-nav{
        flex-direction: row
    }
    #tamkinPlayerApp .navbar-expand-md .navbar-nav .dropdown-menu{
        position: absolute
    }
    #tamkinPlayerApp .navbar-expand-md .navbar-nav .nav-link{
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }
    #tamkinPlayerApp .navbar-expand-md .navbar-nav-scroll{
        overflow: visible
    }
    #tamkinPlayerApp .navbar-expand-md .navbar-collapse{
        display: flex !important;
        flex-basis: auto
    }
    #tamkinPlayerApp .navbar-expand-md .navbar-toggler{
        display: none
    }
    #tamkinPlayerApp .navbar-expand-md .offcanvas{
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }
    #tamkinPlayerApp .navbar-expand-md .offcanvas .offcanvas-header{
        display: none
    }
    #tamkinPlayerApp .navbar-expand-md .offcanvas .offcanvas-body{
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:992px) {
    #tamkinPlayerApp .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    #tamkinPlayerApp .navbar-expand-lg .navbar-nav{
        flex-direction: row
    }
    #tamkinPlayerApp .navbar-expand-lg .navbar-nav .dropdown-menu{
        position: absolute
    }
    #tamkinPlayerApp .navbar-expand-lg .navbar-nav .nav-link{
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }
    #tamkinPlayerApp .navbar-expand-lg .navbar-nav-scroll{
        overflow: visible
    }
    #tamkinPlayerApp .navbar-expand-lg .navbar-collapse{
        display: flex !important;
        flex-basis: auto
    }
    #tamkinPlayerApp .navbar-expand-lg .navbar-toggler{
        display: none
    }
    #tamkinPlayerApp .navbar-expand-lg .offcanvas{
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }
    #tamkinPlayerApp .navbar-expand-lg .offcanvas .offcanvas-header{
        display: none
    }
    #tamkinPlayerApp .navbar-expand-lg .offcanvas .offcanvas-body{
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:1200px) {
    #tamkinPlayerApp .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    #tamkinPlayerApp .navbar-expand-xl .navbar-nav{
        flex-direction: row
    }
    #tamkinPlayerApp .navbar-expand-xl .navbar-nav .dropdown-menu{
        position: absolute
    }
    #tamkinPlayerApp .navbar-expand-xl .navbar-nav .nav-link{
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }
    #tamkinPlayerApp .navbar-expand-xl .navbar-nav-scroll{
        overflow: visible
    }
    #tamkinPlayerApp .navbar-expand-xl .navbar-collapse{
        display: flex !important;
        flex-basis: auto
    }
    #tamkinPlayerApp .navbar-expand-xl .navbar-toggler{
        display: none
    }
    #tamkinPlayerApp .navbar-expand-xl .offcanvas{
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }
    #tamkinPlayerApp .navbar-expand-xl .offcanvas .offcanvas-header{
        display: none
    }
    #tamkinPlayerApp .navbar-expand-xl .offcanvas .offcanvas-body{
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width:1400px) {
    #tamkinPlayerApp .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }
    #tamkinPlayerApp .navbar-expand-xxl .navbar-nav{
        flex-direction: row
    }
    #tamkinPlayerApp .navbar-expand-xxl .navbar-nav .dropdown-menu{
        position: absolute
    }
    #tamkinPlayerApp .navbar-expand-xxl .navbar-nav .nav-link{
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }
    #tamkinPlayerApp .navbar-expand-xxl .navbar-nav-scroll{
        overflow: visible
    }
    #tamkinPlayerApp .navbar-expand-xxl .navbar-collapse{
        display: flex !important;
        flex-basis: auto
    }
    #tamkinPlayerApp .navbar-expand-xxl .navbar-toggler{
        display: none
    }
    #tamkinPlayerApp .navbar-expand-xxl .offcanvas{
        position: static;
        z-index: auto;
        flex-grow: 1;
        width: auto !important;
        height: auto !important;
        visibility: visible !important;
        background-color: transparent !important;
        border: 0 !important;
        transform: none !important;
        transition: none
    }
    #tamkinPlayerApp .navbar-expand-xxl .offcanvas .offcanvas-header{
        display: none
    }
    #tamkinPlayerApp .navbar-expand-xxl .offcanvas .offcanvas-body{
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}
#tamkinPlayerApp .navbar-expand{
    flex-wrap: nowrap;
    justify-content: flex-start
}
#tamkinPlayerApp .navbar-expand .navbar-nav{
    flex-direction: row
}
#tamkinPlayerApp .navbar-expand .navbar-nav .dropdown-menu{
    position: absolute
}
#tamkinPlayerApp .navbar-expand .navbar-nav .nav-link{
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x)
}
#tamkinPlayerApp .navbar-expand .navbar-nav-scroll{
    overflow: visible
}
#tamkinPlayerApp .navbar-expand .navbar-collapse{
    display: flex !important;
    flex-basis: auto
}
#tamkinPlayerApp .navbar-expand .navbar-toggler{
    display: none
}
#tamkinPlayerApp .navbar-expand .offcanvas{
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none
}
#tamkinPlayerApp .navbar-expand .offcanvas .offcanvas-header{
    display: none
}
#tamkinPlayerApp .navbar-expand .offcanvas .offcanvas-body{
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
}
#tamkinPlayerApp .navbar-dark{
    --bs-navbar-color: rgba(255, 255, 255, 0.55);
    --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
    --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
    --bs-navbar-active-color: #fff;
    --bs-navbar-brand-color: #fff;
    --bs-navbar-brand-hover-color: #fff;
    --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}
#tamkinPlayerApp .card{
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: 0.375rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(0.375rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius)
}
#tamkinPlayerApp .card>hr{
    margin-right: 0;
    margin-left: 0
}
#tamkinPlayerApp .card>.list-group{
    border-top: inherit;
    border-bottom: inherit
}
#tamkinPlayerApp .card>.list-group:first-child{
    border-top-width: 0;
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}
#tamkinPlayerApp .card>.list-group:last-child{
    border-bottom-width: 0;
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius)
}
#tamkinPlayerApp .card>.card-header+.list-group, #tamkinPlayerApp .card>.list-group+.card-footer{
    border-top: 0
}
#tamkinPlayerApp .card-body{
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color)
}
#tamkinPlayerApp .card-title{
    margin-bottom: var(--bs-card-title-spacer-y)
}
#tamkinPlayerApp .card-subtitle{
    margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
    margin-bottom: 0
}
#tamkinPlayerApp .card-text:last-child{
    margin-bottom: 0
}
#tamkinPlayerApp .card-link+.card-link{
    margin-left: var(--bs-card-spacer-x)
}
#tamkinPlayerApp .card-header{
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    margin-bottom: 0;
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg);
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color)
}
#tamkinPlayerApp .card-header:first-child{
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0
}
#tamkinPlayerApp .card-footer{
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg);
    border-top: var(--bs-card-border-width) solid var(--bs-card-border-color)
}
#tamkinPlayerApp .card-footer:last-child{
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)
}
#tamkinPlayerApp .card-header-tabs{
    margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
    margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
    margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
    border-bottom: 0
}
#tamkinPlayerApp .card-header-tabs .nav-link.active{
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg)
}
#tamkinPlayerApp .card-header-pills{
    margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
    margin-left: calc(-.5 * var(--bs-card-cap-padding-x))
}
#tamkinPlayerApp .card-img-overlay{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--bs-card-img-overlay-padding);
    border-radius: var(--bs-card-inner-border-radius)
}
#tamkinPlayerApp .card-img, #tamkinPlayerApp .card-img-bottom, #tamkinPlayerApp .card-img-top{
    width: 100%
}
#tamkinPlayerApp .card-img, #tamkinPlayerApp .card-img-top{
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}
#tamkinPlayerApp .card-img, #tamkinPlayerApp .card-img-bottom{
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius)
}
#tamkinPlayerApp .card-group>.card{
    margin-bottom: var(--bs-card-group-margin)
}

@media (min-width:576px) {
    #tamkinPlayerApp .card-group {
        display: flex;
        flex-flow: row wrap
    }
    #tamkinPlayerApp .card-group>.card{
        flex: 1 0 0%;
        margin-bottom: 0
    }
    #tamkinPlayerApp .card-group>.card+.card{
        margin-left: 0;
        border-left: 0
    }
    #tamkinPlayerApp .card-group>.card:not(:last-child){
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }
    #tamkinPlayerApp .card-group>.card:not(:last-child) .card-header, #tamkinPlayerApp .card-group>.card:not(:last-child) .card-img-top{
        border-top-right-radius: 0
    }
    #tamkinPlayerApp .card-group>.card:not(:last-child) .card-footer, #tamkinPlayerApp .card-group>.card:not(:last-child) .card-img-bottom{
        border-bottom-right-radius: 0
    }
    #tamkinPlayerApp .card-group>.card:not(:first-child){
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }
    #tamkinPlayerApp .card-group>.card:not(:first-child) .card-header, #tamkinPlayerApp .card-group>.card:not(:first-child) .card-img-top{
        border-top-left-radius: 0
    }
    #tamkinPlayerApp .card-group>.card:not(:first-child) .card-footer, #tamkinPlayerApp .card-group>.card:not(:first-child) .card-img-bottom{
        border-bottom-left-radius: 0
    }
}
#tamkinPlayerApp .accordion{
    --bs-accordion-color: #212529;
    --bs-accordion-bg: #fff;
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: 1px;
    --bs-accordion-border-radius: 0.375rem;
    --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: #212529;
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: #86b7fe;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #0c63e4;
    --bs-accordion-active-bg: #e7f1ff
}
#tamkinPlayerApp .accordion-button{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition)
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .accordion-button {
        transition: none
    }
}
#tamkinPlayerApp .accordion-button:not(.collapsed){
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color)
}
#tamkinPlayerApp .accordion-button:not(.collapsed)::after{
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform)
}
#tamkinPlayerApp .accordion-button::after{
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition)
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .accordion-button::after {
        transition: none
    }
}
#tamkinPlayerApp .accordion-button:hover{
    z-index: 2
}
#tamkinPlayerApp .accordion-button:focus{
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow)
}
#tamkinPlayerApp .accordion-header{
    margin-bottom: 0
}
#tamkinPlayerApp .accordion-item{
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color)
}
#tamkinPlayerApp .accordion-item:first-of-type{
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius)
}
#tamkinPlayerApp .accordion-item:first-of-type .accordion-button{
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius)
}
#tamkinPlayerApp .accordion-item:not(:first-of-type){
    border-top: 0
}
#tamkinPlayerApp .accordion-item:last-of-type{
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius)
}
#tamkinPlayerApp .accordion-item:last-of-type .accordion-button.collapsed{
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius)
}
#tamkinPlayerApp .accordion-item:last-of-type .accordion-collapse{
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius)
}
#tamkinPlayerApp .accordion-body{
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x)
}
#tamkinPlayerApp .accordion-flush .accordion-collapse{
    border-width: 0
}
#tamkinPlayerApp .accordion-flush .accordion-item{
    border-right: 0;
    border-left: 0;
    border-radius: 0
}
#tamkinPlayerApp .accordion-flush .accordion-item:first-child{
    border-top: 0
}
#tamkinPlayerApp .accordion-flush .accordion-item:last-child{
    border-bottom: 0
}
#tamkinPlayerApp .accordion-flush .accordion-item .accordion-button, #tamkinPlayerApp .accordion-flush .accordion-item .accordion-button.collapsed{
    border-radius: 0
}
#tamkinPlayerApp .breadcrumb{
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 0;
    --bs-breadcrumb-margin-bottom: 1rem;
    --bs-breadcrumb-bg: ;
    --bs-breadcrumb-border-radius: ;
    --bs-breadcrumb-divider-color: #6c757d;
    --bs-breadcrumb-item-padding-x: 0.5rem;
    --bs-breadcrumb-item-active-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
    margin-bottom: var(--bs-breadcrumb-margin-bottom);
    font-size: var(--bs-breadcrumb-font-size);
    list-style: none;
    background-color: var(--bs-breadcrumb-bg);
    border-radius: var(--bs-breadcrumb-border-radius)
}
#tamkinPlayerApp .breadcrumb-item+.breadcrumb-item{
    padding-left: var(--bs-breadcrumb-item-padding-x)
}
#tamkinPlayerApp .breadcrumb-item+.breadcrumb-item::before{
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "https://oclata.com/")
}
#tamkinPlayerApp .breadcrumb-item.active{
    color: var(--bs-breadcrumb-item-active-color)
}
#tamkinPlayerApp .pagination{
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: #fff;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #dee2e6;
    --bs-pagination-border-radius: 0.375rem;
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: #e9ecef;
    --bs-pagination-hover-border-color: #dee2e6;
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: #e9ecef;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #0d6efd;
    --bs-pagination-active-border-color: #0d6efd;
    --bs-pagination-disabled-color: #6c757d;
    --bs-pagination-disabled-bg: #fff;
    --bs-pagination-disabled-border-color: #dee2e6;
    display: flex;
    padding-left: 0;
    list-style: none
}
#tamkinPlayerApp .page-link{
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: var(--bs-pagination-color);
    text-decoration: none;
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .page-link {
        transition: none
    }
}
#tamkinPlayerApp .page-link:hover{
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color)
}
#tamkinPlayerApp .page-link:focus{
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow)
}
#tamkinPlayerApp .active>.page-link, #tamkinPlayerApp .page-link.active{
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color)
}
#tamkinPlayerApp .disabled>.page-link, #tamkinPlayerApp .page-link.disabled{
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color)
}
#tamkinPlayerApp .page-item:not(:first-child) .page-link{
    margin-left: -1px
}
#tamkinPlayerApp .page-item:first-child .page-link{
    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius)
}
#tamkinPlayerApp .page-item:last-child .page-link{
    border-top-right-radius: var(--bs-pagination-border-radius);
    border-bottom-right-radius: var(--bs-pagination-border-radius)
}
#tamkinPlayerApp .pagination-lg{
    --bs-pagination-padding-x: 1.5rem;
    --bs-pagination-padding-y: 0.75rem;
    --bs-pagination-font-size: 1.25rem;
    --bs-pagination-border-radius: 0.5rem
}
#tamkinPlayerApp .pagination-sm{
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
    --bs-pagination-font-size: 0.875rem;
    --bs-pagination-border-radius: 0.25rem
}
#tamkinPlayerApp .badge{
    --bs-badge-padding-x: 0.65em;
    --bs-badge-padding-y: 0.35em;
    --bs-badge-font-size: 0.75em;
    --bs-badge-font-weight: 700;
    --bs-badge-color: #fff;
    --bs-badge-border-radius: 0.375rem;
    display: inline-block;
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 1;
    color: var(--bs-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--bs-badge-border-radius)
}
#tamkinPlayerApp .badge:empty{
    display: none
}
#tamkinPlayerApp .btn .badge{
    position: relative;
    top: -1px
}
#tamkinPlayerApp .alert{
    --bs-alert-bg: transparent;
    --bs-alert-padding-x: 1rem;
    --bs-alert-padding-y: 1rem;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-color: inherit;
    --bs-alert-border-color: transparent;
    --bs-alert-border: 1px solid var(--bs-alert-border-color);
    --bs-alert-border-radius: 0.375rem;
    position: relative;
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    margin-bottom: var(--bs-alert-margin-bottom);
    color: var(--bs-alert-color);
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius)
}
#tamkinPlayerApp .alert-heading{
    color: inherit
}
#tamkinPlayerApp .alert-link{
    font-weight: 700
}
#tamkinPlayerApp .alert-dismissible{
    padding-right: 3rem
}
#tamkinPlayerApp .alert-dismissible .btn-close{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}
#tamkinPlayerApp .alert-primary{
    --bs-alert-color: #084298;
    --bs-alert-bg: #cfe2ff;
    --bs-alert-border-color: #b6d4fe
}
#tamkinPlayerApp .alert-primary .alert-link{
    color: #06357a
}
#tamkinPlayerApp .alert-secondary{
    --bs-alert-color: #41464b;
    --bs-alert-bg: #e2e3e5;
    --bs-alert-border-color: #d3d6d8
}
#tamkinPlayerApp .alert-secondary .alert-link{
    color: #34383c
}
#tamkinPlayerApp .alert-success{
    --bs-alert-color: #0f5132;
    --bs-alert-bg: #d1e7dd;
    --bs-alert-border-color: #badbcc
}
#tamkinPlayerApp .alert-success .alert-link{
    color: #0c4128
}
#tamkinPlayerApp .alert-info{
    --bs-alert-color: #055160;
    --bs-alert-bg: #cff4fc;
    --bs-alert-border-color: #b6effb
}
#tamkinPlayerApp .alert-info .alert-link{
    color: #04414d
}
#tamkinPlayerApp .alert-warning{
    --bs-alert-color: #664d03;
    --bs-alert-bg: #fff3cd;
    --bs-alert-border-color: #ffecb5
}
#tamkinPlayerApp .alert-warning .alert-link{
    color: #523e02
}
#tamkinPlayerApp .alert-danger{
    --bs-alert-color: #842029;
    --bs-alert-bg: #f8d7da;
    --bs-alert-border-color: #f5c2c7
}
#tamkinPlayerApp .alert-danger .alert-link{
    color: #6a1a21
}
#tamkinPlayerApp .alert-light{
    --bs-alert-color: #636464;
    --bs-alert-bg: #fefefe;
    --bs-alert-border-color: #fdfdfe
}
#tamkinPlayerApp .alert-light .alert-link{
    color: #4f5050
}
#tamkinPlayerApp .alert-dark{
    --bs-alert-color: #141619;
    --bs-alert-bg: #d3d3d4;
    --bs-alert-border-color: #bcbebf
}
#tamkinPlayerApp .alert-dark .alert-link{
    color: #101214
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}
#tamkinPlayerApp .progress{
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius)
}
#tamkinPlayerApp .progress-bar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition)
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .progress-bar {
        transition: none
    }
}
#tamkinPlayerApp .progress-bar-striped{
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: var(--bs-progress-height) var(--bs-progress-height)
}
#tamkinPlayerApp .progress-bar-animated{
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .progress-bar-animated {
        animation: none
    }
}
#tamkinPlayerApp .list-group{
    --bs-list-group-color: #212529;
    --bs-list-group-bg: #fff;
    --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
    --bs-list-group-border-width: 1px;
    --bs-list-group-border-radius: 0.375rem;
    --bs-list-group-item-padding-x: 1rem;
    --bs-list-group-item-padding-y: 0.5rem;
    --bs-list-group-action-color: #495057;
    --bs-list-group-action-hover-color: #495057;
    --bs-list-group-action-hover-bg: #f8f9fa;
    --bs-list-group-action-active-color: #212529;
    --bs-list-group-action-active-bg: #e9ecef;
    --bs-list-group-disabled-color: #6c757d;
    --bs-list-group-disabled-bg: #fff;
    --bs-list-group-active-color: #fff;
    --bs-list-group-active-bg: #0d6efd;
    --bs-list-group-active-border-color: #0d6efd;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--bs-list-group-border-radius)
}
#tamkinPlayerApp .list-group-numbered{
    list-style-type: none;
    counter-reset: section
}
#tamkinPlayerApp .list-group-numbered>.list-group-item::before{
    content: counters(section, ".") ". ";
    counter-increment: section
}
#tamkinPlayerApp .list-group-item-action{
    width: 100%;
    color: var(--bs-list-group-action-color);
    text-align: inherit
}
#tamkinPlayerApp .list-group-item-action:focus, #tamkinPlayerApp .list-group-item-action:hover{
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg)
}
#tamkinPlayerApp .list-group-item-action:active{
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg)
}
#tamkinPlayerApp .list-group-item{
    position: relative;
    display: block;
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color)
}
#tamkinPlayerApp .list-group-item:first-child{
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}
#tamkinPlayerApp .list-group-item:last-child{
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}
#tamkinPlayerApp .list-group-item.disabled, #tamkinPlayerApp .list-group-item:disabled{
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg)
}
#tamkinPlayerApp .list-group-item.active{
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color)
}
#tamkinPlayerApp .list-group-item+.list-group-item{
    border-top-width: 0
}
#tamkinPlayerApp .list-group-item+.list-group-item.active{
    margin-top: calc(-1 * var(--bs-list-group-border-width));
    border-top-width: var(--bs-list-group-border-width)
}
#tamkinPlayerApp .list-group-horizontal{
    flex-direction: row
}
#tamkinPlayerApp .list-group-horizontal>.list-group-item:first-child:not(:last-child){
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0
}
#tamkinPlayerApp .list-group-horizontal>.list-group-item:last-child:not(:first-child){
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0
}
#tamkinPlayerApp .list-group-horizontal>.list-group-item.active{
    margin-top: 0
}
#tamkinPlayerApp .list-group-horizontal>.list-group-item+.list-group-item{
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0
}
#tamkinPlayerApp .list-group-horizontal>.list-group-item+.list-group-item.active{
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width)
}

@media (min-width:576px) {
    #tamkinPlayerApp .list-group-horizontal-sm {
        flex-direction: row
    }
    #tamkinPlayerApp .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child){
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }
    #tamkinPlayerApp .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child){
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }
    #tamkinPlayerApp .list-group-horizontal-sm>.list-group-item.active{
        margin-top: 0
    }
    #tamkinPlayerApp .list-group-horizontal-sm>.list-group-item+.list-group-item{
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }
    #tamkinPlayerApp .list-group-horizontal-sm>.list-group-item+.list-group-item.active{
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width:768px) {
    #tamkinPlayerApp .list-group-horizontal-md {
        flex-direction: row
    }
    #tamkinPlayerApp .list-group-horizontal-md>.list-group-item:first-child:not(:last-child){
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }
    #tamkinPlayerApp .list-group-horizontal-md>.list-group-item:last-child:not(:first-child){
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }
    #tamkinPlayerApp .list-group-horizontal-md>.list-group-item.active{
        margin-top: 0
    }
    #tamkinPlayerApp .list-group-horizontal-md>.list-group-item+.list-group-item{
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }
    #tamkinPlayerApp .list-group-horizontal-md>.list-group-item+.list-group-item.active{
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width:992px) {
    #tamkinPlayerApp .list-group-horizontal-lg {
        flex-direction: row
    }
    #tamkinPlayerApp .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child){
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }
    #tamkinPlayerApp .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child){
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }
    #tamkinPlayerApp .list-group-horizontal-lg>.list-group-item.active{
        margin-top: 0
    }
    #tamkinPlayerApp .list-group-horizontal-lg>.list-group-item+.list-group-item{
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }
    #tamkinPlayerApp .list-group-horizontal-lg>.list-group-item+.list-group-item.active{
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width:1200px) {
    #tamkinPlayerApp .list-group-horizontal-xl {
        flex-direction: row
    }
    #tamkinPlayerApp .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child){
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }
    #tamkinPlayerApp .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child){
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }
    #tamkinPlayerApp .list-group-horizontal-xl>.list-group-item.active{
        margin-top: 0
    }
    #tamkinPlayerApp .list-group-horizontal-xl>.list-group-item+.list-group-item{
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }
    #tamkinPlayerApp .list-group-horizontal-xl>.list-group-item+.list-group-item.active{
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width:1400px) {
    #tamkinPlayerApp .list-group-horizontal-xxl {
        flex-direction: row
    }
    #tamkinPlayerApp .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child){
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }
    #tamkinPlayerApp .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child){
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }
    #tamkinPlayerApp .list-group-horizontal-xxl>.list-group-item.active{
        margin-top: 0
    }
    #tamkinPlayerApp .list-group-horizontal-xxl>.list-group-item+.list-group-item{
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }
    #tamkinPlayerApp .list-group-horizontal-xxl>.list-group-item+.list-group-item.active{
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}
#tamkinPlayerApp .list-group-flush{
    border-radius: 0
}
#tamkinPlayerApp .list-group-flush>.list-group-item{
    border-width: 0 0 var(--bs-list-group-border-width)
}
#tamkinPlayerApp .list-group-flush>.list-group-item:last-child{
    border-bottom-width: 0
}
#tamkinPlayerApp .list-group-item-primary{
    color: #084298;
    background-color: #cfe2ff
}
#tamkinPlayerApp .list-group-item-primary.list-group-item-action:focus, #tamkinPlayerApp .list-group-item-primary.list-group-item-action:hover{
    color: #084298;
    background-color: #bacbe6
}
#tamkinPlayerApp .list-group-item-primary.list-group-item-action.active{
    color: #fff;
    background-color: #084298;
    border-color: #084298
}
#tamkinPlayerApp .list-group-item-secondary{
    color: #41464b;
    background-color: #e2e3e5
}
#tamkinPlayerApp .list-group-item-secondary.list-group-item-action:focus, #tamkinPlayerApp .list-group-item-secondary.list-group-item-action:hover{
    color: #41464b;
    background-color: #cbccce
}
#tamkinPlayerApp .list-group-item-secondary.list-group-item-action.active{
    color: #fff;
    background-color: #41464b;
    border-color: #41464b
}
#tamkinPlayerApp .list-group-item-success{
    color: #0f5132;
    background-color: #d1e7dd
}
#tamkinPlayerApp .list-group-item-success.list-group-item-action:focus, #tamkinPlayerApp .list-group-item-success.list-group-item-action:hover{
    color: #0f5132;
    background-color: #bcd0c7
}
#tamkinPlayerApp .list-group-item-success.list-group-item-action.active{
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132
}
#tamkinPlayerApp .list-group-item-info{
    color: #055160;
    background-color: #cff4fc
}
#tamkinPlayerApp .list-group-item-info.list-group-item-action:focus, #tamkinPlayerApp .list-group-item-info.list-group-item-action:hover{
    color: #055160;
    background-color: #badce3
}
#tamkinPlayerApp .list-group-item-info.list-group-item-action.active{
    color: #fff;
    background-color: #055160;
    border-color: #055160
}
#tamkinPlayerApp .list-group-item-warning{
    color: #664d03;
    background-color: #fff3cd
}
#tamkinPlayerApp .list-group-item-warning.list-group-item-action:focus, #tamkinPlayerApp .list-group-item-warning.list-group-item-action:hover{
    color: #664d03;
    background-color: #e6dbb9
}
#tamkinPlayerApp .list-group-item-warning.list-group-item-action.active{
    color: #fff;
    background-color: #664d03;
    border-color: #664d03
}
#tamkinPlayerApp .list-group-item-danger{
    color: #842029;
    background-color: #f8d7da
}
#tamkinPlayerApp .list-group-item-danger.list-group-item-action:focus, #tamkinPlayerApp .list-group-item-danger.list-group-item-action:hover{
    color: #842029;
    background-color: #dfc2c4
}
#tamkinPlayerApp .list-group-item-danger.list-group-item-action.active{
    color: #fff;
    background-color: #842029;
    border-color: #842029
}
#tamkinPlayerApp .list-group-item-light{
    color: #636464;
    background-color: #fefefe
}
#tamkinPlayerApp .list-group-item-light.list-group-item-action:focus, #tamkinPlayerApp .list-group-item-light.list-group-item-action:hover{
    color: #636464;
    background-color: #e5e5e5
}
#tamkinPlayerApp .list-group-item-light.list-group-item-action.active{
    color: #fff;
    background-color: #636464;
    border-color: #636464
}
#tamkinPlayerApp .list-group-item-dark{
    color: #141619;
    background-color: #d3d3d4
}
#tamkinPlayerApp .list-group-item-dark.list-group-item-action:focus, #tamkinPlayerApp .list-group-item-dark.list-group-item-action:hover{
    color: #141619;
    background-color: #bebebf
}
#tamkinPlayerApp .list-group-item-dark.list-group-item-action.active{
    color: #fff;
    background-color: #141619;
    border-color: #141619
}
#tamkinPlayerApp .btn-close{
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: .5
}
#tamkinPlayerApp .btn-close:hover{
    color: #000;
    text-decoration: none;
    opacity: .75
}
#tamkinPlayerApp .btn-close:focus{
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    opacity: 1
}
#tamkinPlayerApp .btn-close.disabled, #tamkinPlayerApp .btn-close:disabled{
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: .25
}
#tamkinPlayerApp .btn-close-white{
    filter: invert(1) grayscale(100%) brightness(200%)
}
#tamkinPlayerApp .toast{
    --bs-toast-zindex: 1090;
    --bs-toast-padding-x: 0.75rem;
    --bs-toast-padding-y: 0.5rem;
    --bs-toast-spacing: 1.5rem;
    --bs-toast-max-width: 350px;
    --bs-toast-font-size: 0.875rem;
    --bs-toast-color: ;
    --bs-toast-bg: rgba(255, 255, 255, 0.85);
    --bs-toast-border-width: 1px;
    --bs-toast-border-color: var(--bs-border-color-translucent);
    --bs-toast-border-radius: 0.375rem;
    --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-toast-header-color: #6c757d;
    --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
    --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
    width: var(--bs-toast-max-width);
    max-width: 100%;
    font-size: var(--bs-toast-font-size);
    color: var(--bs-toast-color);
    pointer-events: auto;
    background-color: var(--bs-toast-bg);
    background-clip: padding-box;
    border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
    box-shadow: var(--bs-toast-box-shadow);
    border-radius: var(--bs-toast-border-radius)
}
#tamkinPlayerApp .toast.showing{
    opacity: 0
}
#tamkinPlayerApp .toast:not(.show){
    display: none
}
#tamkinPlayerApp .toast-container{
    --bs-toast-zindex: 1090;
    position: absolute;
    z-index: var(--bs-toast-zindex);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none
}
#tamkinPlayerApp .toast-container>:not(:last-child){
    margin-bottom: var(--bs-toast-spacing)
}
#tamkinPlayerApp .toast-header{
    display: flex;
    align-items: center;
    padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
    color: var(--bs-toast-header-color);
    background-color: var(--bs-toast-header-bg);
    background-clip: padding-box;
    border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
    border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
    border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width))
}
#tamkinPlayerApp .toast-header .btn-close{
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x)
}
#tamkinPlayerApp .toast-body{
    padding: var(--bs-toast-padding-x);
    word-wrap: break-word
}
#tamkinPlayerApp .modal{
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-color: ;
    --bs-modal-bg: #fff;
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: 1px;
    --bs-modal-border-radius: 0.5rem;
    --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-modal-inner-border-radius: calc(0.5rem - 1px);
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem 1rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: 1px;
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: 1px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}
#tamkinPlayerApp .modal-dialog{
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none
}
#tamkinPlayerApp .modal.fade .modal-dialog{
    transition: transform .3s ease-out;
    transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .modal.fade .modal-dialog {
        transition: none
    }
}
#tamkinPlayerApp .modal.show .modal-dialog{
    transform: none
}
#tamkinPlayerApp .modal.modal-static .modal-dialog{
    transform: scale(1.02)
}
#tamkinPlayerApp .modal-dialog-scrollable{
    height: calc(100% - var(--bs-modal-margin) * 2)
}
#tamkinPlayerApp .modal-dialog-scrollable .modal-content{
    max-height: 100%;
    overflow: hidden
}
#tamkinPlayerApp .modal-dialog-scrollable .modal-body{
    overflow-y: auto
}
#tamkinPlayerApp .modal-dialog-centered{
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2)
}
#tamkinPlayerApp .modal-content{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0
}
.modal-backdrop{
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg)
}
.modal-backdrop.fade{
    opacity: 0
}
.modal-backdrop.show{
    opacity: var(--bs-backdrop-opacity)
}
#tamkinPlayerApp .modal-header{
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius)
}
#tamkinPlayerApp .modal-header .btn-close{
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto
}
#tamkinPlayerApp .modal-title{
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height)
}
#tamkinPlayerApp .modal-body{
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding)
}
#tamkinPlayerApp .modal-footer{
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius)
}
#tamkinPlayerApp .modal-footer>*{
    margin: calc(var(--bs-modal-footer-gap) * .5)
}

@media (min-width:576px) {
    #tamkinPlayerApp .modal {
        --bs-modal-margin: 1.75rem;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15)
    }
    #tamkinPlayerApp .modal-dialog{
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto
    }
    #tamkinPlayerApp .modal-sm{
        --bs-modal-width: 300px
    }
}

@media (min-width:992px) {

    #tamkinPlayerApp .modal-lg,
    #tamkinPlayerApp .modal-xl {
        --bs-modal-width: 800px
    }
}

@media (min-width:1200px) {
    #tamkinPlayerApp .modal-xl {
        --bs-modal-width: 1140px
    }
}
#tamkinPlayerApp .modal-fullscreen{
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}
#tamkinPlayerApp .modal-fullscreen .modal-content{
    height: 100%;
    border: 0;
    border-radius: 0
}
#tamkinPlayerApp .modal-fullscreen .modal-footer, #tamkinPlayerApp .modal-fullscreen .modal-header{
    border-radius: 0
}
#tamkinPlayerApp .modal-fullscreen .modal-body{
    overflow-y: auto
}

@media (max-width:575.98px) {
    #tamkinPlayerApp .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }
    #tamkinPlayerApp .modal-fullscreen-sm-down .modal-content{
        height: 100%;
        border: 0;
        border-radius: 0
    }
    #tamkinPlayerApp .modal-fullscreen-sm-down .modal-footer, #tamkinPlayerApp .modal-fullscreen-sm-down .modal-header{
        border-radius: 0
    }
    #tamkinPlayerApp .modal-fullscreen-sm-down .modal-body{
        overflow-y: auto
    }
}

@media (max-width:767.98px) {
    #tamkinPlayerApp .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }
    #tamkinPlayerApp .modal-fullscreen-md-down .modal-content{
        height: 100%;
        border: 0;
        border-radius: 0
    }
    #tamkinPlayerApp .modal-fullscreen-md-down .modal-footer, #tamkinPlayerApp .modal-fullscreen-md-down .modal-header{
        border-radius: 0
    }
    #tamkinPlayerApp .modal-fullscreen-md-down .modal-body{
        overflow-y: auto
    }
}

@media (max-width:991.98px) {
    #tamkinPlayerApp .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }
    #tamkinPlayerApp .modal-fullscreen-lg-down .modal-content{
        height: 100%;
        border: 0;
        border-radius: 0
    }
    #tamkinPlayerApp .modal-fullscreen-lg-down .modal-footer, #tamkinPlayerApp .modal-fullscreen-lg-down .modal-header{
        border-radius: 0
    }
    #tamkinPlayerApp .modal-fullscreen-lg-down .modal-body{
        overflow-y: auto
    }
}

@media (max-width:1199.98px) {
    #tamkinPlayerApp .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }
    #tamkinPlayerApp .modal-fullscreen-xl-down .modal-content{
        height: 100%;
        border: 0;
        border-radius: 0
    }
    #tamkinPlayerApp .modal-fullscreen-xl-down .modal-footer, #tamkinPlayerApp .modal-fullscreen-xl-down .modal-header{
        border-radius: 0
    }
    #tamkinPlayerApp .modal-fullscreen-xl-down .modal-body{
        overflow-y: auto
    }
}

@media (max-width:1399.98px) {
    #tamkinPlayerApp .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }
    #tamkinPlayerApp .modal-fullscreen-xxl-down .modal-content{
        height: 100%;
        border: 0;
        border-radius: 0
    }
    #tamkinPlayerApp .modal-fullscreen-xxl-down .modal-footer, #tamkinPlayerApp .modal-fullscreen-xxl-down .modal-header{
        border-radius: 0
    }
    #tamkinPlayerApp .modal-fullscreen-xxl-down .modal-body{
        overflow-y: auto
    }
}
#tamkinPlayerApp .tooltip{
    --bs-tooltip-zindex: 1080;
    --bs-tooltip-max-width: 200px;
    --bs-tooltip-padding-x: 0.5rem;
    --bs-tooltip-padding-y: 0.25rem;
    --bs-tooltip-margin: ;
    --bs-tooltip-font-size: 0.875rem;
    --bs-tooltip-color: #fff;
    --bs-tooltip-bg: #000;
    --bs-tooltip-border-radius: 0.375rem;
    --bs-tooltip-opacity: 0.9;
    --bs-tooltip-arrow-width: 0.8rem;
    --bs-tooltip-arrow-height: 0.4rem;
    z-index: var(--bs-tooltip-zindex);
    display: block;
    padding: var(--bs-tooltip-arrow-height);
    margin: var(--bs-tooltip-margin);
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--bs-tooltip-font-size);
    word-wrap: break-word;
    opacity: 0
}
#tamkinPlayerApp .tooltip.show{
    opacity: var(--bs-tooltip-opacity)
}
#tamkinPlayerApp .tooltip .tooltip-arrow{
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height)
}
#tamkinPlayerApp .tooltip .tooltip-arrow::before{
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}
#tamkinPlayerApp .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow, #tamkinPlayerApp .bs-tooltip-top .tooltip-arrow{
    bottom: 0
}
#tamkinPlayerApp .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, #tamkinPlayerApp .bs-tooltip-top .tooltip-arrow::before{
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg)
}
#tamkinPlayerApp .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow, #tamkinPlayerApp .bs-tooltip-end .tooltip-arrow{
    left: 0;
    width: var(--bs-tooltip-arrow-height);
    height: var(--bs-tooltip-arrow-width)
}
#tamkinPlayerApp .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, #tamkinPlayerApp .bs-tooltip-end .tooltip-arrow::before{
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg)
}
#tamkinPlayerApp .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow, #tamkinPlayerApp .bs-tooltip-bottom .tooltip-arrow{
    top: 0
}
#tamkinPlayerApp .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, #tamkinPlayerApp .bs-tooltip-bottom .tooltip-arrow::before{
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg)
}
#tamkinPlayerApp .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, #tamkinPlayerApp .bs-tooltip-start .tooltip-arrow{
    right: 0;
    width: var(--bs-tooltip-arrow-height);
    height: var(--bs-tooltip-arrow-width)
}
#tamkinPlayerApp .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, #tamkinPlayerApp .bs-tooltip-start .tooltip-arrow::before{
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg)
}
#tamkinPlayerApp .tooltip-inner{
    max-width: var(--bs-tooltip-max-width);
    padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
    color: var(--bs-tooltip-color);
    text-align: center;
    background-color: var(--bs-tooltip-bg);
    border-radius: var(--bs-tooltip-border-radius)
}
#tamkinPlayerApp .popover{
    --bs-popover-zindex: 1070;
    --bs-popover-max-width: 276px;
    --bs-popover-font-size: 0.875rem;
    --bs-popover-bg: #fff;
    --bs-popover-border-width: 1px;
    --bs-popover-border-color: var(--bs-border-color-translucent);
    --bs-popover-border-radius: 0.5rem;
    --bs-popover-inner-border-radius: calc(0.5rem - 1px);
    --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-popover-header-padding-x: 1rem;
    --bs-popover-header-padding-y: 0.5rem;
    --bs-popover-header-font-size: 1rem;
    --bs-popover-header-color: ;
    --bs-popover-header-bg: #f0f0f0;
    --bs-popover-body-padding-x: 1rem;
    --bs-popover-body-padding-y: 1rem;
    --bs-popover-body-color: #212529;
    --bs-popover-arrow-width: 1rem;
    --bs-popover-arrow-height: 0.5rem;
    --bs-popover-arrow-border: var(--bs-popover-border-color);
    z-index: var(--bs-popover-zindex);
    display: block;
    max-width: var(--bs-popover-max-width);
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--bs-popover-font-size);
    word-wrap: break-word;
    background-color: var(--bs-popover-bg);
    background-clip: padding-box;
    border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    border-radius: var(--bs-popover-border-radius)
}
#tamkinPlayerApp .popover .popover-arrow{
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height)
}
#tamkinPlayerApp .popover .popover-arrow::after, #tamkinPlayerApp .popover .popover-arrow::before{
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 0
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=top]>.popover-arrow, #tamkinPlayerApp .bs-popover-top>.popover-arrow{
    bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width))
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after, #tamkinPlayerApp .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before, #tamkinPlayerApp .bs-popover-top>.popover-arrow::after, #tamkinPlayerApp .bs-popover-top>.popover-arrow::before{
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before, #tamkinPlayerApp .bs-popover-top>.popover-arrow::before{
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after, #tamkinPlayerApp .bs-popover-top>.popover-arrow::after{
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=right]>.popover-arrow, #tamkinPlayerApp .bs-popover-end>.popover-arrow{
    left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
    width: var(--bs-popover-arrow-height);
    height: var(--bs-popover-arrow-width)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after, #tamkinPlayerApp .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before, #tamkinPlayerApp .bs-popover-end>.popover-arrow::after, #tamkinPlayerApp .bs-popover-end>.popover-arrow::before{
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before, #tamkinPlayerApp .bs-popover-end>.popover-arrow::before{
    left: 0;
    border-right-color: var(--bs-popover-arrow-border)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after, #tamkinPlayerApp .bs-popover-end>.popover-arrow::after{
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow, #tamkinPlayerApp .bs-popover-bottom>.popover-arrow{
    top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width))
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after, #tamkinPlayerApp .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before, #tamkinPlayerApp .bs-popover-bottom>.popover-arrow::after, #tamkinPlayerApp .bs-popover-bottom>.popover-arrow::before{
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before, #tamkinPlayerApp .bs-popover-bottom>.popover-arrow::before{
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after, #tamkinPlayerApp .bs-popover-bottom>.popover-arrow::after{
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, #tamkinPlayerApp .bs-popover-bottom .popover-header::before{
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: var(--bs-popover-arrow-width);
    margin-left: calc(-.5 * var(--bs-popover-arrow-width));
    content: "";
    border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=left]>.popover-arrow, #tamkinPlayerApp .bs-popover-start>.popover-arrow{
    right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
    width: var(--bs-popover-arrow-height);
    height: var(--bs-popover-arrow-width)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after, #tamkinPlayerApp .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before, #tamkinPlayerApp .bs-popover-start>.popover-arrow::after, #tamkinPlayerApp .bs-popover-start>.popover-arrow::before{
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before, #tamkinPlayerApp .bs-popover-start>.popover-arrow::before{
    right: 0;
    border-left-color: var(--bs-popover-arrow-border)
}
#tamkinPlayerApp .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after, #tamkinPlayerApp .bs-popover-start>.popover-arrow::after{
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg)
}
#tamkinPlayerApp .popover-header{
    padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
    margin-bottom: 0;
    font-size: var(--bs-popover-header-font-size);
    color: var(--bs-popover-header-color);
    background-color: var(--bs-popover-header-bg);
    border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    border-top-left-radius: var(--bs-popover-inner-border-radius);
    border-top-right-radius: var(--bs-popover-inner-border-radius)
}
#tamkinPlayerApp .popover-header:empty{
    display: none
}
#tamkinPlayerApp .popover-body{
    padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
    color: var(--bs-popover-body-color)
}
#tamkinPlayerApp .carousel{
    position: relative
}
#tamkinPlayerApp .carousel.pointer-event{
    touch-action: pan-y
}
#tamkinPlayerApp .carousel-inner{
    position: relative;
    width: 100%;
    overflow: hidden
}
#tamkinPlayerApp .carousel-inner::after{
    display: block;
    clear: both;
    content: ""
}
#tamkinPlayerApp .carousel-item{
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .carousel-item {
        transition: none
    }
}
#tamkinPlayerApp .carousel-item-next, #tamkinPlayerApp .carousel-item-prev, #tamkinPlayerApp .carousel-item.active{
    display: block
}
#tamkinPlayerApp .active.carousel-item-end, #tamkinPlayerApp .carousel-item-next:not(.carousel-item-start){
    transform: translateX(100%)
}
#tamkinPlayerApp .active.carousel-item-start, #tamkinPlayerApp .carousel-item-prev:not(.carousel-item-end){
    transform: translateX(-100%)
}
#tamkinPlayerApp .carousel-fade .carousel-item{
    opacity: 0;
    transition-property: opacity;
    transform: none
}
#tamkinPlayerApp .carousel-fade .carousel-item-next.carousel-item-start, #tamkinPlayerApp .carousel-fade .carousel-item-prev.carousel-item-end, #tamkinPlayerApp .carousel-fade .carousel-item.active{
    z-index: 1;
    opacity: 1
}
#tamkinPlayerApp .carousel-fade .active.carousel-item-end, #tamkinPlayerApp .carousel-fade .active.carousel-item-start{
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {

    #tamkinPlayerApp .carousel-fade .active.carousel-item-end,
    #tamkinPlayerApp .carousel-fade .active.carousel-item-start {
        transition: none
    }
}
#tamkinPlayerApp .carousel-control-next, #tamkinPlayerApp .carousel-control-prev{
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

    #tamkinPlayerApp .carousel-control-next,
    #tamkinPlayerApp .carousel-control-prev {
        transition: none
    }
}
#tamkinPlayerApp .carousel-control-next:focus, #tamkinPlayerApp .carousel-control-next:hover, #tamkinPlayerApp .carousel-control-prev:focus, #tamkinPlayerApp .carousel-control-prev:hover{
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}
#tamkinPlayerApp .carousel-control-prev{
    left: 0
}
#tamkinPlayerApp .carousel-control-next{
    right: 0
}
#tamkinPlayerApp .carousel-control-next-icon, #tamkinPlayerApp .carousel-control-prev-icon{
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}
#tamkinPlayerApp .carousel-control-prev-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}
#tamkinPlayerApp .carousel-control-next-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}
#tamkinPlayerApp .carousel-indicators{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}
#tamkinPlayerApp .carousel-indicators [data-bs-target]{
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .carousel-indicators [data-bs-target] {
        transition: none
    }
}
#tamkinPlayerApp .carousel-indicators .active{
    opacity: 1
}
#tamkinPlayerApp .carousel-caption{
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}
#tamkinPlayerApp .carousel-dark .carousel-control-next-icon, #tamkinPlayerApp .carousel-dark .carousel-control-prev-icon{
    filter: invert(1) grayscale(100)
}
#tamkinPlayerApp .carousel-dark .carousel-indicators [data-bs-target]{
    background-color: #000
}
#tamkinPlayerApp .carousel-dark .carousel-caption{
    color: #000
}
#tamkinPlayerApp .spinner-border, #tamkinPlayerApp .spinner-grow{
    display: inline-block;
    width: var(--bs-spinner-width);
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    border-radius: 50%;
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name)
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}
#tamkinPlayerApp .spinner-border{
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.25em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-border;
    border: var(--bs-spinner-border-width) solid currentcolor;
    border-right-color: transparent
}
#tamkinPlayerApp .spinner-border-sm{
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
    --bs-spinner-border-width: 0.2em
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }
    50%{
        opacity: 1;
        transform: none
    }
}
#tamkinPlayerApp .spinner-grow{
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0
}
#tamkinPlayerApp .spinner-grow-sm{
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem
}

@media (prefers-reduced-motion:reduce) {

    #tamkinPlayerApp .spinner-border,
    #tamkinPlayerApp .spinner-grow {
        --bs-spinner-animation-speed: 1.5s
    }
}
#tamkinPlayerApp .offcanvas, #tamkinPlayerApp .offcanvas-lg, #tamkinPlayerApp .offcanvas-md, #tamkinPlayerApp .offcanvas-sm, #tamkinPlayerApp .offcanvas-xl, #tamkinPlayerApp .offcanvas-xxl{
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 400px;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 1rem;
    --bs-offcanvas-padding-y: 1rem;
    --bs-offcanvas-color: ;
    --bs-offcanvas-bg: #fff;
    --bs-offcanvas-border-width: 1px;
    --bs-offcanvas-border-color: var(--bs-border-color-translucent);
    --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)
}

@media (max-width:575.98px) {
    #tamkinPlayerApp .offcanvas-sm {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: transform .3s ease-in-out
    }
}

@media (max-width:575.98px) and (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .offcanvas-sm {
        transition: none
    }
}

@media (max-width:575.98px) {
    #tamkinPlayerApp .offcanvas-sm.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media (max-width:575.98px) {
    #tamkinPlayerApp .offcanvas-sm.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media (max-width:575.98px) {
    #tamkinPlayerApp .offcanvas-sm.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media (max-width:575.98px) {
    #tamkinPlayerApp .offcanvas-sm.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media (max-width:575.98px) {

    #tamkinPlayerApp .offcanvas-sm.show:not(.hiding),
    #tamkinPlayerApp .offcanvas-sm.showing {
        transform: none
    }
}

@media (max-width:575.98px) {

    #tamkinPlayerApp .offcanvas-sm.hiding,
    #tamkinPlayerApp .offcanvas-sm.show,
    #tamkinPlayerApp .offcanvas-sm.showing {
        visibility: visible
    }
}

@media (min-width:576px) {
    #tamkinPlayerApp .offcanvas-sm {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }
    #tamkinPlayerApp .offcanvas-sm .offcanvas-header{
        display: none
    }
    #tamkinPlayerApp .offcanvas-sm .offcanvas-body{
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:767.98px) {
    #tamkinPlayerApp .offcanvas-md {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: transform .3s ease-in-out
    }
}

@media (max-width:767.98px) and (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .offcanvas-md {
        transition: none
    }
}

@media (max-width:767.98px) {
    #tamkinPlayerApp .offcanvas-md.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media (max-width:767.98px) {
    #tamkinPlayerApp .offcanvas-md.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media (max-width:767.98px) {
    #tamkinPlayerApp .offcanvas-md.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media (max-width:767.98px) {
    #tamkinPlayerApp .offcanvas-md.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media (max-width:767.98px) {

    #tamkinPlayerApp .offcanvas-md.show:not(.hiding),
    #tamkinPlayerApp .offcanvas-md.showing {
        transform: none
    }
}

@media (max-width:767.98px) {

    #tamkinPlayerApp .offcanvas-md.hiding,
    #tamkinPlayerApp .offcanvas-md.show,
    #tamkinPlayerApp .offcanvas-md.showing {
        visibility: visible
    }
}

@media (min-width:768px) {
    #tamkinPlayerApp .offcanvas-md {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }
    #tamkinPlayerApp .offcanvas-md .offcanvas-header{
        display: none
    }
    #tamkinPlayerApp .offcanvas-md .offcanvas-body{
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:991.98px) {
    #tamkinPlayerApp .offcanvas-lg {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: transform .3s ease-in-out
    }
}

@media (max-width:991.98px) and (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .offcanvas-lg {
        transition: none
    }
}

@media (max-width:991.98px) {
    #tamkinPlayerApp .offcanvas-lg.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media (max-width:991.98px) {
    #tamkinPlayerApp .offcanvas-lg.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media (max-width:991.98px) {
    #tamkinPlayerApp .offcanvas-lg.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media (max-width:991.98px) {
    #tamkinPlayerApp .offcanvas-lg.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media (max-width:991.98px) {

    #tamkinPlayerApp .offcanvas-lg.show:not(.hiding),
    #tamkinPlayerApp .offcanvas-lg.showing {
        transform: none
    }
}

@media (max-width:991.98px) {

    #tamkinPlayerApp .offcanvas-lg.hiding,
    #tamkinPlayerApp .offcanvas-lg.show,
    #tamkinPlayerApp .offcanvas-lg.showing {
        visibility: visible
    }
}

@media (min-width:992px) {
    #tamkinPlayerApp .offcanvas-lg {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }
    #tamkinPlayerApp .offcanvas-lg .offcanvas-header{
        display: none
    }
    #tamkinPlayerApp .offcanvas-lg .offcanvas-body{
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:1199.98px) {
    #tamkinPlayerApp .offcanvas-xl {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: transform .3s ease-in-out
    }
}

@media (max-width:1199.98px) and (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .offcanvas-xl {
        transition: none
    }
}

@media (max-width:1199.98px) {
    #tamkinPlayerApp .offcanvas-xl.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media (max-width:1199.98px) {
    #tamkinPlayerApp .offcanvas-xl.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media (max-width:1199.98px) {
    #tamkinPlayerApp .offcanvas-xl.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media (max-width:1199.98px) {
    #tamkinPlayerApp .offcanvas-xl.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media (max-width:1199.98px) {

    #tamkinPlayerApp .offcanvas-xl.show:not(.hiding),
    #tamkinPlayerApp .offcanvas-xl.showing {
        transform: none
    }
}

@media (max-width:1199.98px) {

    #tamkinPlayerApp .offcanvas-xl.hiding,
    #tamkinPlayerApp .offcanvas-xl.show,
    #tamkinPlayerApp .offcanvas-xl.showing {
        visibility: visible
    }
}

@media (min-width:1200px) {
    #tamkinPlayerApp .offcanvas-xl {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }
    #tamkinPlayerApp .offcanvas-xl .offcanvas-header{
        display: none
    }
    #tamkinPlayerApp .offcanvas-xl .offcanvas-body{
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}

@media (max-width:1399.98px) {
    #tamkinPlayerApp .offcanvas-xxl {
        position: fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: flex;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        transition: transform .3s ease-in-out
    }
}

@media (max-width:1399.98px) and (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .offcanvas-xxl {
        transition: none
    }
}

@media (max-width:1399.98px) {
    #tamkinPlayerApp .offcanvas-xxl.offcanvas-start {
        top: 0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(-100%)
    }
}

@media (max-width:1399.98px) {
    #tamkinPlayerApp .offcanvas-xxl.offcanvas-end {
        top: 0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%)
    }
}

@media (max-width:1399.98px) {
    #tamkinPlayerApp .offcanvas-xxl.offcanvas-top {
        top: 0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(-100%)
    }
}

@media (max-width:1399.98px) {
    #tamkinPlayerApp .offcanvas-xxl.offcanvas-bottom {
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateY(100%)
    }
}

@media (max-width:1399.98px) {

    #tamkinPlayerApp .offcanvas-xxl.show:not(.hiding),
    #tamkinPlayerApp .offcanvas-xxl.showing {
        transform: none
    }
}

@media (max-width:1399.98px) {

    #tamkinPlayerApp .offcanvas-xxl.hiding,
    #tamkinPlayerApp .offcanvas-xxl.show,
    #tamkinPlayerApp .offcanvas-xxl.showing {
        visibility: visible
    }
}

@media (min-width:1400px) {
    #tamkinPlayerApp .offcanvas-xxl {
        --bs-offcanvas-height: auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent !important
    }
    #tamkinPlayerApp .offcanvas-xxl .offcanvas-header{
        display: none
    }
    #tamkinPlayerApp .offcanvas-xxl .offcanvas-body{
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important
    }
}
#tamkinPlayerApp .offcanvas{
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    #tamkinPlayerApp .offcanvas {
        transition: none
    }
}
#tamkinPlayerApp .offcanvas.offcanvas-start{
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%)
}
#tamkinPlayerApp .offcanvas.offcanvas-end{
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%)
}
#tamkinPlayerApp .offcanvas.offcanvas-top{
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%)
}
#tamkinPlayerApp .offcanvas.offcanvas-bottom{
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%)
}
#tamkinPlayerApp .offcanvas.show:not(.hiding), #tamkinPlayerApp .offcanvas.showing{
    transform: none
}
#tamkinPlayerApp .offcanvas.hiding, #tamkinPlayerApp .offcanvas.show, #tamkinPlayerApp .offcanvas.showing{
    visibility: visible
}
.offcanvas-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}
.offcanvas-backdrop.fade{
    opacity: 0
}
.offcanvas-backdrop.show{
    opacity: .5
}
#tamkinPlayerApp .offcanvas-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x)
}
#tamkinPlayerApp .offcanvas-header .btn-close{
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y))
}
#tamkinPlayerApp .offcanvas-title{
    margin-bottom: 0;
    line-height: 1.5
}
#tamkinPlayerApp .offcanvas-body{
    flex-grow: 1;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    overflow-y: auto
}
#tamkinPlayerApp .placeholder{
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: .5
}
#tamkinPlayerApp .placeholder.btn::before{
    display: inline-block;
    content: ""
}
#tamkinPlayerApp .placeholder-xs{
    min-height: .6em
}
#tamkinPlayerApp .placeholder-sm{
    min-height: .8em
}
#tamkinPlayerApp .placeholder-lg{
    min-height: 1.2em
}
#tamkinPlayerApp .placeholder-glow .placeholder{
    animation: placeholder-glow 2s ease-in-out infinite
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}
#tamkinPlayerApp .placeholder-wave{
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite
}

@keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0%;
        mask-position: -200% 0%
    }
}
#tamkinPlayerApp .clearfix::after{
    display: block;
    clear: both;
    content: ""
}
#tamkinPlayerApp .text-bg-primary{
    color: #fff !important;
    background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important
}
#tamkinPlayerApp .text-bg-secondary{
    color: #fff !important;
    background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important
}
#tamkinPlayerApp .text-bg-success{
    color: #fff !important;
    background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important
}
#tamkinPlayerApp .text-bg-info{
    color: #000 !important;
    background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important
}
#tamkinPlayerApp .text-bg-warning{
    color: #000 !important;
    background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important
}
#tamkinPlayerApp .text-bg-danger{
    color: #fff !important;
    background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important
}
#tamkinPlayerApp .text-bg-light{
    color: #000 !important;
    background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important
}
#tamkinPlayerApp .text-bg-dark{
    color: #fff !important;
    background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important
}
#tamkinPlayerApp .link-primary{
    color: #0d6efd !important
}
#tamkinPlayerApp .link-primary:focus, #tamkinPlayerApp .link-primary:hover{
    color: #0a58ca !important
}
#tamkinPlayerApp .link-secondary{
    color: #6c757d !important
}
#tamkinPlayerApp .link-secondary:focus, #tamkinPlayerApp .link-secondary:hover{
    color: #565e64 !important
}
#tamkinPlayerApp .link-success{
    color: #198754 !important
}
#tamkinPlayerApp .link-success:focus, #tamkinPlayerApp .link-success:hover{
    color: #146c43 !important
}
#tamkinPlayerApp .link-info{
    color: #0dcaf0 !important
}
#tamkinPlayerApp .link-info:focus, #tamkinPlayerApp .link-info:hover{
    color: #3dd5f3 !important
}
#tamkinPlayerApp .link-warning{
    color: #ffc107 !important
}
#tamkinPlayerApp .link-warning:focus, #tamkinPlayerApp .link-warning:hover{
    color: #ffcd39 !important
}
#tamkinPlayerApp .link-danger{
    color: #dc3545 !important
}
#tamkinPlayerApp .link-danger:focus, #tamkinPlayerApp .link-danger:hover{
    color: #b02a37 !important
}
#tamkinPlayerApp .link-light{
    color: #f8f9fa !important
}
#tamkinPlayerApp .link-light:focus, #tamkinPlayerApp .link-light:hover{
    color: #f9fafb !important
}
#tamkinPlayerApp .link-dark{
    color: #212529 !important
}
#tamkinPlayerApp .link-dark:focus, #tamkinPlayerApp .link-dark:hover{
    color: #1a1e21 !important
}
#tamkinPlayerApp .ratio{
    position: relative;
    width: 100%
}
#tamkinPlayerApp .ratio::before{
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}
#tamkinPlayerApp .ratio>*{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}
#tamkinPlayerApp .ratio-1x1{
    --bs-aspect-ratio: 100%
}
#tamkinPlayerApp .ratio-4x3{
    --bs-aspect-ratio: 75%
}
#tamkinPlayerApp .ratio-16x9{
    --bs-aspect-ratio: 56.25%
}
#tamkinPlayerApp .ratio-21x9{
    --bs-aspect-ratio: 42.8571428571%
}
#tamkinPlayerApp .fixed-top{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}
#tamkinPlayerApp .fixed-bottom{
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}
#tamkinPlayerApp .sticky-top{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
}
#tamkinPlayerApp .sticky-bottom{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020
}

@media (min-width:576px) {
    #tamkinPlayerApp .sticky-sm-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
    #tamkinPlayerApp .sticky-sm-bottom{
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:768px) {
    #tamkinPlayerApp .sticky-md-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
    #tamkinPlayerApp .sticky-md-bottom{
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:992px) {
    #tamkinPlayerApp .sticky-lg-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
    #tamkinPlayerApp .sticky-lg-bottom{
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:1200px) {
    #tamkinPlayerApp .sticky-xl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
    #tamkinPlayerApp .sticky-xl-bottom{
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width:1400px) {
    #tamkinPlayerApp .sticky-xxl-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
    #tamkinPlayerApp .sticky-xxl-bottom{
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}
#tamkinPlayerApp .hstack{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch
}
#tamkinPlayerApp .vstack{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch
}
#tamkinPlayerApp .visually-hidden, #tamkinPlayerApp .visually-hidden-focusable:not(:focus):not(:focus-within){
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}
#tamkinPlayerApp .stretched-link::after{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}
#tamkinPlayerApp .text-truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
#tamkinPlayerApp .vr{
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentcolor;
    opacity: .25
}
#tamkinPlayerApp .align-baseline{
    vertical-align: baseline !important
}
#tamkinPlayerApp .align-top{
    vertical-align: top !important
}
#tamkinPlayerApp .align-middle{
    vertical-align: middle !important
}
#tamkinPlayerApp .align-bottom{
    vertical-align: bottom !important
}
#tamkinPlayerApp .align-text-bottom{
    vertical-align: text-bottom !important
}
#tamkinPlayerApp .align-text-top{
    vertical-align: text-top !important
}
#tamkinPlayerApp .float-start{
    float: left !important
}
#tamkinPlayerApp .float-end{
    float: right !important
}
#tamkinPlayerApp .float-none{
    float: none !important
}
#tamkinPlayerApp .opacity-0{
    opacity: 0 !important
}
#tamkinPlayerApp .opacity-25{
    opacity: .25 !important
}
#tamkinPlayerApp .opacity-50{
    opacity: .5 !important
}
#tamkinPlayerApp .opacity-75{
    opacity: .75 !important
}
#tamkinPlayerApp .opacity-100{
    opacity: 1 !important
}
#tamkinPlayerApp .overflow-auto{
    overflow: auto !important
}
#tamkinPlayerApp .overflow-hidden{
    overflow: hidden !important
}
#tamkinPlayerApp .overflow-visible{
    overflow: visible !important
}
#tamkinPlayerApp .overflow-scroll{
    overflow: scroll !important
}
#tamkinPlayerApp .d-inline{
    display: inline !important
}
#tamkinPlayerApp .d-inline-block{
    display: inline-block !important
}
#tamkinPlayerApp .d-block{
    display: block !important
}
#tamkinPlayerApp .d-grid{
    display: grid !important
}
#tamkinPlayerApp .d-table{
    display: table !important
}
#tamkinPlayerApp .d-table-row{
    display: table-row !important
}
#tamkinPlayerApp .d-table-cell{
    display: table-cell !important
}
#tamkinPlayerApp .d-flex{
    display: flex !important
}
#tamkinPlayerApp .d-inline-flex{
    display: inline-flex !important
}
#tamkinPlayerApp .d-none{
    display: none !important
}
#tamkinPlayerApp .shadow{
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}
#tamkinPlayerApp .shadow-sm{
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}
#tamkinPlayerApp .shadow-lg{
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}
#tamkinPlayerApp .shadow-none{
    box-shadow: none !important
}
#tamkinPlayerApp .position-static{
    position: static !important
}
#tamkinPlayerApp .position-relative{
    position: relative !important
}
#tamkinPlayerApp .position-absolute{
    position: absolute !important
}
#tamkinPlayerApp .position-fixed{
    position: fixed !important
}
#tamkinPlayerApp .position-sticky{
    position: -webkit-sticky !important;
    position: sticky !important
}
#tamkinPlayerApp .top-0{
    top: 0 !important
}
#tamkinPlayerApp .top-50{
    top: 50% !important
}
#tamkinPlayerApp .top-100{
    top: 100% !important
}
#tamkinPlayerApp .bottom-0{
    bottom: 0 !important
}
#tamkinPlayerApp .bottom-50{
    bottom: 50% !important
}
#tamkinPlayerApp .bottom-100{
    bottom: 100% !important
}
#tamkinPlayerApp .start-0{
    left: 0 !important
}
#tamkinPlayerApp .start-50{
    left: 50% !important
}
#tamkinPlayerApp .start-100{
    left: 100% !important
}
#tamkinPlayerApp .end-0{
    right: 0 !important
}
#tamkinPlayerApp .end-50{
    right: 50% !important
}
#tamkinPlayerApp .end-100{
    right: 100% !important
}
#tamkinPlayerApp .translate-middle{
    transform: translate(-50%, -50%) !important
}
#tamkinPlayerApp .translate-middle-x{
    transform: translateX(-50%) !important
}
#tamkinPlayerApp .translate-middle-y{
    transform: translateY(-50%) !important
}
#tamkinPlayerApp .border{
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}
#tamkinPlayerApp .border-0{
    border: 0 !important
}
#tamkinPlayerApp .border-top{
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}
#tamkinPlayerApp .border-top-0{
    border-top: 0 !important
}
#tamkinPlayerApp .border-end{
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}
#tamkinPlayerApp .border-end-0{
    border-right: 0 !important
}
#tamkinPlayerApp .border-bottom{
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}
#tamkinPlayerApp .border-bottom-0{
    border-bottom: 0 !important
}
#tamkinPlayerApp .border-start{
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
}
#tamkinPlayerApp .border-start-0{
    border-left: 0 !important
}
#tamkinPlayerApp .border-primary{
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important
}
#tamkinPlayerApp .border-secondary{
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important
}
#tamkinPlayerApp .border-success{
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important
}
#tamkinPlayerApp .border-info{
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important
}
#tamkinPlayerApp .border-warning{
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important
}
#tamkinPlayerApp .border-danger{
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important
}
#tamkinPlayerApp .border-light{
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important
}
#tamkinPlayerApp .border-dark{
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important
}
#tamkinPlayerApp .border-white{
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important
}
#tamkinPlayerApp .border-1{
    --bs-border-width: 1px
}
#tamkinPlayerApp .border-2{
    --bs-border-width: 2px
}
#tamkinPlayerApp .border-3{
    --bs-border-width: 3px
}
#tamkinPlayerApp .border-4{
    --bs-border-width: 4px
}
#tamkinPlayerApp .border-5{
    --bs-border-width: 5px
}
#tamkinPlayerApp .border-opacity-10{
    --bs-border-opacity: 0.1
}
#tamkinPlayerApp .border-opacity-25{
    --bs-border-opacity: 0.25
}
#tamkinPlayerApp .border-opacity-50{
    --bs-border-opacity: 0.5
}
#tamkinPlayerApp .border-opacity-75{
    --bs-border-opacity: 0.75
}
#tamkinPlayerApp .border-opacity-100{
    --bs-border-opacity: 1
}
#tamkinPlayerApp .w-25{
    width: 25% !important
}
#tamkinPlayerApp .w-50{
    width: 50% !important
}
#tamkinPlayerApp .w-75{
    width: 75% !important
}
#tamkinPlayerApp .w-100{
    width: 100% !important
}
#tamkinPlayerApp .w-auto{
    width: auto !important
}
#tamkinPlayerApp .mw-100{
    max-width: 100% !important
}
#tamkinPlayerApp .vw-100{
    width: 100vw !important
}
#tamkinPlayerApp .min-vw-100{
    min-width: 100vw !important
}
#tamkinPlayerApp .h-25{
    height: 25% !important
}
#tamkinPlayerApp .h-50{
    height: 50% !important
}
#tamkinPlayerApp .h-75{
    height: 75% !important
}
#tamkinPlayerApp .h-100{
    height: 100% !important
}
#tamkinPlayerApp .h-auto{
    height: auto !important
}
#tamkinPlayerApp .mh-100{
    max-height: 100% !important
}
#tamkinPlayerApp .vh-100{
    height: 100vh !important
}
#tamkinPlayerApp .min-vh-100{
    min-height: 100vh !important
}
#tamkinPlayerApp .flex-fill{
    flex: 1 1 auto !important
}
#tamkinPlayerApp .flex-row{
    flex-direction: row !important
}
#tamkinPlayerApp .flex-column{
    flex-direction: column !important
}
#tamkinPlayerApp .flex-row-reverse{
    flex-direction: row-reverse !important
}
#tamkinPlayerApp .flex-column-reverse{
    flex-direction: column-reverse !important
}
#tamkinPlayerApp .flex-grow-0{
    flex-grow: 0 !important
}
#tamkinPlayerApp .flex-grow-1{
    flex-grow: 1 !important
}
#tamkinPlayerApp .flex-shrink-0{
    flex-shrink: 0 !important
}
#tamkinPlayerApp .flex-shrink-1{
    flex-shrink: 1 !important
}
#tamkinPlayerApp .flex-wrap{
    flex-wrap: wrap !important
}
#tamkinPlayerApp .flex-nowrap{
    flex-wrap: nowrap !important
}
#tamkinPlayerApp .flex-wrap-reverse{
    flex-wrap: wrap-reverse !important
}
#tamkinPlayerApp .justify-content-start{
    justify-content: flex-start !important
}
#tamkinPlayerApp .justify-content-end{
    justify-content: flex-end !important
}
#tamkinPlayerApp .justify-content-center{
    justify-content: center !important
}
#tamkinPlayerApp .justify-content-between{
    justify-content: space-between !important
}
#tamkinPlayerApp .justify-content-around{
    justify-content: space-around !important
}
#tamkinPlayerApp .justify-content-evenly{
    justify-content: space-evenly !important
}
#tamkinPlayerApp .align-items-start{
    align-items: flex-start !important
}
#tamkinPlayerApp .align-items-end{
    align-items: flex-end !important
}
#tamkinPlayerApp .align-items-center{
    align-items: center !important
}
#tamkinPlayerApp .align-items-baseline{
    align-items: baseline !important
}
#tamkinPlayerApp .align-items-stretch{
    align-items: stretch !important
}
#tamkinPlayerApp .align-content-start{
    align-content: flex-start !important
}
#tamkinPlayerApp .align-content-end{
    align-content: flex-end !important
}
#tamkinPlayerApp .align-content-center{
    align-content: center !important
}
#tamkinPlayerApp .align-content-between{
    align-content: space-between !important
}
#tamkinPlayerApp .align-content-around{
    align-content: space-around !important
}
#tamkinPlayerApp .align-content-stretch{
    align-content: stretch !important
}
#tamkinPlayerApp .align-self-auto{
    align-self: auto !important
}
#tamkinPlayerApp .align-self-start{
    align-self: flex-start !important
}
#tamkinPlayerApp .align-self-end{
    align-self: flex-end !important
}
#tamkinPlayerApp .align-self-center{
    align-self: center !important
}
#tamkinPlayerApp .align-self-baseline{
    align-self: baseline !important
}
#tamkinPlayerApp .align-self-stretch{
    align-self: stretch !important
}
#tamkinPlayerApp .order-first{
    order: -1 !important
}
#tamkinPlayerApp .order-0{
    order: 0 !important
}
#tamkinPlayerApp .order-1{
    order: 1 !important
}
#tamkinPlayerApp .order-2{
    order: 2 !important
}
#tamkinPlayerApp .order-3{
    order: 3 !important
}
#tamkinPlayerApp .order-4{
    order: 4 !important
}
#tamkinPlayerApp .order-5{
    order: 5 !important
}
#tamkinPlayerApp .order-last{
    order: 6 !important
}
#tamkinPlayerApp .m-0{
    margin: 0 !important
}
#tamkinPlayerApp .m-1{
    margin: .25rem !important
}
#tamkinPlayerApp .m-2{
    margin: .5rem !important
}
#tamkinPlayerApp .m-3{
    margin: 1rem !important
}
#tamkinPlayerApp .m-4{
    margin: 1.5rem !important
}
#tamkinPlayerApp .m-5{
    margin: 3rem !important
}
#tamkinPlayerApp .m-auto{
    margin: auto !important
}
#tamkinPlayerApp .mx-0{
    margin-right: 0 !important;
    margin-left: 0 !important
}
#tamkinPlayerApp .mx-1{
    margin-right: .25rem !important;
    margin-left: .25rem !important
}
#tamkinPlayerApp .mx-2{
    margin-right: .5rem !important;
    margin-left: .5rem !important
}
#tamkinPlayerApp .mx-3{
    margin-right: 1rem !important;
    margin-left: 1rem !important
}
#tamkinPlayerApp .mx-4{
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}
#tamkinPlayerApp .mx-5{
    margin-right: 3rem !important;
    margin-left: 3rem !important
}
#tamkinPlayerApp .mx-auto{
    margin-right: auto !important;
    margin-left: auto !important
}
#tamkinPlayerApp .my-0{
    margin-top: 0 !important;
    margin-bottom: 0 !important
}
#tamkinPlayerApp .my-1{
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}
#tamkinPlayerApp .my-2{
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}
#tamkinPlayerApp .my-3{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}
#tamkinPlayerApp .my-4{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}
#tamkinPlayerApp .my-5{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}
#tamkinPlayerApp .my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important
}
#tamkinPlayerApp .mt-0{
    margin-top: 0 !important
}
#tamkinPlayerApp .mt-1{
    margin-top: .25rem !important
}
#tamkinPlayerApp .mt-2{
    margin-top: .5rem !important
}
#tamkinPlayerApp .mt-3{
    margin-top: 1rem !important
}
#tamkinPlayerApp .mt-4{
    margin-top: 1.5rem !important
}
#tamkinPlayerApp .mt-5{
    margin-top: 3rem !important
}
#tamkinPlayerApp .mt-auto{
    margin-top: auto !important
}
#tamkinPlayerApp .me-0{
    margin-right: 0 !important
}
#tamkinPlayerApp .me-1{
    margin-right: .25rem !important
}
#tamkinPlayerApp .me-2{
    margin-right: .5rem !important
}
#tamkinPlayerApp .me-3{
    margin-right: 1rem !important
}
#tamkinPlayerApp .me-4{
    margin-right: 1.5rem !important
}
#tamkinPlayerApp .me-5{
    margin-right: 3rem !important
}
#tamkinPlayerApp .me-auto{
    margin-right: auto !important
}
#tamkinPlayerApp .mb-0{
    margin-bottom: 0 !important
}
#tamkinPlayerApp .mb-1{
    margin-bottom: .25rem !important
}
#tamkinPlayerApp .mb-2{
    margin-bottom: .5rem !important
}
#tamkinPlayerApp .mb-3{
    margin-bottom: 1rem !important
}
#tamkinPlayerApp .mb-4{
    margin-bottom: 1.5rem !important
}
#tamkinPlayerApp .mb-5{
    margin-bottom: 3rem !important
}
#tamkinPlayerApp .mb-auto{
    margin-bottom: auto !important
}
#tamkinPlayerApp .ms-0{
    margin-left: 0 !important
}
#tamkinPlayerApp .ms-1{
    margin-left: .25rem !important
}
#tamkinPlayerApp .ms-2{
    margin-left: .5rem !important
}
#tamkinPlayerApp .ms-3{
    margin-left: 1rem !important
}
#tamkinPlayerApp .ms-4{
    margin-left: 1.5rem !important
}
#tamkinPlayerApp .ms-5{
    margin-left: 3rem !important
}
#tamkinPlayerApp .ms-auto{
    margin-left: auto !important
}
#tamkinPlayerApp .p-0{
    padding: 0 !important
}
#tamkinPlayerApp .p-1{
    padding: .25rem !important
}
#tamkinPlayerApp .p-2{
    padding: .5rem !important
}
#tamkinPlayerApp .p-3{
    padding: 1rem !important
}
#tamkinPlayerApp .p-4{
    padding: 1.5rem !important
}
#tamkinPlayerApp .p-5{
    padding: 3rem !important
}
#tamkinPlayerApp .px-0{
    padding-right: 0 !important;
    padding-left: 0 !important
}
#tamkinPlayerApp .px-1{
    padding-right: .25rem !important;
    padding-left: .25rem !important
}
#tamkinPlayerApp .px-2{
    padding-right: .5rem !important;
    padding-left: .5rem !important
}
#tamkinPlayerApp .px-3{
    padding-right: 1rem !important;
    padding-left: 1rem !important
}
#tamkinPlayerApp .px-4{
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}
#tamkinPlayerApp .px-5{
    padding-right: 3rem !important;
    padding-left: 3rem !important
}
#tamkinPlayerApp .py-0{
    padding-top: 0 !important;
    padding-bottom: 0 !important
}
#tamkinPlayerApp .py-1{
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}
#tamkinPlayerApp .py-2{
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}
#tamkinPlayerApp .py-3{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}
#tamkinPlayerApp .py-4{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}
#tamkinPlayerApp .py-5{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}
#tamkinPlayerApp .pt-0{
    padding-top: 0 !important
}
#tamkinPlayerApp .pt-1{
    padding-top: .25rem !important
}
#tamkinPlayerApp .pt-2{
    padding-top: .5rem !important
}
#tamkinPlayerApp .pt-3{
    padding-top: 1rem !important
}
#tamkinPlayerApp .pt-4{
    padding-top: 1.5rem !important
}
#tamkinPlayerApp .pt-5{
    padding-top: 3rem !important
}
#tamkinPlayerApp .pe-0{
    padding-right: 0 !important
}
#tamkinPlayerApp .pe-1{
    padding-right: .25rem !important
}
#tamkinPlayerApp .pe-2{
    padding-right: .5rem !important
}
#tamkinPlayerApp .pe-3{
    padding-right: 1rem !important
}
#tamkinPlayerApp .pe-4{
    padding-right: 1.5rem !important
}
#tamkinPlayerApp .pe-5{
    padding-right: 3rem !important
}
#tamkinPlayerApp .pb-0{
    padding-bottom: 0 !important
}
#tamkinPlayerApp .pb-1{
    padding-bottom: .25rem !important
}
#tamkinPlayerApp .pb-2{
    padding-bottom: .5rem !important
}
#tamkinPlayerApp .pb-3{
    padding-bottom: 1rem !important
}
#tamkinPlayerApp .pb-4{
    padding-bottom: 1.5rem !important
}
#tamkinPlayerApp .pb-5{
    padding-bottom: 3rem !important
}
#tamkinPlayerApp .ps-0{
    padding-left: 0 !important
}
#tamkinPlayerApp .ps-1{
    padding-left: .25rem !important
}
#tamkinPlayerApp .ps-2{
    padding-left: .5rem !important
}
#tamkinPlayerApp .ps-3{
    padding-left: 1rem !important
}
#tamkinPlayerApp .ps-4{
    padding-left: 1.5rem !important
}
#tamkinPlayerApp .ps-5{
    padding-left: 3rem !important
}
#tamkinPlayerApp .gap-0{
    gap: 0 !important
}
#tamkinPlayerApp .gap-1{
    gap: .25rem !important
}
#tamkinPlayerApp .gap-2{
    gap: .5rem !important
}
#tamkinPlayerApp .gap-3{
    gap: 1rem !important
}
#tamkinPlayerApp .gap-4{
    gap: 1.5rem !important
}
#tamkinPlayerApp .gap-5{
    gap: 3rem !important
}
#tamkinPlayerApp .font-monospace{
    font-family: var(--bs-font-monospace) !important
}
#tamkinPlayerApp .fs-1{
    font-size: calc(1.375rem + 1.5vw) !important
}
#tamkinPlayerApp .fs-2{
    font-size: calc(1.325rem + .9vw) !important
}
#tamkinPlayerApp .fs-3{
    font-size: calc(1.3rem + .6vw) !important
}
#tamkinPlayerApp .fs-4{
    font-size: calc(1.275rem + .3vw) !important
}
#tamkinPlayerApp .fs-5{
    font-size: 1.25rem !important
}
#tamkinPlayerApp .fs-6{
    font-size: 1rem !important
}
#tamkinPlayerApp .fst-italic{
    font-style: italic !important
}
#tamkinPlayerApp .fst-normal{
    font-style: normal !important
}
#tamkinPlayerApp .fw-light{
    font-weight: 300 !important
}
#tamkinPlayerApp .fw-lighter{
    font-weight: lighter !important
}
#tamkinPlayerApp .fw-normal{
    font-weight: 400 !important
}
#tamkinPlayerApp .fw-bold{
    font-weight: 700 !important
}
#tamkinPlayerApp .fw-semibold{
    font-weight: 600 !important
}
#tamkinPlayerApp .fw-bolder{
    font-weight: bolder !important
}
#tamkinPlayerApp .lh-1{
    line-height: 1 !important
}
#tamkinPlayerApp .lh-sm{
    line-height: 1.25 !important
}
#tamkinPlayerApp .lh-base{
    line-height: 1.5 !important
}
#tamkinPlayerApp .lh-lg{
    line-height: 2 !important
}
#tamkinPlayerApp .text-start{
    text-align: left !important
}
#tamkinPlayerApp .text-end{
    text-align: right !important
}
#tamkinPlayerApp .text-center{
    text-align: center !important
}
#tamkinPlayerApp .text-decoration-none{
    text-decoration: none !important
}
#tamkinPlayerApp .text-decoration-underline{
    text-decoration: underline !important
}
#tamkinPlayerApp .text-decoration-line-through{
    text-decoration: line-through !important
}
#tamkinPlayerApp .text-lowercase{
    text-transform: lowercase !important
}
#tamkinPlayerApp .text-uppercase{
    text-transform: uppercase !important
}
#tamkinPlayerApp .text-capitalize{
    text-transform: capitalize !important
}
#tamkinPlayerApp .text-wrap{
    white-space: normal !important
}
#tamkinPlayerApp .text-nowrap{
    white-space: nowrap !important
}
#tamkinPlayerApp .text-break{
    word-wrap: break-word !important;
    word-break: break-word !important
}
#tamkinPlayerApp .text-primary{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
}
#tamkinPlayerApp .text-secondary{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
}
#tamkinPlayerApp .text-success{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
}
#tamkinPlayerApp .text-info{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
}
#tamkinPlayerApp .text-warning{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
}
#tamkinPlayerApp .text-danger{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}
#tamkinPlayerApp .text-light{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
}
#tamkinPlayerApp .text-dark{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
}
#tamkinPlayerApp .text-black{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}
#tamkinPlayerApp .text-white{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}
#tamkinPlayerApp .text-body{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
}
#tamkinPlayerApp .text-muted{
    --bs-text-opacity: 1;
    color: #6c757d !important
}
#tamkinPlayerApp .text-black-50{
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, .5) !important
}
#tamkinPlayerApp .text-white-50{
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, .5) !important
}
#tamkinPlayerApp .text-reset{
    --bs-text-opacity: 1;
    color: inherit !important
}
#tamkinPlayerApp .text-opacity-25{
    --bs-text-opacity: 0.25
}
#tamkinPlayerApp .text-opacity-50{
    --bs-text-opacity: 0.5
}
#tamkinPlayerApp .text-opacity-75{
    --bs-text-opacity: 0.75
}
#tamkinPlayerApp .text-opacity-100{
    --bs-text-opacity: 1
}
#tamkinPlayerApp .bg-primary{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}
#tamkinPlayerApp .bg-secondary{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
}
#tamkinPlayerApp .bg-success{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
}
#tamkinPlayerApp .bg-info{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
}
#tamkinPlayerApp .bg-warning{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
}
#tamkinPlayerApp .bg-danger{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
}
#tamkinPlayerApp .bg-light{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
}
#tamkinPlayerApp .bg-dark{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}
#tamkinPlayerApp .bg-black{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
}
#tamkinPlayerApp .bg-white{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
}
#tamkinPlayerApp .bg-body{
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
}
#tamkinPlayerApp .bg-transparent{
    --bs-bg-opacity: 1;
    background-color: transparent !important
}
#tamkinPlayerApp .bg-opacity-10{
    --bs-bg-opacity: 0.1
}
#tamkinPlayerApp .bg-opacity-25{
    --bs-bg-opacity: 0.25
}
#tamkinPlayerApp .bg-opacity-50{
    --bs-bg-opacity: 0.5
}
#tamkinPlayerApp .bg-opacity-75{
    --bs-bg-opacity: 0.75
}
#tamkinPlayerApp .bg-opacity-100{
    --bs-bg-opacity: 1
}
#tamkinPlayerApp .bg-gradient{
    background-image: var(--bs-gradient) !important
}
#tamkinPlayerApp .user-select-all{
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important
}
#tamkinPlayerApp .user-select-auto{
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important
}
#tamkinPlayerApp .user-select-none{
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important
}
#tamkinPlayerApp .pe-none{
    pointer-events: none !important
}
#tamkinPlayerApp .pe-auto{
    pointer-events: auto !important
}
#tamkinPlayerApp .rounded{
    border-radius: var(--bs-border-radius) !important
}
#tamkinPlayerApp .rounded-0{
    border-radius: 0 !important
}
#tamkinPlayerApp .rounded-1{
    border-radius: var(--bs-border-radius-sm) !important
}
#tamkinPlayerApp .rounded-2{
    border-radius: var(--bs-border-radius) !important
}
#tamkinPlayerApp .rounded-3{
    border-radius: var(--bs-border-radius-lg) !important
}
#tamkinPlayerApp .rounded-4{
    border-radius: var(--bs-border-radius-xl) !important
}
#tamkinPlayerApp .rounded-5{
    border-radius: var(--bs-border-radius-2xl) !important
}
#tamkinPlayerApp .rounded-circle{
    border-radius: 50% !important
}
#tamkinPlayerApp .rounded-pill{
    border-radius: var(--bs-border-radius-pill) !important
}
#tamkinPlayerApp .rounded-top{
    border-top-left-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important
}
#tamkinPlayerApp .rounded-end{
    border-top-right-radius: var(--bs-border-radius) !important;
    border-bottom-right-radius: var(--bs-border-radius) !important
}
#tamkinPlayerApp .rounded-bottom{
    border-bottom-right-radius: var(--bs-border-radius) !important;
    border-bottom-left-radius: var(--bs-border-radius) !important
}
#tamkinPlayerApp .rounded-start{
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-top-left-radius: var(--bs-border-radius) !important
}
#tamkinPlayerApp .visible{
    visibility: visible !important
}
#tamkinPlayerApp .invisible{
    visibility: hidden !important
}

@media (min-width:576px) {
    #tamkinPlayerApp .float-sm-start {
        float: left !important
    }
    #tamkinPlayerApp .float-sm-end{
        float: right !important
    }
    #tamkinPlayerApp .float-sm-none{
        float: none !important
    }
    #tamkinPlayerApp .d-sm-inline{
        display: inline !important
    }
    #tamkinPlayerApp .d-sm-inline-block{
        display: inline-block !important
    }
    #tamkinPlayerApp .d-sm-block{
        display: block !important
    }
    #tamkinPlayerApp .d-sm-grid{
        display: grid !important
    }
    #tamkinPlayerApp .d-sm-table{
        display: table !important
    }
    #tamkinPlayerApp .d-sm-table-row{
        display: table-row !important
    }
    #tamkinPlayerApp .d-sm-table-cell{
        display: table-cell !important
    }
    #tamkinPlayerApp .d-sm-flex{
        display: flex !important
    }
    #tamkinPlayerApp .d-sm-inline-flex{
        display: inline-flex !important
    }
    #tamkinPlayerApp .d-sm-none{
        display: none !important
    }
    #tamkinPlayerApp .flex-sm-fill{
        flex: 1 1 auto !important
    }
    #tamkinPlayerApp .flex-sm-row{
        flex-direction: row !important
    }
    #tamkinPlayerApp .flex-sm-column{
        flex-direction: column !important
    }
    #tamkinPlayerApp .flex-sm-row-reverse{
        flex-direction: row-reverse !important
    }
    #tamkinPlayerApp .flex-sm-column-reverse{
        flex-direction: column-reverse !important
    }
    #tamkinPlayerApp .flex-sm-grow-0{
        flex-grow: 0 !important
    }
    #tamkinPlayerApp .flex-sm-grow-1{
        flex-grow: 1 !important
    }
    #tamkinPlayerApp .flex-sm-shrink-0{
        flex-shrink: 0 !important
    }
    #tamkinPlayerApp .flex-sm-shrink-1{
        flex-shrink: 1 !important
    }
    #tamkinPlayerApp .flex-sm-wrap{
        flex-wrap: wrap !important
    }
    #tamkinPlayerApp .flex-sm-nowrap{
        flex-wrap: nowrap !important
    }
    #tamkinPlayerApp .flex-sm-wrap-reverse{
        flex-wrap: wrap-reverse !important
    }
    #tamkinPlayerApp .justify-content-sm-start{
        justify-content: flex-start !important
    }
    #tamkinPlayerApp .justify-content-sm-end{
        justify-content: flex-end !important
    }
    #tamkinPlayerApp .justify-content-sm-center{
        justify-content: center !important
    }
    #tamkinPlayerApp .justify-content-sm-between{
        justify-content: space-between !important
    }
    #tamkinPlayerApp .justify-content-sm-around{
        justify-content: space-around !important
    }
    #tamkinPlayerApp .justify-content-sm-evenly{
        justify-content: space-evenly !important
    }
    #tamkinPlayerApp .align-items-sm-start{
        align-items: flex-start !important
    }
    #tamkinPlayerApp .align-items-sm-end{
        align-items: flex-end !important
    }
    #tamkinPlayerApp .align-items-sm-center{
        align-items: center !important
    }
    #tamkinPlayerApp .align-items-sm-baseline{
        align-items: baseline !important
    }
    #tamkinPlayerApp .align-items-sm-stretch{
        align-items: stretch !important
    }
    #tamkinPlayerApp .align-content-sm-start{
        align-content: flex-start !important
    }
    #tamkinPlayerApp .align-content-sm-end{
        align-content: flex-end !important
    }
    #tamkinPlayerApp .align-content-sm-center{
        align-content: center !important
    }
    #tamkinPlayerApp .align-content-sm-between{
        align-content: space-between !important
    }
    #tamkinPlayerApp .align-content-sm-around{
        align-content: space-around !important
    }
    #tamkinPlayerApp .align-content-sm-stretch{
        align-content: stretch !important
    }
    #tamkinPlayerApp .align-self-sm-auto{
        align-self: auto !important
    }
    #tamkinPlayerApp .align-self-sm-start{
        align-self: flex-start !important
    }
    #tamkinPlayerApp .align-self-sm-end{
        align-self: flex-end !important
    }
    #tamkinPlayerApp .align-self-sm-center{
        align-self: center !important
    }
    #tamkinPlayerApp .align-self-sm-baseline{
        align-self: baseline !important
    }
    #tamkinPlayerApp .align-self-sm-stretch{
        align-self: stretch !important
    }
    #tamkinPlayerApp .order-sm-first{
        order: -1 !important
    }
    #tamkinPlayerApp .order-sm-0{
        order: 0 !important
    }
    #tamkinPlayerApp .order-sm-1{
        order: 1 !important
    }
    #tamkinPlayerApp .order-sm-2{
        order: 2 !important
    }
    #tamkinPlayerApp .order-sm-3{
        order: 3 !important
    }
    #tamkinPlayerApp .order-sm-4{
        order: 4 !important
    }
    #tamkinPlayerApp .order-sm-5{
        order: 5 !important
    }
    #tamkinPlayerApp .order-sm-last{
        order: 6 !important
    }
    #tamkinPlayerApp .m-sm-0{
        margin: 0 !important
    }
    #tamkinPlayerApp .m-sm-1{
        margin: .25rem !important
    }
    #tamkinPlayerApp .m-sm-2{
        margin: .5rem !important
    }
    #tamkinPlayerApp .m-sm-3{
        margin: 1rem !important
    }
    #tamkinPlayerApp .m-sm-4{
        margin: 1.5rem !important
    }
    #tamkinPlayerApp .m-sm-5{
        margin: 3rem !important
    }
    #tamkinPlayerApp .m-sm-auto{
        margin: auto !important
    }
    #tamkinPlayerApp .mx-sm-0{
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    #tamkinPlayerApp .mx-sm-1{
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    #tamkinPlayerApp .mx-sm-2{
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    #tamkinPlayerApp .mx-sm-3{
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    #tamkinPlayerApp .mx-sm-4{
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    #tamkinPlayerApp .mx-sm-5{
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    #tamkinPlayerApp .mx-sm-auto{
        margin-right: auto !important;
        margin-left: auto !important
    }
    #tamkinPlayerApp .my-sm-0{
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    #tamkinPlayerApp .my-sm-1{
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    #tamkinPlayerApp .my-sm-2{
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    #tamkinPlayerApp .my-sm-3{
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    #tamkinPlayerApp .my-sm-4{
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .my-sm-5{
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    #tamkinPlayerApp .my-sm-auto{
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    #tamkinPlayerApp .mt-sm-0{
        margin-top: 0 !important
    }
    #tamkinPlayerApp .mt-sm-1{
        margin-top: .25rem !important
    }
    #tamkinPlayerApp .mt-sm-2{
        margin-top: .5rem !important
    }
    #tamkinPlayerApp .mt-sm-3{
        margin-top: 1rem !important
    }
    #tamkinPlayerApp .mt-sm-4{
        margin-top: 1.5rem !important
    }
    #tamkinPlayerApp .mt-sm-5{
        margin-top: 3rem !important
    }
    #tamkinPlayerApp .mt-sm-auto{
        margin-top: auto !important
    }
    #tamkinPlayerApp .me-sm-0{
        margin-right: 0 !important
    }
    #tamkinPlayerApp .me-sm-1{
        margin-right: .25rem !important
    }
    #tamkinPlayerApp .me-sm-2{
        margin-right: .5rem !important
    }
    #tamkinPlayerApp .me-sm-3{
        margin-right: 1rem !important
    }
    #tamkinPlayerApp .me-sm-4{
        margin-right: 1.5rem !important
    }
    #tamkinPlayerApp .me-sm-5{
        margin-right: 3rem !important
    }
    #tamkinPlayerApp .me-sm-auto{
        margin-right: auto !important
    }
    #tamkinPlayerApp .mb-sm-0{
        margin-bottom: 0 !important
    }
    #tamkinPlayerApp .mb-sm-1{
        margin-bottom: .25rem !important
    }
    #tamkinPlayerApp .mb-sm-2{
        margin-bottom: .5rem !important
    }
    #tamkinPlayerApp .mb-sm-3{
        margin-bottom: 1rem !important
    }
    #tamkinPlayerApp .mb-sm-4{
        margin-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .mb-sm-5{
        margin-bottom: 3rem !important
    }
    #tamkinPlayerApp .mb-sm-auto{
        margin-bottom: auto !important
    }
    #tamkinPlayerApp .ms-sm-0{
        margin-left: 0 !important
    }
    #tamkinPlayerApp .ms-sm-1{
        margin-left: .25rem !important
    }
    #tamkinPlayerApp .ms-sm-2{
        margin-left: .5rem !important
    }
    #tamkinPlayerApp .ms-sm-3{
        margin-left: 1rem !important
    }
    #tamkinPlayerApp .ms-sm-4{
        margin-left: 1.5rem !important
    }
    #tamkinPlayerApp .ms-sm-5{
        margin-left: 3rem !important
    }
    #tamkinPlayerApp .ms-sm-auto{
        margin-left: auto !important
    }
    #tamkinPlayerApp .p-sm-0{
        padding: 0 !important
    }
    #tamkinPlayerApp .p-sm-1{
        padding: .25rem !important
    }
    #tamkinPlayerApp .p-sm-2{
        padding: .5rem !important
    }
    #tamkinPlayerApp .p-sm-3{
        padding: 1rem !important
    }
    #tamkinPlayerApp .p-sm-4{
        padding: 1.5rem !important
    }
    #tamkinPlayerApp .p-sm-5{
        padding: 3rem !important
    }
    #tamkinPlayerApp .px-sm-0{
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    #tamkinPlayerApp .px-sm-1{
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    #tamkinPlayerApp .px-sm-2{
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    #tamkinPlayerApp .px-sm-3{
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    #tamkinPlayerApp .px-sm-4{
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    #tamkinPlayerApp .px-sm-5{
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    #tamkinPlayerApp .py-sm-0{
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    #tamkinPlayerApp .py-sm-1{
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    #tamkinPlayerApp .py-sm-2{
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    #tamkinPlayerApp .py-sm-3{
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    #tamkinPlayerApp .py-sm-4{
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .py-sm-5{
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    #tamkinPlayerApp .pt-sm-0{
        padding-top: 0 !important
    }
    #tamkinPlayerApp .pt-sm-1{
        padding-top: .25rem !important
    }
    #tamkinPlayerApp .pt-sm-2{
        padding-top: .5rem !important
    }
    #tamkinPlayerApp .pt-sm-3{
        padding-top: 1rem !important
    }
    #tamkinPlayerApp .pt-sm-4{
        padding-top: 1.5rem !important
    }
    #tamkinPlayerApp .pt-sm-5{
        padding-top: 3rem !important
    }
    #tamkinPlayerApp .pe-sm-0{
        padding-right: 0 !important
    }
    #tamkinPlayerApp .pe-sm-1{
        padding-right: .25rem !important
    }
    #tamkinPlayerApp .pe-sm-2{
        padding-right: .5rem !important
    }
    #tamkinPlayerApp .pe-sm-3{
        padding-right: 1rem !important
    }
    #tamkinPlayerApp .pe-sm-4{
        padding-right: 1.5rem !important
    }
    #tamkinPlayerApp .pe-sm-5{
        padding-right: 3rem !important
    }
    #tamkinPlayerApp .pb-sm-0{
        padding-bottom: 0 !important
    }
    #tamkinPlayerApp .pb-sm-1{
        padding-bottom: .25rem !important
    }
    #tamkinPlayerApp .pb-sm-2{
        padding-bottom: .5rem !important
    }
    #tamkinPlayerApp .pb-sm-3{
        padding-bottom: 1rem !important
    }
    #tamkinPlayerApp .pb-sm-4{
        padding-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .pb-sm-5{
        padding-bottom: 3rem !important
    }
    #tamkinPlayerApp .ps-sm-0{
        padding-left: 0 !important
    }
    #tamkinPlayerApp .ps-sm-1{
        padding-left: .25rem !important
    }
    #tamkinPlayerApp .ps-sm-2{
        padding-left: .5rem !important
    }
    #tamkinPlayerApp .ps-sm-3{
        padding-left: 1rem !important
    }
    #tamkinPlayerApp .ps-sm-4{
        padding-left: 1.5rem !important
    }
    #tamkinPlayerApp .ps-sm-5{
        padding-left: 3rem !important
    }
    #tamkinPlayerApp .gap-sm-0{
        gap: 0 !important
    }
    #tamkinPlayerApp .gap-sm-1{
        gap: .25rem !important
    }
    #tamkinPlayerApp .gap-sm-2{
        gap: .5rem !important
    }
    #tamkinPlayerApp .gap-sm-3{
        gap: 1rem !important
    }
    #tamkinPlayerApp .gap-sm-4{
        gap: 1.5rem !important
    }
    #tamkinPlayerApp .gap-sm-5{
        gap: 3rem !important
    }
    #tamkinPlayerApp .text-sm-start{
        text-align: left !important
    }
    #tamkinPlayerApp .text-sm-end{
        text-align: right !important
    }
    #tamkinPlayerApp .text-sm-center{
        text-align: center !important
    }
}

@media (min-width:768px) {
    #tamkinPlayerApp .float-md-start {
        float: left !important
    }
    #tamkinPlayerApp .float-md-end{
        float: right !important
    }
    #tamkinPlayerApp .float-md-none{
        float: none !important
    }
    #tamkinPlayerApp .d-md-inline{
        display: inline !important
    }
    #tamkinPlayerApp .d-md-inline-block{
        display: inline-block !important
    }
    #tamkinPlayerApp .d-md-block{
        display: block !important
    }
    #tamkinPlayerApp .d-md-grid{
        display: grid !important
    }
    #tamkinPlayerApp .d-md-table{
        display: table !important
    }
    #tamkinPlayerApp .d-md-table-row{
        display: table-row !important
    }
    #tamkinPlayerApp .d-md-table-cell{
        display: table-cell !important
    }
    #tamkinPlayerApp .d-md-flex{
        display: flex !important
    }
    #tamkinPlayerApp .d-md-inline-flex{
        display: inline-flex !important
    }
    #tamkinPlayerApp .d-md-none{
        display: none !important
    }
    #tamkinPlayerApp .flex-md-fill{
        flex: 1 1 auto !important
    }
    #tamkinPlayerApp .flex-md-row{
        flex-direction: row !important
    }
    #tamkinPlayerApp .flex-md-column{
        flex-direction: column !important
    }
    #tamkinPlayerApp .flex-md-row-reverse{
        flex-direction: row-reverse !important
    }
    #tamkinPlayerApp .flex-md-column-reverse{
        flex-direction: column-reverse !important
    }
    #tamkinPlayerApp .flex-md-grow-0{
        flex-grow: 0 !important
    }
    #tamkinPlayerApp .flex-md-grow-1{
        flex-grow: 1 !important
    }
    #tamkinPlayerApp .flex-md-shrink-0{
        flex-shrink: 0 !important
    }
    #tamkinPlayerApp .flex-md-shrink-1{
        flex-shrink: 1 !important
    }
    #tamkinPlayerApp .flex-md-wrap{
        flex-wrap: wrap !important
    }
    #tamkinPlayerApp .flex-md-nowrap{
        flex-wrap: nowrap !important
    }
    #tamkinPlayerApp .flex-md-wrap-reverse{
        flex-wrap: wrap-reverse !important
    }
    #tamkinPlayerApp .justify-content-md-start{
        justify-content: flex-start !important
    }
    #tamkinPlayerApp .justify-content-md-end{
        justify-content: flex-end !important
    }
    #tamkinPlayerApp .justify-content-md-center{
        justify-content: center !important
    }
    #tamkinPlayerApp .justify-content-md-between{
        justify-content: space-between !important
    }
    #tamkinPlayerApp .justify-content-md-around{
        justify-content: space-around !important
    }
    #tamkinPlayerApp .justify-content-md-evenly{
        justify-content: space-evenly !important
    }
    #tamkinPlayerApp .align-items-md-start{
        align-items: flex-start !important
    }
    #tamkinPlayerApp .align-items-md-end{
        align-items: flex-end !important
    }
    #tamkinPlayerApp .align-items-md-center{
        align-items: center !important
    }
    #tamkinPlayerApp .align-items-md-baseline{
        align-items: baseline !important
    }
    #tamkinPlayerApp .align-items-md-stretch{
        align-items: stretch !important
    }
    #tamkinPlayerApp .align-content-md-start{
        align-content: flex-start !important
    }
    #tamkinPlayerApp .align-content-md-end{
        align-content: flex-end !important
    }
    #tamkinPlayerApp .align-content-md-center{
        align-content: center !important
    }
    #tamkinPlayerApp .align-content-md-between{
        align-content: space-between !important
    }
    #tamkinPlayerApp .align-content-md-around{
        align-content: space-around !important
    }
    #tamkinPlayerApp .align-content-md-stretch{
        align-content: stretch !important
    }
    #tamkinPlayerApp .align-self-md-auto{
        align-self: auto !important
    }
    #tamkinPlayerApp .align-self-md-start{
        align-self: flex-start !important
    }
    #tamkinPlayerApp .align-self-md-end{
        align-self: flex-end !important
    }
    #tamkinPlayerApp .align-self-md-center{
        align-self: center !important
    }
    #tamkinPlayerApp .align-self-md-baseline{
        align-self: baseline !important
    }
    #tamkinPlayerApp .align-self-md-stretch{
        align-self: stretch !important
    }
    #tamkinPlayerApp .order-md-first{
        order: -1 !important
    }
    #tamkinPlayerApp .order-md-0{
        order: 0 !important
    }
    #tamkinPlayerApp .order-md-1{
        order: 1 !important
    }
    #tamkinPlayerApp .order-md-2{
        order: 2 !important
    }
    #tamkinPlayerApp .order-md-3{
        order: 3 !important
    }
    #tamkinPlayerApp .order-md-4{
        order: 4 !important
    }
    #tamkinPlayerApp .order-md-5{
        order: 5 !important
    }
    #tamkinPlayerApp .order-md-last{
        order: 6 !important
    }
    #tamkinPlayerApp .m-md-0{
        margin: 0 !important
    }
    #tamkinPlayerApp .m-md-1{
        margin: .25rem !important
    }
    #tamkinPlayerApp .m-md-2{
        margin: .5rem !important
    }
    #tamkinPlayerApp .m-md-3{
        margin: 1rem !important
    }
    #tamkinPlayerApp .m-md-4{
        margin: 1.5rem !important
    }
    #tamkinPlayerApp .m-md-5{
        margin: 3rem !important
    }
    #tamkinPlayerApp .m-md-auto{
        margin: auto !important
    }
    #tamkinPlayerApp .mx-md-0{
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    #tamkinPlayerApp .mx-md-1{
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    #tamkinPlayerApp .mx-md-2{
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    #tamkinPlayerApp .mx-md-3{
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    #tamkinPlayerApp .mx-md-4{
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    #tamkinPlayerApp .mx-md-5{
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    #tamkinPlayerApp .mx-md-auto{
        margin-right: auto !important;
        margin-left: auto !important
    }
    #tamkinPlayerApp .my-md-0{
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    #tamkinPlayerApp .my-md-1{
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    #tamkinPlayerApp .my-md-2{
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    #tamkinPlayerApp .my-md-3{
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    #tamkinPlayerApp .my-md-4{
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .my-md-5{
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    #tamkinPlayerApp .my-md-auto{
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    #tamkinPlayerApp .mt-md-0{
        margin-top: 0 !important
    }
    #tamkinPlayerApp .mt-md-1{
        margin-top: .25rem !important
    }
    #tamkinPlayerApp .mt-md-2{
        margin-top: .5rem !important
    }
    #tamkinPlayerApp .mt-md-3{
        margin-top: 1rem !important
    }
    #tamkinPlayerApp .mt-md-4{
        margin-top: 1.5rem !important
    }
    #tamkinPlayerApp .mt-md-5{
        margin-top: 3rem !important
    }
    #tamkinPlayerApp .mt-md-auto{
        margin-top: auto !important
    }
    #tamkinPlayerApp .me-md-0{
        margin-right: 0 !important
    }
    #tamkinPlayerApp .me-md-1{
        margin-right: .25rem !important
    }
    #tamkinPlayerApp .me-md-2{
        margin-right: .5rem !important
    }
    #tamkinPlayerApp .me-md-3{
        margin-right: 1rem !important
    }
    #tamkinPlayerApp .me-md-4{
        margin-right: 1.5rem !important
    }
    #tamkinPlayerApp .me-md-5{
        margin-right: 3rem !important
    }
    #tamkinPlayerApp .me-md-auto{
        margin-right: auto !important
    }
    #tamkinPlayerApp .mb-md-0{
        margin-bottom: 0 !important
    }
    #tamkinPlayerApp .mb-md-1{
        margin-bottom: .25rem !important
    }
    #tamkinPlayerApp .mb-md-2{
        margin-bottom: .5rem !important
    }
    #tamkinPlayerApp .mb-md-3{
        margin-bottom: 1rem !important
    }
    #tamkinPlayerApp .mb-md-4{
        margin-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .mb-md-5{
        margin-bottom: 3rem !important
    }
    #tamkinPlayerApp .mb-md-auto{
        margin-bottom: auto !important
    }
    #tamkinPlayerApp .ms-md-0{
        margin-left: 0 !important
    }
    #tamkinPlayerApp .ms-md-1{
        margin-left: .25rem !important
    }
    #tamkinPlayerApp .ms-md-2{
        margin-left: .5rem !important
    }
    #tamkinPlayerApp .ms-md-3{
        margin-left: 1rem !important
    }
    #tamkinPlayerApp .ms-md-4{
        margin-left: 1.5rem !important
    }
    #tamkinPlayerApp .ms-md-5{
        margin-left: 3rem !important
    }
    #tamkinPlayerApp .ms-md-auto{
        margin-left: auto !important
    }
    #tamkinPlayerApp .p-md-0{
        padding: 0 !important
    }
    #tamkinPlayerApp .p-md-1{
        padding: .25rem !important
    }
    #tamkinPlayerApp .p-md-2{
        padding: .5rem !important
    }
    #tamkinPlayerApp .p-md-3{
        padding: 1rem !important
    }
    #tamkinPlayerApp .p-md-4{
        padding: 1.5rem !important
    }
    #tamkinPlayerApp .p-md-5{
        padding: 3rem !important
    }
    #tamkinPlayerApp .px-md-0{
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    #tamkinPlayerApp .px-md-1{
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    #tamkinPlayerApp .px-md-2{
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    #tamkinPlayerApp .px-md-3{
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    #tamkinPlayerApp .px-md-4{
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    #tamkinPlayerApp .px-md-5{
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    #tamkinPlayerApp .py-md-0{
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    #tamkinPlayerApp .py-md-1{
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    #tamkinPlayerApp .py-md-2{
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    #tamkinPlayerApp .py-md-3{
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    #tamkinPlayerApp .py-md-4{
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .py-md-5{
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    #tamkinPlayerApp .pt-md-0{
        padding-top: 0 !important
    }
    #tamkinPlayerApp .pt-md-1{
        padding-top: .25rem !important
    }
    #tamkinPlayerApp .pt-md-2{
        padding-top: .5rem !important
    }
    #tamkinPlayerApp .pt-md-3{
        padding-top: 1rem !important
    }
    #tamkinPlayerApp .pt-md-4{
        padding-top: 1.5rem !important
    }
    #tamkinPlayerApp .pt-md-5{
        padding-top: 3rem !important
    }
    #tamkinPlayerApp .pe-md-0{
        padding-right: 0 !important
    }
    #tamkinPlayerApp .pe-md-1{
        padding-right: .25rem !important
    }
    #tamkinPlayerApp .pe-md-2{
        padding-right: .5rem !important
    }
    #tamkinPlayerApp .pe-md-3{
        padding-right: 1rem !important
    }
    #tamkinPlayerApp .pe-md-4{
        padding-right: 1.5rem !important
    }
    #tamkinPlayerApp .pe-md-5{
        padding-right: 3rem !important
    }
    #tamkinPlayerApp .pb-md-0{
        padding-bottom: 0 !important
    }
    #tamkinPlayerApp .pb-md-1{
        padding-bottom: .25rem !important
    }
    #tamkinPlayerApp .pb-md-2{
        padding-bottom: .5rem !important
    }
    #tamkinPlayerApp .pb-md-3{
        padding-bottom: 1rem !important
    }
    #tamkinPlayerApp .pb-md-4{
        padding-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .pb-md-5{
        padding-bottom: 3rem !important
    }
    #tamkinPlayerApp .ps-md-0{
        padding-left: 0 !important
    }
    #tamkinPlayerApp .ps-md-1{
        padding-left: .25rem !important
    }
    #tamkinPlayerApp .ps-md-2{
        padding-left: .5rem !important
    }
    #tamkinPlayerApp .ps-md-3{
        padding-left: 1rem !important
    }
    #tamkinPlayerApp .ps-md-4{
        padding-left: 1.5rem !important
    }
    #tamkinPlayerApp .ps-md-5{
        padding-left: 3rem !important
    }
    #tamkinPlayerApp .gap-md-0{
        gap: 0 !important
    }
    #tamkinPlayerApp .gap-md-1{
        gap: .25rem !important
    }
    #tamkinPlayerApp .gap-md-2{
        gap: .5rem !important
    }
    #tamkinPlayerApp .gap-md-3{
        gap: 1rem !important
    }
    #tamkinPlayerApp .gap-md-4{
        gap: 1.5rem !important
    }
    #tamkinPlayerApp .gap-md-5{
        gap: 3rem !important
    }
    #tamkinPlayerApp .text-md-start{
        text-align: left !important
    }
    #tamkinPlayerApp .text-md-end{
        text-align: right !important
    }
    #tamkinPlayerApp .text-md-center{
        text-align: center !important
    }
}

@media (min-width:992px) {
    #tamkinPlayerApp .float-lg-start {
        float: left !important
    }
    #tamkinPlayerApp .float-lg-end{
        float: right !important
    }
    #tamkinPlayerApp .float-lg-none{
        float: none !important
    }
    #tamkinPlayerApp .d-lg-inline{
        display: inline !important
    }
    #tamkinPlayerApp .d-lg-inline-block{
        display: inline-block !important
    }
    #tamkinPlayerApp .d-lg-block{
        display: block !important
    }
    #tamkinPlayerApp .d-lg-grid{
        display: grid !important
    }
    #tamkinPlayerApp .d-lg-table{
        display: table !important
    }
    #tamkinPlayerApp .d-lg-table-row{
        display: table-row !important
    }
    #tamkinPlayerApp .d-lg-table-cell{
        display: table-cell !important
    }
    #tamkinPlayerApp .d-lg-flex{
        display: flex !important
    }
    #tamkinPlayerApp .d-lg-inline-flex{
        display: inline-flex !important
    }
    #tamkinPlayerApp .d-lg-none{
        display: none !important
    }
    #tamkinPlayerApp .flex-lg-fill{
        flex: 1 1 auto !important
    }
    #tamkinPlayerApp .flex-lg-row{
        flex-direction: row !important
    }
    #tamkinPlayerApp .flex-lg-column{
        flex-direction: column !important
    }
    #tamkinPlayerApp .flex-lg-row-reverse{
        flex-direction: row-reverse !important
    }
    #tamkinPlayerApp .flex-lg-column-reverse{
        flex-direction: column-reverse !important
    }
    #tamkinPlayerApp .flex-lg-grow-0{
        flex-grow: 0 !important
    }
    #tamkinPlayerApp .flex-lg-grow-1{
        flex-grow: 1 !important
    }
    #tamkinPlayerApp .flex-lg-shrink-0{
        flex-shrink: 0 !important
    }
    #tamkinPlayerApp .flex-lg-shrink-1{
        flex-shrink: 1 !important
    }
    #tamkinPlayerApp .flex-lg-wrap{
        flex-wrap: wrap !important
    }
    #tamkinPlayerApp .flex-lg-nowrap{
        flex-wrap: nowrap !important
    }
    #tamkinPlayerApp .flex-lg-wrap-reverse{
        flex-wrap: wrap-reverse !important
    }
    #tamkinPlayerApp .justify-content-lg-start{
        justify-content: flex-start !important
    }
    #tamkinPlayerApp .justify-content-lg-end{
        justify-content: flex-end !important
    }
    #tamkinPlayerApp .justify-content-lg-center{
        justify-content: center !important
    }
    #tamkinPlayerApp .justify-content-lg-between{
        justify-content: space-between !important
    }
    #tamkinPlayerApp .justify-content-lg-around{
        justify-content: space-around !important
    }
    #tamkinPlayerApp .justify-content-lg-evenly{
        justify-content: space-evenly !important
    }
    #tamkinPlayerApp .align-items-lg-start{
        align-items: flex-start !important
    }
    #tamkinPlayerApp .align-items-lg-end{
        align-items: flex-end !important
    }
    #tamkinPlayerApp .align-items-lg-center{
        align-items: center !important
    }
    #tamkinPlayerApp .align-items-lg-baseline{
        align-items: baseline !important
    }
    #tamkinPlayerApp .align-items-lg-stretch{
        align-items: stretch !important
    }
    #tamkinPlayerApp .align-content-lg-start{
        align-content: flex-start !important
    }
    #tamkinPlayerApp .align-content-lg-end{
        align-content: flex-end !important
    }
    #tamkinPlayerApp .align-content-lg-center{
        align-content: center !important
    }
    #tamkinPlayerApp .align-content-lg-between{
        align-content: space-between !important
    }
    #tamkinPlayerApp .align-content-lg-around{
        align-content: space-around !important
    }
    #tamkinPlayerApp .align-content-lg-stretch{
        align-content: stretch !important
    }
    #tamkinPlayerApp .align-self-lg-auto{
        align-self: auto !important
    }
    #tamkinPlayerApp .align-self-lg-start{
        align-self: flex-start !important
    }
    #tamkinPlayerApp .align-self-lg-end{
        align-self: flex-end !important
    }
    #tamkinPlayerApp .align-self-lg-center{
        align-self: center !important
    }
    #tamkinPlayerApp .align-self-lg-baseline{
        align-self: baseline !important
    }
    #tamkinPlayerApp .align-self-lg-stretch{
        align-self: stretch !important
    }
    #tamkinPlayerApp .order-lg-first{
        order: -1 !important
    }
    #tamkinPlayerApp .order-lg-0{
        order: 0 !important
    }
    #tamkinPlayerApp .order-lg-1{
        order: 1 !important
    }
    #tamkinPlayerApp .order-lg-2{
        order: 2 !important
    }
    #tamkinPlayerApp .order-lg-3{
        order: 3 !important
    }
    #tamkinPlayerApp .order-lg-4{
        order: 4 !important
    }
    #tamkinPlayerApp .order-lg-5{
        order: 5 !important
    }
    #tamkinPlayerApp .order-lg-last{
        order: 6 !important
    }
    #tamkinPlayerApp .m-lg-0{
        margin: 0 !important
    }
    #tamkinPlayerApp .m-lg-1{
        margin: .25rem !important
    }
    #tamkinPlayerApp .m-lg-2{
        margin: .5rem !important
    }
    #tamkinPlayerApp .m-lg-3{
        margin: 1rem !important
    }
    #tamkinPlayerApp .m-lg-4{
        margin: 1.5rem !important
    }
    #tamkinPlayerApp .m-lg-5{
        margin: 3rem !important
    }
    #tamkinPlayerApp .m-lg-auto{
        margin: auto !important
    }
    #tamkinPlayerApp .mx-lg-0{
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    #tamkinPlayerApp .mx-lg-1{
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    #tamkinPlayerApp .mx-lg-2{
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    #tamkinPlayerApp .mx-lg-3{
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    #tamkinPlayerApp .mx-lg-4{
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    #tamkinPlayerApp .mx-lg-5{
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    #tamkinPlayerApp .mx-lg-auto{
        margin-right: auto !important;
        margin-left: auto !important
    }
    #tamkinPlayerApp .my-lg-0{
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    #tamkinPlayerApp .my-lg-1{
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    #tamkinPlayerApp .my-lg-2{
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    #tamkinPlayerApp .my-lg-3{
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    #tamkinPlayerApp .my-lg-4{
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .my-lg-5{
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    #tamkinPlayerApp .my-lg-auto{
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    #tamkinPlayerApp .mt-lg-0{
        margin-top: 0 !important
    }
    #tamkinPlayerApp .mt-lg-1{
        margin-top: .25rem !important
    }
    #tamkinPlayerApp .mt-lg-2{
        margin-top: .5rem !important
    }
    #tamkinPlayerApp .mt-lg-3{
        margin-top: 1rem !important
    }
    #tamkinPlayerApp .mt-lg-4{
        margin-top: 1.5rem !important
    }
    #tamkinPlayerApp .mt-lg-5{
        margin-top: 3rem !important
    }
    #tamkinPlayerApp .mt-lg-auto{
        margin-top: auto !important
    }
    #tamkinPlayerApp .me-lg-0{
        margin-right: 0 !important
    }
    #tamkinPlayerApp .me-lg-1{
        margin-right: .25rem !important
    }
    #tamkinPlayerApp .me-lg-2{
        margin-right: .5rem !important
    }
    #tamkinPlayerApp .me-lg-3{
        margin-right: 1rem !important
    }
    #tamkinPlayerApp .me-lg-4{
        margin-right: 1.5rem !important
    }
    #tamkinPlayerApp .me-lg-5{
        margin-right: 3rem !important
    }
    #tamkinPlayerApp .me-lg-auto{
        margin-right: auto !important
    }
    #tamkinPlayerApp .mb-lg-0{
        margin-bottom: 0 !important
    }
    #tamkinPlayerApp .mb-lg-1{
        margin-bottom: .25rem !important
    }
    #tamkinPlayerApp .mb-lg-2{
        margin-bottom: .5rem !important
    }
    #tamkinPlayerApp .mb-lg-3{
        margin-bottom: 1rem !important
    }
    #tamkinPlayerApp .mb-lg-4{
        margin-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .mb-lg-5{
        margin-bottom: 3rem !important
    }
    #tamkinPlayerApp .mb-lg-auto{
        margin-bottom: auto !important
    }
    #tamkinPlayerApp .ms-lg-0{
        margin-left: 0 !important
    }
    #tamkinPlayerApp .ms-lg-1{
        margin-left: .25rem !important
    }
    #tamkinPlayerApp .ms-lg-2{
        margin-left: .5rem !important
    }
    #tamkinPlayerApp .ms-lg-3{
        margin-left: 1rem !important
    }
    #tamkinPlayerApp .ms-lg-4{
        margin-left: 1.5rem !important
    }
    #tamkinPlayerApp .ms-lg-5{
        margin-left: 3rem !important
    }
    #tamkinPlayerApp .ms-lg-auto{
        margin-left: auto !important
    }
    #tamkinPlayerApp .p-lg-0{
        padding: 0 !important
    }
    #tamkinPlayerApp .p-lg-1{
        padding: .25rem !important
    }
    #tamkinPlayerApp .p-lg-2{
        padding: .5rem !important
    }
    #tamkinPlayerApp .p-lg-3{
        padding: 1rem !important
    }
    #tamkinPlayerApp .p-lg-4{
        padding: 1.5rem !important
    }
    #tamkinPlayerApp .p-lg-5{
        padding: 3rem !important
    }
    #tamkinPlayerApp .px-lg-0{
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    #tamkinPlayerApp .px-lg-1{
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    #tamkinPlayerApp .px-lg-2{
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    #tamkinPlayerApp .px-lg-3{
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    #tamkinPlayerApp .px-lg-4{
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    #tamkinPlayerApp .px-lg-5{
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    #tamkinPlayerApp .py-lg-0{
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    #tamkinPlayerApp .py-lg-1{
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    #tamkinPlayerApp .py-lg-2{
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    #tamkinPlayerApp .py-lg-3{
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    #tamkinPlayerApp .py-lg-4{
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .py-lg-5{
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    #tamkinPlayerApp .pt-lg-0{
        padding-top: 0 !important
    }
    #tamkinPlayerApp .pt-lg-1{
        padding-top: .25rem !important
    }
    #tamkinPlayerApp .pt-lg-2{
        padding-top: .5rem !important
    }
    #tamkinPlayerApp .pt-lg-3{
        padding-top: 1rem !important
    }
    #tamkinPlayerApp .pt-lg-4{
        padding-top: 1.5rem !important
    }
    #tamkinPlayerApp .pt-lg-5{
        padding-top: 3rem !important
    }
    #tamkinPlayerApp .pe-lg-0{
        padding-right: 0 !important
    }
    #tamkinPlayerApp .pe-lg-1{
        padding-right: .25rem !important
    }
    #tamkinPlayerApp .pe-lg-2{
        padding-right: .5rem !important
    }
    #tamkinPlayerApp .pe-lg-3{
        padding-right: 1rem !important
    }
    #tamkinPlayerApp .pe-lg-4{
        padding-right: 1.5rem !important
    }
    #tamkinPlayerApp .pe-lg-5{
        padding-right: 3rem !important
    }
    #tamkinPlayerApp .pb-lg-0{
        padding-bottom: 0 !important
    }
    #tamkinPlayerApp .pb-lg-1{
        padding-bottom: .25rem !important
    }
    #tamkinPlayerApp .pb-lg-2{
        padding-bottom: .5rem !important
    }
    #tamkinPlayerApp .pb-lg-3{
        padding-bottom: 1rem !important
    }
    #tamkinPlayerApp .pb-lg-4{
        padding-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .pb-lg-5{
        padding-bottom: 3rem !important
    }
    #tamkinPlayerApp .ps-lg-0{
        padding-left: 0 !important
    }
    #tamkinPlayerApp .ps-lg-1{
        padding-left: .25rem !important
    }
    #tamkinPlayerApp .ps-lg-2{
        padding-left: .5rem !important
    }
    #tamkinPlayerApp .ps-lg-3{
        padding-left: 1rem !important
    }
    #tamkinPlayerApp .ps-lg-4{
        padding-left: 1.5rem !important
    }
    #tamkinPlayerApp .ps-lg-5{
        padding-left: 3rem !important
    }
    #tamkinPlayerApp .gap-lg-0{
        gap: 0 !important
    }
    #tamkinPlayerApp .gap-lg-1{
        gap: .25rem !important
    }
    #tamkinPlayerApp .gap-lg-2{
        gap: .5rem !important
    }
    #tamkinPlayerApp .gap-lg-3{
        gap: 1rem !important
    }
    #tamkinPlayerApp .gap-lg-4{
        gap: 1.5rem !important
    }
    #tamkinPlayerApp .gap-lg-5{
        gap: 3rem !important
    }
    #tamkinPlayerApp .text-lg-start{
        text-align: left !important
    }
    #tamkinPlayerApp .text-lg-end{
        text-align: right !important
    }
    #tamkinPlayerApp .text-lg-center{
        text-align: center !important
    }
}

@media (min-width:1200px) {
    #tamkinPlayerApp .float-xl-start {
        float: left !important
    }
    #tamkinPlayerApp .float-xl-end{
        float: right !important
    }
    #tamkinPlayerApp .float-xl-none{
        float: none !important
    }
    #tamkinPlayerApp .d-xl-inline{
        display: inline !important
    }
    #tamkinPlayerApp .d-xl-inline-block{
        display: inline-block !important
    }
    #tamkinPlayerApp .d-xl-block{
        display: block !important
    }
    #tamkinPlayerApp .d-xl-grid{
        display: grid !important
    }
    #tamkinPlayerApp .d-xl-table{
        display: table !important
    }
    #tamkinPlayerApp .d-xl-table-row{
        display: table-row !important
    }
    #tamkinPlayerApp .d-xl-table-cell{
        display: table-cell !important
    }
    #tamkinPlayerApp .d-xl-flex{
        display: flex !important
    }
    #tamkinPlayerApp .d-xl-inline-flex{
        display: inline-flex !important
    }
    #tamkinPlayerApp .d-xl-none{
        display: none !important
    }
    #tamkinPlayerApp .flex-xl-fill{
        flex: 1 1 auto !important
    }
    #tamkinPlayerApp .flex-xl-row{
        flex-direction: row !important
    }
    #tamkinPlayerApp .flex-xl-column{
        flex-direction: column !important
    }
    #tamkinPlayerApp .flex-xl-row-reverse{
        flex-direction: row-reverse !important
    }
    #tamkinPlayerApp .flex-xl-column-reverse{
        flex-direction: column-reverse !important
    }
    #tamkinPlayerApp .flex-xl-grow-0{
        flex-grow: 0 !important
    }
    #tamkinPlayerApp .flex-xl-grow-1{
        flex-grow: 1 !important
    }
    #tamkinPlayerApp .flex-xl-shrink-0{
        flex-shrink: 0 !important
    }
    #tamkinPlayerApp .flex-xl-shrink-1{
        flex-shrink: 1 !important
    }
    #tamkinPlayerApp .flex-xl-wrap{
        flex-wrap: wrap !important
    }
    #tamkinPlayerApp .flex-xl-nowrap{
        flex-wrap: nowrap !important
    }
    #tamkinPlayerApp .flex-xl-wrap-reverse{
        flex-wrap: wrap-reverse !important
    }
    #tamkinPlayerApp .justify-content-xl-start{
        justify-content: flex-start !important
    }
    #tamkinPlayerApp .justify-content-xl-end{
        justify-content: flex-end !important
    }
    #tamkinPlayerApp .justify-content-xl-center{
        justify-content: center !important
    }
    #tamkinPlayerApp .justify-content-xl-between{
        justify-content: space-between !important
    }
    #tamkinPlayerApp .justify-content-xl-around{
        justify-content: space-around !important
    }
    #tamkinPlayerApp .justify-content-xl-evenly{
        justify-content: space-evenly !important
    }
    #tamkinPlayerApp .align-items-xl-start{
        align-items: flex-start !important
    }
    #tamkinPlayerApp .align-items-xl-end{
        align-items: flex-end !important
    }
    #tamkinPlayerApp .align-items-xl-center{
        align-items: center !important
    }
    #tamkinPlayerApp .align-items-xl-baseline{
        align-items: baseline !important
    }
    #tamkinPlayerApp .align-items-xl-stretch{
        align-items: stretch !important
    }
    #tamkinPlayerApp .align-content-xl-start{
        align-content: flex-start !important
    }
    #tamkinPlayerApp .align-content-xl-end{
        align-content: flex-end !important
    }
    #tamkinPlayerApp .align-content-xl-center{
        align-content: center !important
    }
    #tamkinPlayerApp .align-content-xl-between{
        align-content: space-between !important
    }
    #tamkinPlayerApp .align-content-xl-around{
        align-content: space-around !important
    }
    #tamkinPlayerApp .align-content-xl-stretch{
        align-content: stretch !important
    }
    #tamkinPlayerApp .align-self-xl-auto{
        align-self: auto !important
    }
    #tamkinPlayerApp .align-self-xl-start{
        align-self: flex-start !important
    }
    #tamkinPlayerApp .align-self-xl-end{
        align-self: flex-end !important
    }
    #tamkinPlayerApp .align-self-xl-center{
        align-self: center !important
    }
    #tamkinPlayerApp .align-self-xl-baseline{
        align-self: baseline !important
    }
    #tamkinPlayerApp .align-self-xl-stretch{
        align-self: stretch !important
    }
    #tamkinPlayerApp .order-xl-first{
        order: -1 !important
    }
    #tamkinPlayerApp .order-xl-0{
        order: 0 !important
    }
    #tamkinPlayerApp .order-xl-1{
        order: 1 !important
    }
    #tamkinPlayerApp .order-xl-2{
        order: 2 !important
    }
    #tamkinPlayerApp .order-xl-3{
        order: 3 !important
    }
    #tamkinPlayerApp .order-xl-4{
        order: 4 !important
    }
    #tamkinPlayerApp .order-xl-5{
        order: 5 !important
    }
    #tamkinPlayerApp .order-xl-last{
        order: 6 !important
    }
    #tamkinPlayerApp .m-xl-0{
        margin: 0 !important
    }
    #tamkinPlayerApp .m-xl-1{
        margin: .25rem !important
    }
    #tamkinPlayerApp .m-xl-2{
        margin: .5rem !important
    }
    #tamkinPlayerApp .m-xl-3{
        margin: 1rem !important
    }
    #tamkinPlayerApp .m-xl-4{
        margin: 1.5rem !important
    }
    #tamkinPlayerApp .m-xl-5{
        margin: 3rem !important
    }
    #tamkinPlayerApp .m-xl-auto{
        margin: auto !important
    }
    #tamkinPlayerApp .mx-xl-0{
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    #tamkinPlayerApp .mx-xl-1{
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    #tamkinPlayerApp .mx-xl-2{
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    #tamkinPlayerApp .mx-xl-3{
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    #tamkinPlayerApp .mx-xl-4{
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    #tamkinPlayerApp .mx-xl-5{
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    #tamkinPlayerApp .mx-xl-auto{
        margin-right: auto !important;
        margin-left: auto !important
    }
    #tamkinPlayerApp .my-xl-0{
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    #tamkinPlayerApp .my-xl-1{
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    #tamkinPlayerApp .my-xl-2{
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    #tamkinPlayerApp .my-xl-3{
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    #tamkinPlayerApp .my-xl-4{
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .my-xl-5{
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    #tamkinPlayerApp .my-xl-auto{
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    #tamkinPlayerApp .mt-xl-0{
        margin-top: 0 !important
    }
    #tamkinPlayerApp .mt-xl-1{
        margin-top: .25rem !important
    }
    #tamkinPlayerApp .mt-xl-2{
        margin-top: .5rem !important
    }
    #tamkinPlayerApp .mt-xl-3{
        margin-top: 1rem !important
    }
    #tamkinPlayerApp .mt-xl-4{
        margin-top: 1.5rem !important
    }
    #tamkinPlayerApp .mt-xl-5{
        margin-top: 3rem !important
    }
    #tamkinPlayerApp .mt-xl-auto{
        margin-top: auto !important
    }
    #tamkinPlayerApp .me-xl-0{
        margin-right: 0 !important
    }
    #tamkinPlayerApp .me-xl-1{
        margin-right: .25rem !important
    }
    #tamkinPlayerApp .me-xl-2{
        margin-right: .5rem !important
    }
    #tamkinPlayerApp .me-xl-3{
        margin-right: 1rem !important
    }
    #tamkinPlayerApp .me-xl-4{
        margin-right: 1.5rem !important
    }
    #tamkinPlayerApp .me-xl-5{
        margin-right: 3rem !important
    }
    #tamkinPlayerApp .me-xl-auto{
        margin-right: auto !important
    }
    #tamkinPlayerApp .mb-xl-0{
        margin-bottom: 0 !important
    }
    #tamkinPlayerApp .mb-xl-1{
        margin-bottom: .25rem !important
    }
    #tamkinPlayerApp .mb-xl-2{
        margin-bottom: .5rem !important
    }
    #tamkinPlayerApp .mb-xl-3{
        margin-bottom: 1rem !important
    }
    #tamkinPlayerApp .mb-xl-4{
        margin-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .mb-xl-5{
        margin-bottom: 3rem !important
    }
    #tamkinPlayerApp .mb-xl-auto{
        margin-bottom: auto !important
    }
    #tamkinPlayerApp .ms-xl-0{
        margin-left: 0 !important
    }
    #tamkinPlayerApp .ms-xl-1{
        margin-left: .25rem !important
    }
    #tamkinPlayerApp .ms-xl-2{
        margin-left: .5rem !important
    }
    #tamkinPlayerApp .ms-xl-3{
        margin-left: 1rem !important
    }
    #tamkinPlayerApp .ms-xl-4{
        margin-left: 1.5rem !important
    }
    #tamkinPlayerApp .ms-xl-5{
        margin-left: 3rem !important
    }
    #tamkinPlayerApp .ms-xl-auto{
        margin-left: auto !important
    }
    #tamkinPlayerApp .p-xl-0{
        padding: 0 !important
    }
    #tamkinPlayerApp .p-xl-1{
        padding: .25rem !important
    }
    #tamkinPlayerApp .p-xl-2{
        padding: .5rem !important
    }
    #tamkinPlayerApp .p-xl-3{
        padding: 1rem !important
    }
    #tamkinPlayerApp .p-xl-4{
        padding: 1.5rem !important
    }
    #tamkinPlayerApp .p-xl-5{
        padding: 3rem !important
    }
    #tamkinPlayerApp .px-xl-0{
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    #tamkinPlayerApp .px-xl-1{
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    #tamkinPlayerApp .px-xl-2{
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    #tamkinPlayerApp .px-xl-3{
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    #tamkinPlayerApp .px-xl-4{
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    #tamkinPlayerApp .px-xl-5{
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    #tamkinPlayerApp .py-xl-0{
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    #tamkinPlayerApp .py-xl-1{
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    #tamkinPlayerApp .py-xl-2{
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    #tamkinPlayerApp .py-xl-3{
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    #tamkinPlayerApp .py-xl-4{
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .py-xl-5{
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    #tamkinPlayerApp .pt-xl-0{
        padding-top: 0 !important
    }
    #tamkinPlayerApp .pt-xl-1{
        padding-top: .25rem !important
    }
    #tamkinPlayerApp .pt-xl-2{
        padding-top: .5rem !important
    }
    #tamkinPlayerApp .pt-xl-3{
        padding-top: 1rem !important
    }
    #tamkinPlayerApp .pt-xl-4{
        padding-top: 1.5rem !important
    }
    #tamkinPlayerApp .pt-xl-5{
        padding-top: 3rem !important
    }
    #tamkinPlayerApp .pe-xl-0{
        padding-right: 0 !important
    }
    #tamkinPlayerApp .pe-xl-1{
        padding-right: .25rem !important
    }
    #tamkinPlayerApp .pe-xl-2{
        padding-right: .5rem !important
    }
    #tamkinPlayerApp .pe-xl-3{
        padding-right: 1rem !important
    }
    #tamkinPlayerApp .pe-xl-4{
        padding-right: 1.5rem !important
    }
    #tamkinPlayerApp .pe-xl-5{
        padding-right: 3rem !important
    }
    #tamkinPlayerApp .pb-xl-0{
        padding-bottom: 0 !important
    }
    #tamkinPlayerApp .pb-xl-1{
        padding-bottom: .25rem !important
    }
    #tamkinPlayerApp .pb-xl-2{
        padding-bottom: .5rem !important
    }
    #tamkinPlayerApp .pb-xl-3{
        padding-bottom: 1rem !important
    }
    #tamkinPlayerApp .pb-xl-4{
        padding-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .pb-xl-5{
        padding-bottom: 3rem !important
    }
    #tamkinPlayerApp .ps-xl-0{
        padding-left: 0 !important
    }
    #tamkinPlayerApp .ps-xl-1{
        padding-left: .25rem !important
    }
    #tamkinPlayerApp .ps-xl-2{
        padding-left: .5rem !important
    }
    #tamkinPlayerApp .ps-xl-3{
        padding-left: 1rem !important
    }
    #tamkinPlayerApp .ps-xl-4{
        padding-left: 1.5rem !important
    }
    #tamkinPlayerApp .ps-xl-5{
        padding-left: 3rem !important
    }
    #tamkinPlayerApp .gap-xl-0{
        gap: 0 !important
    }
    #tamkinPlayerApp .gap-xl-1{
        gap: .25rem !important
    }
    #tamkinPlayerApp .gap-xl-2{
        gap: .5rem !important
    }
    #tamkinPlayerApp .gap-xl-3{
        gap: 1rem !important
    }
    #tamkinPlayerApp .gap-xl-4{
        gap: 1.5rem !important
    }
    #tamkinPlayerApp .gap-xl-5{
        gap: 3rem !important
    }
    #tamkinPlayerApp .text-xl-start{
        text-align: left !important
    }
    #tamkinPlayerApp .text-xl-end{
        text-align: right !important
    }
    #tamkinPlayerApp .text-xl-center{
        text-align: center !important
    }
}

@media (min-width:1400px) {
    #tamkinPlayerApp .float-xxl-start {
        float: left !important
    }
    #tamkinPlayerApp .float-xxl-end{
        float: right !important
    }
    #tamkinPlayerApp .float-xxl-none{
        float: none !important
    }
    #tamkinPlayerApp .d-xxl-inline{
        display: inline !important
    }
    #tamkinPlayerApp .d-xxl-inline-block{
        display: inline-block !important
    }
    #tamkinPlayerApp .d-xxl-block{
        display: block !important
    }
    #tamkinPlayerApp .d-xxl-grid{
        display: grid !important
    }
    #tamkinPlayerApp .d-xxl-table{
        display: table !important
    }
    #tamkinPlayerApp .d-xxl-table-row{
        display: table-row !important
    }
    #tamkinPlayerApp .d-xxl-table-cell{
        display: table-cell !important
    }
    #tamkinPlayerApp .d-xxl-flex{
        display: flex !important
    }
    #tamkinPlayerApp .d-xxl-inline-flex{
        display: inline-flex !important
    }
    #tamkinPlayerApp .d-xxl-none{
        display: none !important
    }
    #tamkinPlayerApp .flex-xxl-fill{
        flex: 1 1 auto !important
    }
    #tamkinPlayerApp .flex-xxl-row{
        flex-direction: row !important
    }
    #tamkinPlayerApp .flex-xxl-column{
        flex-direction: column !important
    }
    #tamkinPlayerApp .flex-xxl-row-reverse{
        flex-direction: row-reverse !important
    }
    #tamkinPlayerApp .flex-xxl-column-reverse{
        flex-direction: column-reverse !important
    }
    #tamkinPlayerApp .flex-xxl-grow-0{
        flex-grow: 0 !important
    }
    #tamkinPlayerApp .flex-xxl-grow-1{
        flex-grow: 1 !important
    }
    #tamkinPlayerApp .flex-xxl-shrink-0{
        flex-shrink: 0 !important
    }
    #tamkinPlayerApp .flex-xxl-shrink-1{
        flex-shrink: 1 !important
    }
    #tamkinPlayerApp .flex-xxl-wrap{
        flex-wrap: wrap !important
    }
    #tamkinPlayerApp .flex-xxl-nowrap{
        flex-wrap: nowrap !important
    }
    #tamkinPlayerApp .flex-xxl-wrap-reverse{
        flex-wrap: wrap-reverse !important
    }
    #tamkinPlayerApp .justify-content-xxl-start{
        justify-content: flex-start !important
    }
    #tamkinPlayerApp .justify-content-xxl-end{
        justify-content: flex-end !important
    }
    #tamkinPlayerApp .justify-content-xxl-center{
        justify-content: center !important
    }
    #tamkinPlayerApp .justify-content-xxl-between{
        justify-content: space-between !important
    }
    #tamkinPlayerApp .justify-content-xxl-around{
        justify-content: space-around !important
    }
    #tamkinPlayerApp .justify-content-xxl-evenly{
        justify-content: space-evenly !important
    }
    #tamkinPlayerApp .align-items-xxl-start{
        align-items: flex-start !important
    }
    #tamkinPlayerApp .align-items-xxl-end{
        align-items: flex-end !important
    }
    #tamkinPlayerApp .align-items-xxl-center{
        align-items: center !important
    }
    #tamkinPlayerApp .align-items-xxl-baseline{
        align-items: baseline !important
    }
    #tamkinPlayerApp .align-items-xxl-stretch{
        align-items: stretch !important
    }
    #tamkinPlayerApp .align-content-xxl-start{
        align-content: flex-start !important
    }
    #tamkinPlayerApp .align-content-xxl-end{
        align-content: flex-end !important
    }
    #tamkinPlayerApp .align-content-xxl-center{
        align-content: center !important
    }
    #tamkinPlayerApp .align-content-xxl-between{
        align-content: space-between !important
    }
    #tamkinPlayerApp .align-content-xxl-around{
        align-content: space-around !important
    }
    #tamkinPlayerApp .align-content-xxl-stretch{
        align-content: stretch !important
    }
    #tamkinPlayerApp .align-self-xxl-auto{
        align-self: auto !important
    }
    #tamkinPlayerApp .align-self-xxl-start{
        align-self: flex-start !important
    }
    #tamkinPlayerApp .align-self-xxl-end{
        align-self: flex-end !important
    }
    #tamkinPlayerApp .align-self-xxl-center{
        align-self: center !important
    }
    #tamkinPlayerApp .align-self-xxl-baseline{
        align-self: baseline !important
    }
    #tamkinPlayerApp .align-self-xxl-stretch{
        align-self: stretch !important
    }
    #tamkinPlayerApp .order-xxl-first{
        order: -1 !important
    }
    #tamkinPlayerApp .order-xxl-0{
        order: 0 !important
    }
    #tamkinPlayerApp .order-xxl-1{
        order: 1 !important
    }
    #tamkinPlayerApp .order-xxl-2{
        order: 2 !important
    }
    #tamkinPlayerApp .order-xxl-3{
        order: 3 !important
    }
    #tamkinPlayerApp .order-xxl-4{
        order: 4 !important
    }
    #tamkinPlayerApp .order-xxl-5{
        order: 5 !important
    }
    #tamkinPlayerApp .order-xxl-last{
        order: 6 !important
    }
    #tamkinPlayerApp .m-xxl-0{
        margin: 0 !important
    }
    #tamkinPlayerApp .m-xxl-1{
        margin: .25rem !important
    }
    #tamkinPlayerApp .m-xxl-2{
        margin: .5rem !important
    }
    #tamkinPlayerApp .m-xxl-3{
        margin: 1rem !important
    }
    #tamkinPlayerApp .m-xxl-4{
        margin: 1.5rem !important
    }
    #tamkinPlayerApp .m-xxl-5{
        margin: 3rem !important
    }
    #tamkinPlayerApp .m-xxl-auto{
        margin: auto !important
    }
    #tamkinPlayerApp .mx-xxl-0{
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    #tamkinPlayerApp .mx-xxl-1{
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    #tamkinPlayerApp .mx-xxl-2{
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    #tamkinPlayerApp .mx-xxl-3{
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    #tamkinPlayerApp .mx-xxl-4{
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    #tamkinPlayerApp .mx-xxl-5{
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    #tamkinPlayerApp .mx-xxl-auto{
        margin-right: auto !important;
        margin-left: auto !important
    }
    #tamkinPlayerApp .my-xxl-0{
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    #tamkinPlayerApp .my-xxl-1{
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    #tamkinPlayerApp .my-xxl-2{
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    #tamkinPlayerApp .my-xxl-3{
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    #tamkinPlayerApp .my-xxl-4{
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .my-xxl-5{
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    #tamkinPlayerApp .my-xxl-auto{
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    #tamkinPlayerApp .mt-xxl-0{
        margin-top: 0 !important
    }
    #tamkinPlayerApp .mt-xxl-1{
        margin-top: .25rem !important
    }
    #tamkinPlayerApp .mt-xxl-2{
        margin-top: .5rem !important
    }
    #tamkinPlayerApp .mt-xxl-3{
        margin-top: 1rem !important
    }
    #tamkinPlayerApp .mt-xxl-4{
        margin-top: 1.5rem !important
    }
    #tamkinPlayerApp .mt-xxl-5{
        margin-top: 3rem !important
    }
    #tamkinPlayerApp .mt-xxl-auto{
        margin-top: auto !important
    }
    #tamkinPlayerApp .me-xxl-0{
        margin-right: 0 !important
    }
    #tamkinPlayerApp .me-xxl-1{
        margin-right: .25rem !important
    }
    #tamkinPlayerApp .me-xxl-2{
        margin-right: .5rem !important
    }
    #tamkinPlayerApp .me-xxl-3{
        margin-right: 1rem !important
    }
    #tamkinPlayerApp .me-xxl-4{
        margin-right: 1.5rem !important
    }
    #tamkinPlayerApp .me-xxl-5{
        margin-right: 3rem !important
    }
    #tamkinPlayerApp .me-xxl-auto{
        margin-right: auto !important
    }
    #tamkinPlayerApp .mb-xxl-0{
        margin-bottom: 0 !important
    }
    #tamkinPlayerApp .mb-xxl-1{
        margin-bottom: .25rem !important
    }
    #tamkinPlayerApp .mb-xxl-2{
        margin-bottom: .5rem !important
    }
    #tamkinPlayerApp .mb-xxl-3{
        margin-bottom: 1rem !important
    }
    #tamkinPlayerApp .mb-xxl-4{
        margin-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .mb-xxl-5{
        margin-bottom: 3rem !important
    }
    #tamkinPlayerApp .mb-xxl-auto{
        margin-bottom: auto !important
    }
    #tamkinPlayerApp .ms-xxl-0{
        margin-left: 0 !important
    }
    #tamkinPlayerApp .ms-xxl-1{
        margin-left: .25rem !important
    }
    #tamkinPlayerApp .ms-xxl-2{
        margin-left: .5rem !important
    }
    #tamkinPlayerApp .ms-xxl-3{
        margin-left: 1rem !important
    }
    #tamkinPlayerApp .ms-xxl-4{
        margin-left: 1.5rem !important
    }
    #tamkinPlayerApp .ms-xxl-5{
        margin-left: 3rem !important
    }
    #tamkinPlayerApp .ms-xxl-auto{
        margin-left: auto !important
    }
    #tamkinPlayerApp .p-xxl-0{
        padding: 0 !important
    }
    #tamkinPlayerApp .p-xxl-1{
        padding: .25rem !important
    }
    #tamkinPlayerApp .p-xxl-2{
        padding: .5rem !important
    }
    #tamkinPlayerApp .p-xxl-3{
        padding: 1rem !important
    }
    #tamkinPlayerApp .p-xxl-4{
        padding: 1.5rem !important
    }
    #tamkinPlayerApp .p-xxl-5{
        padding: 3rem !important
    }
    #tamkinPlayerApp .px-xxl-0{
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    #tamkinPlayerApp .px-xxl-1{
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    #tamkinPlayerApp .px-xxl-2{
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    #tamkinPlayerApp .px-xxl-3{
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    #tamkinPlayerApp .px-xxl-4{
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    #tamkinPlayerApp .px-xxl-5{
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    #tamkinPlayerApp .py-xxl-0{
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    #tamkinPlayerApp .py-xxl-1{
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    #tamkinPlayerApp .py-xxl-2{
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    #tamkinPlayerApp .py-xxl-3{
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    #tamkinPlayerApp .py-xxl-4{
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .py-xxl-5{
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    #tamkinPlayerApp .pt-xxl-0{
        padding-top: 0 !important
    }
    #tamkinPlayerApp .pt-xxl-1{
        padding-top: .25rem !important
    }
    #tamkinPlayerApp .pt-xxl-2{
        padding-top: .5rem !important
    }
    #tamkinPlayerApp .pt-xxl-3{
        padding-top: 1rem !important
    }
    #tamkinPlayerApp .pt-xxl-4{
        padding-top: 1.5rem !important
    }
    #tamkinPlayerApp .pt-xxl-5{
        padding-top: 3rem !important
    }
    #tamkinPlayerApp .pe-xxl-0{
        padding-right: 0 !important
    }
    #tamkinPlayerApp .pe-xxl-1{
        padding-right: .25rem !important
    }
    #tamkinPlayerApp .pe-xxl-2{
        padding-right: .5rem !important
    }
    #tamkinPlayerApp .pe-xxl-3{
        padding-right: 1rem !important
    }
    #tamkinPlayerApp .pe-xxl-4{
        padding-right: 1.5rem !important
    }
    #tamkinPlayerApp .pe-xxl-5{
        padding-right: 3rem !important
    }
    #tamkinPlayerApp .pb-xxl-0{
        padding-bottom: 0 !important
    }
    #tamkinPlayerApp .pb-xxl-1{
        padding-bottom: .25rem !important
    }
    #tamkinPlayerApp .pb-xxl-2{
        padding-bottom: .5rem !important
    }
    #tamkinPlayerApp .pb-xxl-3{
        padding-bottom: 1rem !important
    }
    #tamkinPlayerApp .pb-xxl-4{
        padding-bottom: 1.5rem !important
    }
    #tamkinPlayerApp .pb-xxl-5{
        padding-bottom: 3rem !important
    }
    #tamkinPlayerApp .ps-xxl-0{
        padding-left: 0 !important
    }
    #tamkinPlayerApp .ps-xxl-1{
        padding-left: .25rem !important
    }
    #tamkinPlayerApp .ps-xxl-2{
        padding-left: .5rem !important
    }
    #tamkinPlayerApp .ps-xxl-3{
        padding-left: 1rem !important
    }
    #tamkinPlayerApp .ps-xxl-4{
        padding-left: 1.5rem !important
    }
    #tamkinPlayerApp .ps-xxl-5{
        padding-left: 3rem !important
    }
    #tamkinPlayerApp .gap-xxl-0{
        gap: 0 !important
    }
    #tamkinPlayerApp .gap-xxl-1{
        gap: .25rem !important
    }
    #tamkinPlayerApp .gap-xxl-2{
        gap: .5rem !important
    }
    #tamkinPlayerApp .gap-xxl-3{
        gap: 1rem !important
    }
    #tamkinPlayerApp .gap-xxl-4{
        gap: 1.5rem !important
    }
    #tamkinPlayerApp .gap-xxl-5{
        gap: 3rem !important
    }
    #tamkinPlayerApp .text-xxl-start{
        text-align: left !important
    }
    #tamkinPlayerApp .text-xxl-end{
        text-align: right !important
    }
    #tamkinPlayerApp .text-xxl-center{
        text-align: center !important
    }
}

@media (min-width:1200px) {
    #tamkinPlayerApp .fs-1 {
        font-size: 2.5rem !important
    }
    #tamkinPlayerApp .fs-2{
        font-size: 2rem !important
    }
    #tamkinPlayerApp .fs-3{
        font-size: 1.75rem !important
    }
    #tamkinPlayerApp .fs-4{
        font-size: 1.5rem !important
    }
}

@media print {
    #tamkinPlayerApp .d-print-inline {
        display: inline !important
    }
    #tamkinPlayerApp .d-print-inline-block{
        display: inline-block !important
    }
    #tamkinPlayerApp .d-print-block{
        display: block !important
    }
    #tamkinPlayerApp .d-print-grid{
        display: grid !important
    }
    #tamkinPlayerApp .d-print-table{
        display: table !important
    }
    #tamkinPlayerApp .d-print-table-row{
        display: table-row !important
    }
    #tamkinPlayerApp .d-print-table-cell{
        display: table-cell !important
    }
    #tamkinPlayerApp .d-print-flex{
        display: flex !important
    }
    #tamkinPlayerApp .d-print-inline-flex{
        display: inline-flex !important
    }
    #tamkinPlayerApp .d-print-none{
        display: none !important
    }
}