/* @import "https://tamkin.app/assets/tamkin_website/player/tamkin_player/arcgis_main.css"; */

#tamkinPlayerApp {
  --tp-primary: #35c0b4;
  --tp-primary-grad: #71dad2;
}
/*Accessibility Menu*/
#tamkinPlayerApp #tp-search-gp {
  position: relative;
}
#tamkinPlayerApp #tp-search:focus {
  box-shadow: none !important;
  outline: 1px solid var(--tp-primary) !important;
}
#tamkinPlayerApp #tp-search-remove {
  position: absolute;
  font-size: 22px;
  cursor: pointer;
  z-index: 9999999999;
  right: 30px;
  top: 4px;
}

#tamkinPlayerApp .highlight {
  background-color: #d0f0fd !important;
}

#tamkinPlayerApp .high-contrast * {
  background-color: #000 !important;
  color: #fff !important;
}

#tamkinPlayerApp .skeleton {
  display: none;
  position: relative;
  overflow: hidden;
}

#tamkinPlayerApp .placeholder {
  position: relative;
  background-color: #b0b0b0;
  background-image: linear-gradient(
    to right,
    #b0b0b0 0%,
    #8a8a8a 20%,
    #b0b0b0 40%,
    #b0b0b0 100%
  );
  background-size: 200% 100%;
  animation: tp-shimmer 1.5s infinite linear;
}

/* Shimmer animation */
@keyframes tp-shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

#tamkinPlayerApp .placeholder::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.6) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

#tamkinPlayerApp .form-control:focus {
  border-color: var(--tp-primary) !important;
}

.highlighted-link,
.highlighted-link * {
  color: rgb(255, 255, 0) !important;
  background-color: rgb(0, 0, 0) !important;
  text-decoration: underline !important;
  transition: all 0.3s ease 0s !important;
}
